import React, { useState, useEffect, useCallback } from "react";
import CartItem from "./CartItem";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import AspectRatio from "@material-ui/icons/AspectRatio";
import Delete from "@material-ui/icons/Delete";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import { setDrawingMode, getDrawingMode } from "../olMap/renderOlMap";
import appState from "../../utils/appState";
import { removeVectorLayers } from "../../utils/layerUtil";
import {
  processOrder,
  checkSelectionPolygon,
  getSelectionPolygon,
  getMin,
  getMax,
  updateGlobalShoppingCart
} from "../../utils/geoshopUtil";

const customerTypes = [
  "Benutzergruppe wählen...",
  "Architekten, Planer, Ingenieure",
  "Werke",
  "Auftragnehmer der kant. Verwaltung",
  "oeffentliche Verwaltung",
  "Schulen, Wissenschaftliche Zwecke",
  "Private",
  "Andere"
];
const mailRegex = /^\S+@\S+\.\S+$/;

const DeleteButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700]
    }
  }
}))(Button);

const styles = {
  cartContainer: {
    border: "1px dotted grey",
    padding: "12px"
  },
  emptyCartTitle: {
    width: "100%"
  },
  cart: {
    border: "1px solid grey",
    borderRadius: "5px",
    padding: 0
  },
  divider: {
    margin: "12px 0"
  },
  buttonImage: {
    margin: "0 0 0 16px"
  },
  drawButton: {
    margin: "8px 0"
  },
  addCartButton: {
    marginTop: "20px"
  }
};
/* variables we assign functions later to interact
 * with the geoshop order polygon.
 */
let deletePolygon = null;

const Cart = ({ shoppingCart, removeFromCart, emptyCart }) => {
  const [orderExtent, setOrderExtent] = useState([]);
  const [orderMail, setOrderMail] = useState("");
  const [customerType, setCustomerType] = useState(customerTypes[0]);
  const [mailAdressError, setMailAdressError] = useState(true);
  const [customerTypeError, setCustomerTypeError] = useState(true);
  const [sendOrder, setSendOrder] = useState(false);
  const [orderMessage, setOrderMessage] = useState("");
  const [sendError, setSendError] = useState(false);
  const [selectionPolygonRequired, setSelectionPolygonRequired] = useState(
    true
  );
  // function to show/hide the order perimeter buttons
  const updatePerimeterButtons = useCallback(() => {
    setSelectionPolygonRequired(checkSelectionPolygon(shoppingCart));
  }, [shoppingCart]);
  // get initial global cart values if available
  useEffect(() => {
    const globalCart = appState.shoppingCart;
    if (globalCart.cart.length > 0) {
      setOrderExtent(globalCart.extent);
      setOrderMail(globalCart.email);
      setCustomerType(globalCart.customerType);
    }
  }, []);

  // check if a selection polygon is necessary, every time the shopping cart changes.
  useEffect(() => {
    updatePerimeterButtons();
  }, [shoppingCart, updatePerimeterButtons]);

  if (shoppingCart.length === 0) {
    return (
      <section style={{ ...styles.cartContainer, ...styles.emptyCartTitle }}>
        <Typography variant="h6">Ihr Warenkorb ist leer.</Typography>
        <Typography>
          Durch Klick auf <AddShoppingCartIcon style={{ margin: "0 8px" }} />
          im Geodatenkatalog oder in den geladenen Layer, können sie Geodaten
          dem Warenkorb hinzufügen.
        </Typography>
      </section>
    );
  } else {
    return (
      <section style={styles.cartContainer} className="shoppingcart">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 0 12px 0"
          }}
        >
          <Typography variant="body1">WARENKORB</Typography>

          <Button
            title="Warenkorb leeren"
            variant="outlined"
            size="small"
            onClick={() => {
              setOrderMessage("");
              if (appState.cancelDrawing) {
                // delete the geoshop order perimeter
                deletePolygon();
                // delete the geoshop pdf order perimeter
                removeVectorLayers({
                  map: appState.olMap,
                  removeType: "pdf"
                });
                appState.cancelDrawing();
              }
              emptyCart();
            }}
          >
            <RemoveShoppingCartIcon />
          </Button>
        </div>
        <List dense={true} style={styles.cart}>
          {shoppingCart.map((item, index) => (
            <CartItem
              key={item.currentProduct}
              product={item}
              removeItem={() => {
                const length = shoppingCart.length;
                const pdfProduct = item.currentProduct
                  .toLowerCase()
                  .indexOf("pdf")
                  ? true
                  : false;
                if (length === 1) {
                  removeFromCart(item);
                  if (deletePolygon) {
                    deletePolygon();
                  }
                  removeVectorLayers({
                    map: appState.olMap,
                    removeType: "pdf"
                  });
                }
                if (length > 1 && pdfProduct) {
                  removeVectorLayers({
                    map: appState.olMap,
                    removeType: "pdf"
                  });
                }
                removeFromCart(item);
              }}
              updatePerimeterButtons={updatePerimeterButtons}
              index={index}
            />
          ))}
        </List>

        <div>
          <Divider style={styles.divider} />
          <Typography variant="subtitle2">Bestellperimeter</Typography>
          <Button
            disabled={selectionPolygonRequired === false}
            color="secondary"
            variant="contained"
            fullWidth={true}
            size="small"
            style={styles.drawButton}
            onClick={() => {
              if (getDrawingMode(appState.olMap) === false) {
                const drawFuncs = setDrawingMode({
                  map: appState.olMap,
                  setOrderExtent
                });
                // assign (clojure) functions to interact with
                // the drawings.
                appState.cancelDrawing = drawFuncs.cancelDrawing;
                deletePolygon = drawFuncs.deletePolygon;
              }
            }}
          >
            {" "}
            Perimeter zeichnen
            <AspectRatio style={styles.buttonImage} />
          </Button>
          <DeleteButton
            disabled={selectionPolygonRequired === false}
            color="primary"
            variant="contained"
            fullWidth={true}
            size="small"
            onClick={() => {
              if (deletePolygon) {
                deletePolygon();
              }
            }}
          >
            Perimeter löschen
            <Delete style={styles.buttonImage} />
          </DeleteButton>
          <Button
            disabled={selectionPolygonRequired === false}
            style={styles.drawButton}
            fullWidth={true}
            variant="outlined"
            size="small"
            onClick={() => {
              if (appState.cancelDrawing) {
                appState.cancelDrawing();
              }
            }}
          >
            Zeichnen beenden
          </Button>
        </div>

        <Divider style={styles.divider} />
        <Typography variant="subtitle2">Persönliche Angaben</Typography>
        <FormControl error={!mailAdressError} style={{ minWidth: "100%" }}>
          <InputLabel htmlFor="e-mail-adress">Email Adresse</InputLabel>
          <Input
            id="e-mail-adress"
            inputProps={{ "aria-label": "e-mail-adress" }}
            onChange={e => {
              setOrderMail(e.target.value);
              updateGlobalShoppingCart({ key: "email", value: e.target.value });
              setMailAdressError(mailRegex.test(e.target.value));
            }}
            value={orderMail}
            type="email"
          />
        </FormControl>
        <FormControl
          style={{ padding: "16px 0 ", minWidth: "100%" }}
          error={!customerTypeError}
        >
          <Select
            native
            value={customerType}
            onChange={e => {
              setCustomerTypeError(
                e.target.value === customerTypes[0] ? false : true
              );
              setCustomerType(e.target.value);
              updateGlobalShoppingCart({
                key: "customerType",
                value: e.target.value
              });
            }}
            inputProps={{
              id: "benutzergruppe",
              name: "benutzergruppe",
              "aria-label": "benutzergruppe"
            }}
          >
            {customerTypes.map(type => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </Select>
        </FormControl>
        <Button
          style={styles.addCartButton}
          fullWidth={true}
          color="secondary"
          size="small"
          variant="outlined"
          onClick={() => {
            const mailCheck = mailRegex.test(orderMail);
            if (mailCheck === false) {
              alert("Bitte geben Sie eine Mailadresse ein...");
              setMailAdressError(mailCheck);
              return;
            }
            if (customerType === customerTypes[0]) {
              alert("Bitte wählen Sie eine Benutzergruppe");
              setCustomerTypeError(false);
              return;
            }
            if (
              orderExtent.length === 0 &&
              checkSelectionPolygon(shoppingCart) === true
            ) {
              alert("Bitte zeichnen Sie einen Bestellperimeter");
              return;
            }
            setSendOrder(true);
            setOrderMessage("");
            setSendError(false);
            processOrder({
              selection_polygon: getSelectionPolygon(orderExtent),
              min: getMin(orderExtent),
              max: getMax(orderExtent),
              orderMail,
              benutzergruppe: customerType,
              shoppingCart
            })
              .then(result => {
                setSendOrder(false);
                setOrderMessage(result);
                if (appState.cancelDrawing) {
                  appState.cancelDrawing();
                }
                const shoppingcart = document.querySelector(".shoppingcart");
                shoppingcart.scrollIntoView(false);
                window.setTimeout(() => {
                  setOrderMessage("");
                }, 4000);
              })
              .catch(error => {
                setSendError(true);
                setOrderMessage(error);
                setSendOrder(false);
              });
          }}
        >
          Bestellung abschicken
          {sendOrder && (
            <CircularProgress
              color="primary"
              style={{
                height: "20px",
                width: "20px",
                margin: "0 12px"
              }}
            />
          )}
        </Button>
        {orderMessage && (
          <div style={{ color: sendError ? "red" : "green", fontSize: "14px" }}>
            {orderMessage}
          </div>
        )}
      </section>
    );
  }
};

export default Cart;
Cart.propTypes = {
  shoppingCart: PropTypes.array.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  emptyCart: PropTypes.func.isRequired
};
