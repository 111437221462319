import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Cart from "./Cart";
import { updateGlobalShoppingCart } from "../../utils/geoshopUtil";
import appState from "../../utils/appState";

const GeoshopContainer = ({ shoppingCart, setShoppingCart }) => {
  /*
   * remove a single product from the cart
   * @param {object} product - the product to remove
   * @returns {object} - the removed product
   */
  const removeFromCart = product => {
    setShoppingCart(
      shoppingCart.filter(
        item => product.currentProduct !== item.currentProduct
      )
    );
    return product;
  };

  /*
   * remove all products from the shopping cart
   */
  const emptyCart = () => {
    setShoppingCart([]);
    updateGlobalShoppingCart({ key: "cart", value: [] });
    updateGlobalShoppingCart({ key: "extent", value: [] });
    updateGlobalShoppingCart({ key: "email", value: "" });
    updateGlobalShoppingCart({ key: "customerType", value: "" });
  };

  useEffect(() => {
    // get values from the global shoppingCart
    if (appState.shoppingCart.cart.length > 0) {
      setShoppingCart(appState.shoppingCart.cart);
    }
  }, [setShoppingCart]);
  // update the global cart array, if the local cart changes
  useEffect(() => {
    updateGlobalShoppingCart({ key: "cart", value: shoppingCart });
  }, [shoppingCart]);

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Cart
        shoppingCart={shoppingCart}
        removeFromCart={removeFromCart}
        emptyCart={emptyCart}
      />
    </div>
  );
};
export default GeoshopContainer;

GeoshopContainer.propTypes = {
  shoppingCart: PropTypes.array.isRequired,
  setShoppingCart: PropTypes.func.isRequired
};
