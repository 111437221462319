import {
  updateOlZIndexes,
  updateCesiumZIndexes,
  layerInTOC
} from "../../utils/layerUtil";
import { updateLayerIndex } from "../../utils/urlUtil";
import appState from "../../utils/appState";
export const layerReducer = (state, action) => {
  switch (action.type) {
    case "add":
      return addLayer(state, action);
    case "remove":
      return removeLayer(state, action);
    case "replace":
      return replaceLayer(state, action);
    case "reorder":
      return reorder(state, action.oldIndex, action.newIndex);
    default:
      return state;
  }
};

const addLayer = (layers, action) => {
  if (layerInTOC({ layers, layer: action.layer })) {
    return layers;
  }
  return [
    ...layers.slice(0, action.index), // add everything until the new index
    action.layer, // add new layer at provided index
    ...layers.slice(action.index) // add rest
  ];
};

const removeLayer = (layers, action) => {
  // get the index of the layer to remove.
  const index = layers.indexOf(action.layer);
  return [...layers.slice(0, index), ...layers.slice(index + 1)];
};

const replaceLayer = (layers, action) => {
  // make a copy and remove the first element
  const newLayers = layers.splice(1);
  // add the new layer as first element
  newLayers.unshift(action.layer);
  return newLayers;
};

const reorder = (state, oldIndex, newIndex) => {
  const movedEl = state[oldIndex];
  //remove the moving element from the array and return a new one.
  const layers = state.filter(layer => layer.name !== movedEl.name);
  // insert the dragged element at the right index in the new array.
  layers.splice(newIndex, 0, movedEl);
  let zIndex = 1;
  const zIndexList = {};
  for (var i = layers.length - 1; i >= 0; i--) {
    zIndexList[layers[i].name] = zIndex;
    zIndex++;
  }
  // update the ol and cesium zIndexes
  updateOlZIndexes(zIndexList);
  if (appState.cesiumViewer) {
    updateCesiumZIndexes(zIndexList);
  }
  // update the url
  updateLayerIndex(oldIndex, newIndex);
  return layers;
};
