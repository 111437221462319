import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  METADATA_PDF_URL,
  GEOSERVER_SEC,
  GEOSERVER_OVERLAY,
  HOST,
  API_URL,
  getLegendUrl
} from "../../utils/ENV";

const styles = theme => ({
  divider: {
    margin: "12px 0"
  },
  rightIcon: {
    margin: "0 12px"
  },
  selected: {
    backgroundColor: "whitesmoke"
  },
  pdfButton: {
    backgroundColor: "#fbc02d",
    "&:hover": {
      backgroundColor: "#fbc02d"
    }
  }
});

const LayerInfos = ({ metadata, classes }) => {
  const [lastUpdate, setLastUpdate] = useState("...");
  const [loadPdf, setLoadPdf] = useState(false);
  useEffect(() => {
    if (metadata.datensatz_extraktion_zeitstand) {
      fetch(
        `${API_URL}lastupdate?db=lisag_${metadata.namespace_geoserver}&table=${metadata.datensatz_extraktion_zeitstand}`
      )
        .then(lastUpdate => lastUpdate.json())
        .then(result => {
          result.error
            ? setLastUpdate("Unbekannt")
            : setLastUpdate(result["last_update"]);
        });
    }
  }, [metadata]);
  const hasWMS = metadata.layername_geoserver !== -1 ? true : false;
  const serviceUrl = metadata.secured
    ? `${HOST}${GEOSERVER_SEC}`
    : `${HOST}${GEOSERVER_OVERLAY}`;
  const wms_base_url = metadata.secured
    ? `${HOST}${GEOSERVER_SEC}`
    : `${HOST}/`;
  const legendUrl = metadata.geour_layer
    ? getLegendUrl({
        host: serviceUrl,
        ns: metadata.namespace_geoserver,
        layername: metadata.service_name_geoserver
      })
    : metadata.legende;
  const kurzbeschrieb = { __html: metadata.kurzbeschrieb };
  return (
    <section>
      <Typography variant="h6">Kurzbeschrieb:</Typography>
      <Typography dangerouslySetInnerHTML={kurzbeschrieb}></Typography>
      <Divider classes={{ root: classes.divider }} />
      <Typography variant="h6">Legende:</Typography>
      <Typography gutterBottom>
        <img src={legendUrl} alt="Legende" />
      </Typography>
      {metadata.nr_geobasisdatensatz && (
        <section>
          <Divider classes={{ root: classes.divider }} />
          <Typography variant="h6">Allgemeine Informationen:</Typography>
          <Table size="small" classes={{ root: classes.table }}>
            <TableBody>
              <TableRow classes={{ root: classes.selected }}>
                <TableCell>ID Geobasisdatensatz:</TableCell>
                <TableCell>{metadata.nr_geobasisdatensatz}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Erfassungsgenauigkeit:</TableCell>
                <TableCell>{metadata.erfassungsgenauigkeit}</TableCell>
              </TableRow>
              <TableRow classes={{ root: classes.selected }}>
                <TableCell>WMS Service:</TableCell>
                <TableCell>
                  {hasWMS && (
                    <a
                      href={wms_base_url + "wms?request=getCapabilities"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {wms_base_url + "wms?request=getCapabilities"}
                    </a>
                  )}
                  {!hasWMS && <span>{metadata.WMS_LINK}</span>}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>WFS Service:</TableCell>
                <TableCell>
                  {hasWMS && (
                    <a
                      href={wms_base_url + "wfs?request=getCapabilities"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {wms_base_url + "wfs?request=getCapabilities"}
                    </a>
                  )}
                  {!hasWMS && <span>Kein WFS verfügbar.</span>}
                </TableCell>
              </TableRow>
              <TableRow classes={{ root: classes.selected }}>
                <TableCell>Aktualisierungsrhythmus:</TableCell>
                <TableCell>{metadata.aktualitaet}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Letzte Aktualisierung:</TableCell>
                <TableCell>
                  {lastUpdate === "Unbekannt"
                    ? "Unbekannt"
                    : new Date(lastUpdate).toLocaleString("de-ch")}
                </TableCell>
              </TableRow>
              <TableRow classes={{ root: classes.selected }}>
                <TableCell>Metadaten PDF:</TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button
                      variant="contained"
                      size="small"
                      disabled={loadPdf}
                      className={classes.pdfButton}
                      onClick={async () => {
                        setLoadPdf(true);
                        const response = await fetch(
                          `${METADATA_PDF_URL}${metadata.name_offiziell}`
                        );
                        const pdf = await response.blob();
                        window.open(URL.createObjectURL(pdf));
                        setLoadPdf(false);
                      }}
                    >
                      Download
                      <CloudDownloadIcon className={classes.rightIcon} />
                    </Button>
                    {loadPdf && (
                      <CircularProgress
                        size={30}
                        color="secondary"
                        style={{ margin: "0 16px" }}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </section>
      )}
    </section>
  );
};
LayerInfos.propTypes = {
  classes: PropTypes.object.isRequired,
  metadata: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};
export default withStyles(styles)(LayerInfos);
