import { Draw, Select, Modify } from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { GeoJSON } from "ol/format";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import Overlay from "ol/Overlay";
import appState from "../utils/appState";
import { getSharingStyle } from "../utils/toolsUtil";
import { addParamToUrl, removeParamFromUrl } from "./urlUtil";
import { createBox } from "ol/interaction/Draw";
import { addLayer, projectGeojson, removeLayer } from "./layerUtil";
import styles from "./stylesUtil";

const hitTolerance = 15;
export default class drawingUtil {
  constructor(map, setActiveButton, layers, setLayer) {
    this.map = map;
    this.setActiveButton = setActiveButton;
    this.layers = layers;
    this.setLayer = setLayer;
    this.color = { hex: "#000000", rgba: "rgba(0,0,0,0.5)" };
    this.font = "20px sans-serif";
    this.startMessage = "Klicken um mit dem Zeichnen zu beginnen";
    this.continueMsg =
      "Klicken um mit dem Zeichnen fortzufahren, Doppelklick zum beenden.";
    this.selectMessage =
      "Klicken Sie auf eine Geometrie um diese zu selektieren.";
    this.source = new VectorSource();
    this.source.on("change", e => {
      const features = e.target.getFeatures();
      const urlDrawings = {
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: {
            name: "EPSG:3857"
          }
        },
        features: []
      };
      features.forEach((feature, index) => {
        // if feature is not selected add the style
        if (!Array.isArray(feature.getStyle())) {
          const geojson = JSON.parse(new GeoJSON().writeFeature(feature));
          const style = getSharingStyle({
            type: geojson.geometry.type,
            style: feature.getStyle().clone()
          });
          geojson.properties = {
            name: `Zeichnung ${index}`,
            _cesiumStyle: style,
            usageType: "draw"
          };
          urlDrawings.features.push(geojson);
        }
      });
      if (urlDrawings.features.length > 0) {
        if (!this.mapLayer) {
          this.mapLayer = this.createMapLayer();
          this.mapLayer.cesiumGeojson = projectGeojson({
            geojson: urlDrawings
          });
          addLayer({
            layer: this.mapLayer,
            setLayer: this.setLayer,
            layers: this.layers,
            updateUrl: false
          });
        }
        this.mapLayer.cesiumGeojson = projectGeojson({
          geojson: urlDrawings
        });
        addParamToUrl("drawings", urlDrawings);
      } else if (features.length === 0) {
        removeParamFromUrl("drawings");
        // remove the layer from the map and the toc
        removeLayer(this.mapLayer, this.setLayer);
        this.mapLayer = null;
      }

      if (window.location.href.length > 1990) {
        removeParamFromUrl("drawings");
        appState.setSnackbar({
          open: true,
          message:
            "INFO: Zu viele Zeichnungen. Die Zeichnungen können nicht mehr via URL verschickt werden."
        });
      }
    });

    // the layer to display the drawings
    this.vectorLayer = new VectorLayer({
      source: this.source,
      style: null
    });
    this.vectorLayer.usageType = "draw";
    // necessary for reordering
    this.vectorLayer.name = "Zeichnung (aktiv)";

    //input for user text
    this.textInput = new Overlay({
      element: this.getTextInput(),
      positioning: "center-center"
    });

    // select
    this.select = new Select({
      style: styles.getDrawEditStyle(),
      layers: [this.vectorLayer],
      hitTolerance
    });
    this.select.on("select", e => {
      if (e.selected.length === 0) {
        appState.setSnackbar({
          open: true,
          message: this.selectMessage
        });
      } else {
        appState.setSnackbar({
          open: true,
          message: "Geometrie selektiert."
        });
        this.lastSelected = e.selected;
      }
    });
    //modify
    this.modify = new Modify({
      features: this.select.getFeatures()
    });
  }

  createMapLayer() {
    return {
      extern: true,
      opacity: 1,
      visible: true,
      name: "Zeichnung (aktiv)",
      serviceName: "Zeichnung (aktiv)",
      mapLayer: this.vectorLayer
    };
  }

  addInteraction(button) {
    // case select
    if (button.format === "select") {
      this.map.addInteraction(this.select);
      this.map.addInteraction(this.modify);
      this.map.on("pointermove", this.pointerMoveFunc);
      appState.setSnackbar({
        open: true,
        message: this.selectMessage
      });
      return;
    }
    // case delete selected
    if (button.format === "delete selected") {
      if (this.select.getFeatures().getLength() > 0) {
        this.select.getFeatures().forEach(feature => {
          this.source.removeFeature(feature);
        });
        this.select.getFeatures().clear();
        this.lastSelected = [];
        appState.setSnackbar({
          open: true,
          message: this.selectMessage
        });
        return;
      } else {
        if (this.lastSelected && this.lastSelected.length > 0) {
          this.source.removeFeature(this.lastSelected[0]);
          this.removeInteraction();
          appState.setSnackbar({
            open: false,
            message: ""
          });
        }
        return;
      }
    }

    // case a drawing button got clicked
    const message =
      button.format === "text"
        ? "Platzierung durch Klick auf die Karte wählen."
        : this.startMessage;
    appState.setSnackbar({ open: true, message: message });
    // draw interaction instance
    this.draw = new Draw({
      source: this.source,
      style: styles.getDrawEditStyle(),
      type: button.geometryType,
      geometryFunction: button.geometryType === "Circle" ? createBox() : null
    });
    // drawstart event
    this.draw.on("drawstart", evt => {
      appState.setSnackbar({ open: true, message: this.continueMsg });
    });
    // drawend event
    this.draw.on("drawend", e => {
      // set the style on the created feature
      e.feature.setStyle(this.getStyle(button));
      // with the button as a property,
      // we can get the right style for a selected feature
      e.feature.button = button;
      if (button.format !== "text") {
        appState.setSnackbar({ open: true, message: this.startMessage });
      } else {
        // open textarea for text input
        this.textInput.setPosition(e.feature.getGeometry().getCoordinates());
        this.textInput.feature = e.feature; // used to set the style
        this.map.addOverlay(this.textInput);
        const textarea = document.querySelector("textarea");
        textarea.value = "";
        textarea.focus();
        this.hideSnackbar();
      }
      if (e.feature) {
        this.lastSelected = [e.feature];
      }
    });
    this.map.addInteraction(this.draw);
  }

  /*
   * remove draw, select and modify interaction.
   */
  removeInteraction(removeLastSelected = true) {
    if (this.draw) {
      this.map.removeInteraction(this.draw);
    }
    if (this.select) {
      this.map.removeInteraction(this.select);
    }
    if (this.modify) {
      this.map.removeInteraction(this.modify);
    }
    this.map.un("pointermove", this.pointerMoveFunc);
    if (removeLastSelected) {
      this.lastSelected = [];
    }
  }

  /*
   * get the style for the drawing features.
   * @param {object} button - button object like stored in toolsUtil.js
   * @returns {object} - ol/style/Style instance.
   */
  getStyle(button, label = "") {
    const style = new Style({
      fill: new Fill({
        color: this.color.rgba
      }),
      stroke: new Stroke({
        color: this.color.hex,
        width: 3
      }),
      image: new CircleStyle({
        radius: 8,
        stroke: new Stroke({
          color: this.color.hex,
          width: 3
        }),
        fill: new Fill({
          color: this.color.rgba
        })
      })
    });
    if (button.format === "dashed") {
      style.getStroke().setLineDash([10, 10]);
    }
    if (button.format === "text") {
      const text = new Text({
        font: this.font,
        text: label,
        fill: new Fill({ color: this.color.hex }),
        stroke: new Stroke({
          color: this.getTextStrokeColor(this.color.hex),
          width: 5
        })
      });
      style.getImage().setRadius(1);
      style.setText(text);
    }
    return style;
  }

  /*
   * remove all drawing features from the source.
   */
  deleteDrawings() {
    this.source.clear(true);
    removeParamFromUrl("draw");
  }

  /*
   * sets the drawing color
   * @param {object} color - object like stored in Colors.jsx.
   * @returns void
   */
  setColor(color) {
    this.color = color;
    let selected = this.select.getFeatures().getArray();
    if (selected.length === 0) {
      selected = this.lastSelected || [];
    }
    // change color of selected features
    if (selected.length > 0) {
      const ol_uids = selected.map(feature => feature.ol_uid);
      this.select.getFeatures().clear(); // we want to change the real style not the select style
      ol_uids.forEach(uid => {
        this.source.getFeatures().forEach(feature => {
          if (uid === feature.ol_uid) {
            feature.setStyle(
              this.getStyle(feature.button, feature.label || "")
            );
          }
        });
      });
      if (this.select.getMap()) {
        appState.setSnackbar({ open: true, message: this.selectMessage });
      }
    }
  }

  /*
   * get the textarea field for user text input
   * @returns {domElement} inputContainer - a div with the necessary content.
   */
  getTextInput() {
    const inputContainer = document.createElement("div");
    inputContainer.classList.add("textinput__container");
    const textarea = document.createElement("textarea");
    textarea.placeholder = "Bitte gewünschten Text eingeben.";
    textarea.cols = 40;
    textarea.rows = 5;
    textarea.style.fontSize = "14px";
    inputContainer.appendChild(textarea);
    const buttonContainer = document.createElement("div");
    buttonContainer.classList.add("textinput__buttoncontainer");
    const cancelButton = document.createElement("button");
    cancelButton.innerText = "Abbrechen";
    cancelButton.addEventListener("click", () => {
      this.map.removeOverlay(this.textInput);
    });
    const okButton = document.createElement("button");
    okButton.addEventListener("click", () => {
      textarea.value = textarea.value.trim();
      if (textarea.value !== "") {
        const currentStyle = this.getStyle({ format: "text" });
        currentStyle.getText().setText(textarea.value);
        this.textInput.feature.setStyle(currentStyle);
        // used to change the text color for selected feature
        this.textInput.feature.label = textarea.value;
        this.textInput.text = textarea.value;
      }
      this.map.removeOverlay(this.textInput);
      this.removeInteraction(false);
      this.hideSnackbar();
      this.setActiveButton({});
    });
    okButton.innerText = "OK";
    buttonContainer.appendChild(cancelButton);
    buttonContainer.appendChild(okButton);
    inputContainer.appendChild(buttonContainer);
    return inputContainer;
  }

  /*
   * gets the text stroke color based on the fill color.
   * this is helpful to have better contrast.
   * @param {string} fillColor - the fill color of the text.
   * @returns {string} strokeColor - the stroke color as hex value.
   */
  getTextStrokeColor(fillColor) {
    switch (fillColor) {
      case "#ffffff":
      case "#FFFF00":
      case "#00FFFF":
        return "#000000";
      default:
        return "#ffffff";
    }
  }

  /*
   * setter for the font size
   * @param {number} fontSize - the new fontSize to set.
   * @returns {string} - the new font as string.
   */
  setFontSize(fontSize) {
    if (!fontSize) {
      return this.font;
    }
    this.font = fontSize;
    let selected = this.select.getFeatures().getArray();
    if (selected.length === 0) {
      selected = this.lastSelected || [];
    }

    if (selected.length > 0) {
      const ol_uids = selected.map(feature => feature.ol_uid);
      this.select.getFeatures().clear(); // we want to change the real style not the select style
      ol_uids.forEach(uid => {
        this.source.getFeatures().forEach(feature => {
          if (uid === feature.ol_uid && feature.label) {
            const style = feature.getStyle().clone();
            style.getText().setFont(this.font);
            feature.setStyle(style);
          }
        });
      });
      if (this.select.getMap()) {
        appState.setSnackbar({ open: true, message: this.selectMessage });
      }
    }
    return this.font;
  }

  /*
   * hides the snackbar.
   */
  hideSnackbar() {
    appState.setSnackbar({ open: false, message: "" });
  }

  /*
   * create a pointer cursor if the
   * mouse enters a drawing geometry in select mode.
   */
  pointerMoveFunc(event) {
    const map = event.map;
    if (event.dragging) {
      return;
    }
    const pixel = map.getEventPixel(event.originalEvent);
    const features = map.getFeaturesAtPixel(pixel, {
      hitTolerance, // must be the same as the select tolerance
      layerFilter: layer => {
        if (layer.name === "Zeichnung (aktiv)") {
          return true;
        } else {
          return false;
        }
      }
    });
    if (features.length > 0) {
      map.getTargetElement().style.cursor = "pointer";
    } else {
      map.getTargetElement().style.cursor = "default";
    }
  }
}
