import React from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import {
  getSidebarVisibilityState,
  removeDrawingInteractions
} from "../../utils/sidebarUtil";
import {
  hideCesiumStreetviewCoverage,
  loadStreetViewCoverage
} from "../../utils/streetviewUtil";
import { changeVectorLayerVisibility } from "../olMap/renderOlMap";
import appState from "../../utils/appState";
import { loadScript } from "../../utils/loadScript";
import { hideStreetSmart } from "../../utils/toolsUtil";

const MenuChip = ({ item, setItems, shoppingCart }) => {
  const { label, visible, icon } = item;
  return (
    <Chip
      sx={{
        boxShadow: "0.1px 1px 1.5px 0 rgba(112,112,112,0.7);",
        margin: "0 0 0 6px"
      }}
      size="small"
      classes={{ colorSecondary: "menuchip__colorsecondary" }}
      icon={icon}
      label={
        label === "Warenkorb" ? `${label} (${shoppingCart.length})` : label
      }
      clickable
      color={visible === false ? "default" : "secondary"}
      onClick={e => {
        setItems(oldState => getSidebarVisibilityState(oldState, label));
        // hide street smart and street view except "Geladene Layer" was clicked
        if (label !== "Geladene Layer") {
          if (appState.svCoverage.getVisible()) {
            appState.svCoverage.setVisible(false);
            hideCesiumStreetviewCoverage();
          }
          hideStreetSmart();
        }
        // allways hide any vector layers when a sidebar gets clicked
        changeVectorLayerVisibility({
          map: appState.olMap,
          type: "hide",
          usageType: "pdf geoshop print"
        });
        if (label === "Warenkorb") {
          changeVectorLayerVisibility({
            map: appState.olMap,
            type: visible ? "hide" : "show",
            usageType: "pdf geoshop"
          });
        }
        if (label === "Drucken") {
          changeVectorLayerVisibility({
            map: appState.olMap,
            type: visible ? "hide" : "show",
            usageType: "print"
          });
        }
        if (label === "Street View") {
          if (visible === false) {
            if (!window.google) {
              loadScript(
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyBrNv-6Pig7PeR9LxZihxFT1XkXEs0DBZA"
              )
                .then(response => {
                  loadStreetViewCoverage();
                  console.info("StreetView", response);
                })
                .catch(error => {
                  appState.setSnackbar({
                    open: true,
                    message: "Street View konnte nicht geladen werden."
                  });
                });
            }
            loadStreetViewCoverage();
          }
        }
        // allways remove the drawing interactions when a menu chip gets clicked.
        removeDrawingInteractions();
      }}
    />
  );
};

export default MenuChip;
MenuChip.propTypes = {
  item: PropTypes.object.isRequired,
  setItems: PropTypes.func.isRequired,
  shoppingCart: PropTypes.array
};
