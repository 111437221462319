import React from "react";
import PropTypes from "prop-types";
import { Chip, Typography } from "@mui/material";
import { processSuggestion } from "../../utils/searchUtil";

const orthofotos = [
  {
    value: 2018
  },
  {
    value: 2016
  },
  {
    value: 2013
  },
  {
    value: 2010
  },
  {
    value: 2004
  },
  {
    value: 1997
  },
  {
    value: 1985
  }
];

const Zeitreise = ({ setLayer, layers }) => {
  return (
    <div className="basemaps__zeitreisecontainer">
      <Typography variant="body1">Orthofotos:</Typography>
      <section>
        {orthofotos.map(orthofoto => (
          <Chip
            style={{ margin: "4px" }}
            key={orthofoto.value}
            label={orthofoto.value}
            onClick={() => {
              const year = orthofoto.value;
              const layer = {};
              layer.year = year;
              layer.sec = false;
              layer.opacity = 1;
              layer.visible = true;
              layer.name = `Orthofoto Zeitreise ${year}`;
              layer.serviceName = `Orthofoto Zeitreise ${year}`;
              const suggestion = { groupname: "Zeitreise", layer };
              processSuggestion({ suggestion, setLayer, layers });
            }}
          />
        ))}
      </section>
    </div>
  );
};

export default Zeitreise;
Zeitreise.propTypes = {
  setLayer: PropTypes.func.isRequired,
  layers: PropTypes.array.isRequired
};
