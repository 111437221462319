export const geodatatypes = {
  "Grundlagen und Planung": true,
  "Natur und Umwelt": true,
  "Bevölkerung und Wirtschaft": true
};
/*
 * get all the layers which match the enabled categories
 * @param {object} params
 * @param {array} data - the data to categorize
 * @param {object} categories - the categories to filter the data
 * @returns {array} result - a new array instance with the filtered data
 */
export const categorize = ({ data, categories } = {}) => {
  const result = [];
  // return empty array when parameters are missing
  if (!data || !categories) {
    return result;
  }
  // return an empty array when all the categories are turned off
  if (allCategoriesOff(categories)) {
    return result;
  }
  // return all data, when all the categories are turned on
  if (allCategoriesOn(categories)) {
    return data;
  }

  // return filtered data when only some categories are enabled
  return filterDataByCategory({
    data,
    categories: getOnCategories(categories)
  });
};

/*
 * perfoms the filter process when a category filter chip gets clicked
 * @param {object} params
 * @param {string} params.category - name of the category to perform the filter
 * @param {object} params.categories - categories as state object like the geodatatypes variable on top
 * @param {function} params.setCategories - function to update the categories object
 * @param {array} params.layers - layer objects to perfom the filter
 * @param {function} params.setLayers - function to update the layers array
 * @returns {array} - a new array of filtered layers
 */
export const filterCategories = ({
  category,
  categories,
  setCategories,
  layers,
  setLayers
}) => {
  const newCategories = {
    ...categories,
    [category]: !categories[category]
  };
  setCategories(newCategories);
  const newcategorizedLayers = categorize({
    data: layers,
    categories: newCategories
  });
  setLayers(newcategorizedLayers);
  return newcategorizedLayers;
};

/*
 * checks if all the categories are turned on
 * @param {object} categories
 * @returns {boolean} - true if all the layers are turned on, false otherwise
 */
const allCategoriesOn = categories => {
  if (!categories) {
    return;
  }
  for (var i in categories) {
    if (categories[i] === false) {
      return false;
    }
  }
  return true;
};

/*
 * checks if all the categories are turned off
 * @param {object} categories
 * @returns {boolean} - true if all the layers are turned on, false otherwise
 */
const allCategoriesOff = categories => {
  if (!categories) {
    return;
  }
  for (var i in categories) {
    if (categories[i] === true) {
      return false;
    }
  }
  return true;
};

/*
 * get all the categories which are turned on
 * @param {object} categories - the categories to filter
 * @returns {array} with all the turned on category names as strings
 */
const getOnCategories = categories => {
  const result = [];
  for (let i in categories) {
    if (categories[i] === true) {
      result.push(i);
    }
  }
  return result;
};

/*
 * filters data by categories
 * @param {object} params
 * @param {array} params.data - the data to filter
 * @param {array} params.categories - category names to search for
 * @returns {array} - data filtered by categories, or false in case of failure.
 */
const filterDataByCategory = ({ data, categories } = {}) => {
  if (!data) {
    return false;
  }
  if (!categories) {
    return data;
  }
  const result = data.filter(layer =>
    categories.indexOf(layer.geodaten_kategorie) !== -1 ? true : false
  );
  return result;
};
