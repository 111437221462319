import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Attributes from "./Attributes";
import {
  addOlGeojson,
  removeCesiumGeojsonOverlays,
  removeVectorLayers
} from "../../utils/layerUtil";
import appState from "../../utils/appState";

/*
 * gets the title for a single accordion element
 * @param {object} geojson - gejson object from getFeatureInfo request.
 * @returns {string} the title.
 */
const getTitle = geojson => {
  if (!geojson) return "?";
  if (geojson.title) return geojson.title;
  if (geojson.id) return geojson.id;
  if (geojson.name) return geojson.name;
  return "?";
};

const InfoBoxContent = ({ featureInfos, mapType }) => {
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    const firstFeature = featureInfos[0];
    let center = false;
    if (firstFeature) {
      if (
        firstFeature.properties.usageType &&
        firstFeature.properties.usageType === "search"
      ) {
        center = true;
      }
      // open the first expansion panel for better user experience
      setExpanded(firstFeature.id);
      if (mapType.value === "2D") {
        addOlGeojson({
          geojson: firstFeature,
          center,
          usageType: firstFeature.properties.usageType
        });
      }
      if (mapType.value === "3D") {
        if (firstFeature.properties._cesiumDatasource) {
          // add the first feature
          appState.cesiumViewer.dataSources.add(
            firstFeature.properties._cesiumDatasource
          );
        }
      }
    }
  }, [featureInfos, mapType.value]);
  return (
    <div className="infobox__content">
      {featureInfos.map((geojson, i) => (
        <Accordion
          expanded={expanded === geojson.id}
          key={`${geojson.id}_${i}`}
          onChange={(event, isExpanded) => {
            const datasource = geojson.properties._cesiumDatasource;
            setExpanded(isExpanded ? geojson.id : false);
            if (isExpanded === false) {
              removeVectorLayers({
                map: appState.olMap,
                removeType: "featureInfo_search"
              });
              if (appState.cesiumViewer) {
                if (datasource) {
                  appState.cesiumViewer.dataSources.remove(datasource);
                  appState.cesiumViewer.scene.requestRender();
                }
              }
            } else {
              if (mapType.value === "2D") {
                addOlGeojson({
                  geojson,
                  center: false,
                  usageType: geojson.properties.usageType
                });
              }
              if (mapType.value === "3D") {
                removeCesiumGeojsonOverlays();
                if (datasource) {
                  appState.cesiumViewer.dataSources.add(datasource);
                }
              }
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className="infobox__layertitle"
              noWrap={true}
              variant="subtitle2"
              title={geojson.layername || geojson.id}
            >
              {getTitle(geojson)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Attributes attributes={geojson.properties} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default InfoBoxContent;

InfoBoxContent.propTypes = {
  featureInfos: PropTypes.array.isRequired,
  mapType: PropTypes.object.isRequired
};
