import React from "react";
import PropTypes from "prop-types";
import NewsItem from "./NewsItem";
import NewsArchive from "./NewsArchive";
import { Typography } from "@material-ui/core";

const NewsList = ({ news }) => {
  return (
    <section>
      {news.current.length > 0 &&
        news.current.map(item => <NewsItem item={item} key={item.id} />)}
      {news.current.length === 0 && (
        <Typography variant="body1">
          Momentan gibt es nichts neues zu berichten...
        </Typography>
      )}
      {news.archive.length > 0 && <NewsArchive archive={news.archive} />}
    </section>
  );
};
export default NewsList;
NewsList.propTypes = {
  news: PropTypes.object.isRequired
};
