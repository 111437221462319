import React from "react";
import PropTypes from "prop-types";
import ButtonBase from "@mui/material/ButtonBase";
import "./Basemaps.css";

const Basemaps = props => {
  const { sendBasemapMachine, currentBasemap } = props;
  return (
    <div className="basemaps">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "4px"
        }}
      >
        <span>Hintergrundkarte:</span>
      </div>

      <div className="basemaps__objects">
        {props.basemaps.map(basemap => {
          return (
            <div className={"basemaps__object"} key={basemap.name}>
              <ButtonBase
                aria-label={basemap.id}
                onClick={() => {
                  sendBasemapMachine(basemap.id.toUpperCase());
                }}
              >
                <img
                  src={basemap.backgroundImage}
                  alt={basemap.name}
                  className={
                    currentBasemap === basemap.id
                      ? "basemaps__image basemap__image_active"
                      : "basemaps__image"
                  }
                />
              </ButtonBase>
              <div>{basemap.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
Basemaps.propTypes = {
  basemaps: PropTypes.array.isRequired,
  currentBasemap: PropTypes.string.isRequired,
  sendBasemapMachine: PropTypes.func.isRequired
};
export default Basemaps;
