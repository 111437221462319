import queryString from "query-string";
/*
 * updates the query parameters of the url
 * @param {object} params an object containing key:value pairs of search params.
 * @returns {string} the search params section of the url.
 */
export const updateUrl = params => {
  if (params) {
    const splittedUrl = window.location.href.split("?");
    const parsedParams = parseSearchParams(window.location.search);
    //update the parsed params with the parameter values
    const newParams = { ...parsedParams, ...params };
    const stringified = queryString.stringify(newParams);
    window.history.replaceState(
      newParams,
      document.title,
      splittedUrl[0] + "?" + stringified
    );
    return window.location.search;
  }
  // when no params are provided return the current url params as a string
  return window.location.search;
};
/*
 * completely replaces the query parameters of the url.
 * @param {object} params an object containing key:value pairs of search params.
 * @returns {string} the search params section of the url.
 */
export const replaceUrl = params => {
  if (params) {
    const splittedUrl = window.location.href.split("?");
    const stringified = queryString.stringify(params);
    window.history.replaceState(
      params,
      document.title,
      splittedUrl[0] + "?" + stringified
    );
    return window.location.search;
  }
  // when no params are provided return the current url params as a string
  return window.location.search;
};

/*
 * parse a string of url query parameters into an object
 * @param {string} params a string like returned from location.search
 * @returns {object} query parameters as key:value pairs
 */
export const parseSearchParams = params => {
  return queryString.parse(params);
};

/*
 * add a new layer to the url query parameter, including visibility and transpareny
 * @param {object} layer - {name: layername, visibility: true/false, transparency: value between 0 and 1}
 * @returns {string} the updatet url
 */
export const addLayerToUrl = layer => {
  // check if there is already a layer parameter in the url
  const currentParams = parseSearchParams(window.location.search);
  // if it's the first layer to add
  if (!currentParams.layers) {
    currentParams.layers = layer.name.toString();
    currentParams.visibility = layer.visibility.toString();
    currentParams.opacity = layer.opacity.toString();
    return updateUrl(currentParams);
  }
  // in case there are already layers loaded
  else {
    const layers = currentParams.layers.split(",");
    if (layers.indexOf(layer.name) !== -1) {
      return;
    }
    layers.unshift(layer.name);
    const visibilities = currentParams.visibility.split(",");
    visibilities.unshift(layer.visibility.toString());
    const opacities = currentParams.opacity.split(",");
    opacities.unshift(layer.opacity.toString());
    return updateUrl({
      layers: layers.join(","),
      visibility: visibilities.join(","),
      opacity: opacities.join(",")
    });
  }
};

/*
 * remove a layer from the url query parameter, including visibility and transpareny
 * @param {object} layer - {name: layername}
 * @returns {string} the updatet url or false in case of failure.
 */
export const removeLayerFromUrl = layer => {
  if (!layer || layer.extern) {
    return false;
  }
  const currentParams = parseSearchParams(window.location.search);
  const layers = currentParams.layers.split(",");
  const visibilities = currentParams.visibility.split(",");
  const opacities = currentParams.opacity.split(",");
  // search for the array index of the particular layer
  const index = layers.indexOf(layer.name);
  if (index !== -1) {
    if (layers.length === 1) {
      // delete the entries for layers, visibility and opacity
      delete currentParams.layers;
      delete currentParams.opacity;
      delete currentParams.visibility;
      return replaceUrl(currentParams);
    } else {
      // remove the values from the url string
      layers.splice(index, 1);
      visibilities.splice(index, 1);
      opacities.splice(index, 1);
      return updateUrl({
        layers: layers.join(","),
        visibility: visibilities.join(","),
        opacity: opacities.join(",")
      });
    }
  }
};

/*
 * updates the visibility and/or opacitiy url params of a layer
 * @param {object} layer - {name: layername, opacity:0-1, visibility: true/false}
 * @returns {string} the updatet url or false in case of failure.
 */
export const updateUrlVisibilityOpacity = layer => {
  if (!layer || layer.extern) {
    return false;
  }
  const currentParams = parseSearchParams(window.location.search);
  let { layers, visibility, opacity } = currentParams; //url strings
  const layersArr = layers.split(",");
  if (!layers || !Array.isArray(layersArr)) {
    return false;
  }

  let visibilityArr = [];
  let opacityArr = [];
  if (visibility) {
    visibilityArr = visibility.split(",");
  }
  if (opacity) {
    opacityArr = opacity.split(",");
  }
  //update visibility and opacity for the layer
  //and every layer that has no value for it in the url
  layersArr.forEach((name, i) => {
    if (name === layer.name) {
      visibilityArr[i] = layer.visible.toString();
      opacityArr[i] = parseFloat(layer.opacity.toString()).toFixed(1);
    } else {
      if (!visibilityArr[i]) {
        visibilityArr[i] = "true";
      }
      if (!opacityArr[i]) {
        opacityArr[i] = 1.0;
      }
    }
  });
  return updateUrl({
    opacity: opacityArr.join(","),
    visibility: visibilityArr.join(",")
  });
};

/*
 * updates the position/order of layer in the url including opacity and visibility.
 * @param {number} oldIndex - the index of the layer to reorder.
 * @param {number} newIndex - the new position of the layer.
 * @returns {string} - stringified geojson object
 */
export const updateLayerIndex = (oldIndex, newIndex) => {
  if (
    oldIndex === undefined ||
    newIndex === undefined ||
    oldIndex === newIndex
  ) {
    return;
  }
  const currentParams = parseSearchParams(window.location.search);
  const layers = currentParams.layers.split(",").filter(value => value !== "");
  const visibilities = currentParams.visibility
    .split(",")
    .filter(value => value !== ""); //filter out empty values which can occur, when having external wms layers.
  const opacities = currentParams.opacity
    .split(",")
    .filter(value => value !== "");
  return updateUrl({
    layers: replaceElement({ array: layers, oldIndex, newIndex }).join(","),
    visibility: replaceElement({
      array: visibilities,
      oldIndex,
      newIndex
    }).join(","),
    opacity: replaceElement({ array: opacities, oldIndex, newIndex }).join(",")
  });
};

/*
 * set an element of an array to a new position.
 * @param {object} params - function parameter object
 * @param {array}  array - the array to replace an element.
 * @param {number} oldIndex - index of the element to replace
 * @param {number} params.newIndex - the new position for the element.
 * @returns {array} array - the reordered array.
 */
const replaceElement = ({ array = [], oldIndex, newIndex } = {}) => {
  if (array.length < 2) {
    return array;
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
};

/*
 * adds a stringified a new url parameter with a stringified content.
 * @param {string}, param - name of the url parameter
 * @param {object} content - url parameter value.
 * @returns {string} - stringified parameter
 */
export const addParamToUrl = (param, content) => {
  const contentString = JSON.stringify(content);
  updateUrl({ [param]: contentString });
  return contentString;
};

/*
 * removes a query parameter from the url
 * @param {string} parameter - name of the parameter to remove
 * @returns {object} currentParams - the new parameter object without the search key
 */
export const removeParamFromUrl = param => {
  const currentParams = parseSearchParams(window.location.search);
  delete currentParams[param];
  replaceUrl(currentParams);
};
