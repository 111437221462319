import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import TimelineIcon from "@material-ui/icons/Timeline";
import FormatShapesIcon from "@material-ui/icons/FormatShapes";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { makeStyles } from "@material-ui/core/styles";
import MeasureTool from "../../utils/measureUtil";
import appState from "../../utils/appState";
import "./Tools.css";
const useStyles = makeStyles({
  outlinedPrimary: {
    backgroundColor: "#fbc02d !important",
    border: "1px solid black !important"
  }
});
const measureButtons = [
  { title: "Koordinate messen", geometryType: "Point", icon: GpsFixedIcon },
  { title: "Länge messen", geometryType: "LineString", icon: TimelineIcon },
  { title: "Fläche messen", geometryType: "Polygon", icon: FormatShapesIcon },
  {
    title: "Geometrien löschen / Messen beenden",
    geometryType: null,
    icon: DeleteForeverIcon
  }
];
const Measure = props => {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState({});
  useEffect(() => {
    if (appState.measureTool) {
      return;
    } else {
      appState.measureTool = new MeasureTool(appState.olMap, setActiveButton);
    }
  }, [setActiveButton]);
  return (
    <section style={{ padding: "12px 0 0 0" }}>
      <div className="buttonGroup__container">
        <ButtonGroup aria-label="messwerkzeuge">
          {measureButtons.map(button => (
            <Button
              classes={{
                outlinedPrimary: classes.outlinedPrimary
              }}
              color={activeButton === button ? "primary" : "default"}
              key={button.title}
              size="small"
              title={button.title}
              onClick={() => {
                setActiveButton(button);
                if (appState.drawingTool) {
                  appState.drawingTool.removeInteraction();
                  appState.drawingTool.setActiveButton({});
                }
                if (button.geometryType === null) {
                  appState.measureTool.deleteOverlays();
                  return;
                }
                appState.measureTool.setMode(button.geometryType);
              }}
            >
              <button.icon />
            </Button>
          ))}
        </ButtonGroup>
        <ButtonGroup>
          <Button
            size="small"
            title="Messen beenden"
            onClick={() => {
              if (appState.drawingTool) {
                appState.drawingTool.removeInteraction();
              }
              appState.measureTool.removeInteraction();
            }}
          >
            beenden
          </Button>
        </ButtonGroup>
      </div>
    </section>
  );
};
export default Measure;
