import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import { Public, MapOutlined } from "@mui/icons-material";

const MapSwitch = props => {
  const { mapType, switchMap } = props;
  if (mapType === "2D") {
    return (
      <IconButton
        aria-label="start cesium"
        color="inherit"
        onClick={() => (switchMap ? switchMap() : null)}
      >
        <Public />
        <span style={{ fontSize: "0.875rem", padding: "0 0 0 4px" }}>
          3D Ansicht
        </span>
      </IconButton>
    );
  }
  return (
    <IconButton
      aria-label="start openlayers"
      color="inherit"
      onClick={() => (switchMap ? switchMap() : null)}
    >
      <MapOutlined />
      <span style={{ fontSize: "0.875rem", padding: "0 0 0 4px" }}>
        2D Ansicht
      </span>
    </IconButton>
  );
};
MapSwitch.propTypes = {
  mapType: PropTypes.string.isRequired,
  switchMap: PropTypes.func.isRequired
};
export default MapSwitch;
