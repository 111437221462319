import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
  Icon
} from "ol/style";
import { Polygon, Point, MultiPoint } from "ol/geom";
import { getStationType } from "../utils/messdatenUtil";
import pin from "../components/tools/img/pin.svg";
/* storage for loaded messdaten icons
 * so that they do not need to be lazy loaded twice
 */
const messdatenImages = {};

/*
 * needed for the edit style when drawing.
 */
const white = [255, 255, 255, 1];
const width = 3;

/*
 * ol search styles
 */
const olImage = new CircleStyle({
  radius: 7,
  fill: null,
  stroke: new Stroke({ color: "#ffff00", width: 4 })
});

/*
 * cesium search styles
 */
const cesiumSearchPointStyle = {
  fill: "#000000",
  radius: 8,
  stroke: { color: "#ffff00", width: 2 }
};
const cesiumSearchLineStyle = {
  stroke: { color: "#ffff00", width: 3, dashed: null }
};
const cesiumSearchPolygonStyle = {
  fill: "#ffff00",
  stroke: { color: "#ff0000", width: 3, dashed: null }
};

/*
 * kml styles
 */
const cesiumKmlPointStyle = {
  fill: "#ffd600",
  radius: 5,
  stroke: { color: "#000000", width: 2 }
};
const cesiumKmlLineStyle = {
  stroke: { color: cesiumKmlPointStyle.fill, width: 3, dashed: null }
};
const cesiumKmlPolygonStyle = {
  fill: cesiumKmlPointStyle.fill,
  stroke: { color: "#000000", width: 3, dashed: null }
};

const olKmlPointStyle = new Style({
  image: new CircleStyle({
    radius: cesiumKmlPointStyle.radius,
    fill: new Fill({ color: cesiumKmlPointStyle.fill }),
    stroke: new Stroke({
      color: cesiumKmlPointStyle.stroke.color,
      width: cesiumKmlPointStyle.stroke.width
    })
  })
});
const olKmlLineStyle = new Style({
  stroke: new Stroke({
    color: cesiumKmlLineStyle.stroke.color,
    width: cesiumKmlLineStyle.stroke.width
  })
});
const olKmlPolygonStyle = new Style({
  stroke: new Stroke({
    color: cesiumKmlPolygonStyle.stroke.color,
    width: cesiumKmlPolygonStyle.stroke.width
  }),
  fill: new Fill({
    color: cesiumKmlPolygonStyle.fill
  })
});

const styles = {
  olFoto: new Style({
    image: new Icon({
      src: pin
    })
  }),
  cesiumFoto: {
    image: pin,
    width: 24,
    height: 24,
    scale: 1
  },
  geoshop: new Style({
    fill: new Fill({ color: "rgba(255,255,255,0.2" }),
    stroke: new Stroke({ color: "#ffc33", width: 2 }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({ color: "#ffcc33" })
    })
  }),
  olKml: {
    Point: olKmlPointStyle,
    MultiPoint: olKmlPointStyle,
    LineString: olKmlLineStyle,
    MultiLineString: olKmlLineStyle,
    Polygon: olKmlPolygonStyle,
    MultiPolygon: olKmlPolygonStyle
  },
  cesiumKml: {
    Point: cesiumKmlPointStyle,
    MultiPoint: cesiumKmlPointStyle,
    LineString: cesiumKmlLineStyle,
    MultiLineString: cesiumKmlLineStyle,
    Polygon: cesiumKmlPolygonStyle,
    MultiPolygon: cesiumKmlPolygonStyle,
    Circle: cesiumKmlPolygonStyle
  },
  cesiumSearch: {
    Point: cesiumSearchPointStyle,
    MultiPoint: cesiumSearchPointStyle,
    LineString: cesiumSearchLineStyle,
    MultiLineString: cesiumSearchLineStyle,
    Polygon: cesiumSearchPolygonStyle,
    MultiPolygon: cesiumSearchPolygonStyle,
    Circle: cesiumSearchPolygonStyle
  },
  profileGraphCircle: new Style({
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({ color: "#fbc02d" }),
      stroke: new Stroke({ color: "#000000", width: 1.5 })
    })
  }),
  streetSmart: new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({ color: "#fbc02d" }),
      stroke: new Stroke({ color: "#212121", width: 1 })
    })
  }),
  cesiumStreetSmart: {
    fill: "#fbc02d",
    radius: 5,
    stroke: { color: "#212121", width: 1 }
  },
  olSearch: {
    Point: new Style({
      image: olImage
    }),
    LineString: new Style({
      stroke: new Stroke({
        color: "yellow",
        width: 4
      })
    }),
    MultiLineString: new Style({
      stroke: new Stroke({
        color: "yellow",
        width: 4
      })
    }),
    MultiPoint: new Style({
      image: olImage
    }),
    MultiPolygon: new Style({
      stroke: new Stroke({
        color: "yellow",
        width: 4
      }),
      fill: new Fill({
        color: "rgba(255, 0, 0, 0.3)"
      })
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: "blue",
        lineDash: [4],
        width: 4
      }),
      fill: new Fill({
        color: "rgba(0, 0, 255, 0.1)"
      })
    }),
    GeometryCollection: new Style({
      stroke: new Stroke({
        color: "magenta",
        width: 2
      }),
      fill: new Fill({
        color: "magenta"
      }),
      image: new CircleStyle({
        radius: 10,
        fill: null,
        stroke: new Stroke({
          color: "magenta"
        })
      })
    }),
    Circle: new Style({
      stroke: new Stroke({
        color: "red",
        width: 2
      }),
      fill: new Fill({
        color: "rgba(255,0,0,0.2)"
      })
    })
  },
  getDrawEditStyle: function () {
    const editStyle = [
      new Style({
        fill: new Fill({
          color: [255, 255, 255, 0.5]
        }),
        image: new CircleStyle({
          radius: width * 2,
          stroke: new Stroke({
            color: white,
            width: width / 2
          }),
          fill: new Fill({
            color: [0, 153, 255, 1]
          })
        })
      }),
      new Style({
        stroke: new Stroke({
          color: white,
          width: width + 2
        })
      }),
      new Style({
        stroke: new Stroke({
          color: "#fbc02d",
          width: width
        })
      }),
      new Style({
        image: new CircleStyle({
          radius: width * 2,
          stroke: new Stroke({
            color: "black",
            width: 2
          }),
          fill: new Fill({
            color: "rgba(0,0,0,0.3)"
          })
        }),
        geometry: function (feature) {
          let coordinates;
          if (feature.getGeometry() instanceof Point) {
            return;
          }
          if (feature.getGeometry() instanceof Polygon) {
            coordinates = feature.getGeometry().getCoordinates()[0];
            /*
             * trick ;-) remove the first Point because in a Polygon
             * first and last point are equal and therefore the vertex gets to dark.
             */
            coordinates.shift();
          } else {
            coordinates = feature.getGeometry().getCoordinates();
          }
          if (coordinates) {
            return new MultiPoint(coordinates);
          }
        }
      })
    ];
    return editStyle;
  },
  getPrintRectangleStyle: function ({
    text,
    borderColor = "#CD2626",
    fillColor = "rgba(178, 34, 34, 0.2)"
  } = {}) {
    return {
      Polygon: new Style({
        stroke: new Stroke({
          color: borderColor,
          width: 3
        }),
        fill: new Fill({
          color: fillColor
        }),
        text: new Text({
          backgroundFill: new Fill({ color: "#ffd044" }),
          fill: new Fill({
            color: "#212121"
          }),
          font: "bold 11px arial",
          padding: [4, 4, 4, 4],
          text: text
        })
      })
    };
  },
  /*
   * get the correct boden icon according to the saugspannung
   * @param {object} saugspannung - {oberboden:xy, unterboden:xy}
   * @returns {string} - the name of the correct svg icon.
   */
  getBodenfeuchteIcon: function (saugspannung) {
    if (!saugspannung || Object.keys(saugspannung).length === 0) {
      return "boden_default_48x48.svg";
    }
    const start = "boden_";
    const end = "_kanton_48x48.svg";
    const oberbodenValue = this.getBodenLegendValue(
      saugspannung["Saugspannung Oberboden [20 cm]"]
    );
    const unterbodenValue = this.getBodenLegendValue(
      saugspannung["Saugspannung Unterboden [35 cm]"]
    );
    return `${start}${oberbodenValue}${unterbodenValue}${end}`;
  },

  getBodenLegendValue: value => {
    if (value > 20) {
      return "04";
    }
    if (value >= 10) {
      return "03";
    }
    if (value >= 6) {
      return "02";
    }
    if (value < 6) {
      return "01";
    }
  },

  /*
   * gets the style/icon for a certain type of measure data.
   * @param {object} params - function parameter object.
   * @param {object} station - station object delivered from kiwis service.
   * @param {number} saugspannung - current saugspannung for bodenfeuchte stations.
   * @param {string} viewer - type of viewer to create the style for (ol or cesium).
   * @returns {object} - olStyle or Cesium Billoard object.
   */
  getMessdatenStyle: async function ({
    station,
    viewer = "ol",
    saugspannung
  } = {}) {
    const scale = 0.7;
    const width = 48;
    const height = 48;
    const opacity = 1;
    const stationType = getStationType(station);
    const isActive =
      station.properties.ONLINE_PUBLICATION === "yes" ? true : false;
    let iconName = "";
    switch (stationType) {
      case "Fliessgewässer - Hydrometrie":
        iconName = isActive
          ? `abflussmessstation_dfu_48x48.svg`
          : `abflussmessstation_48x48.svg`;
        break;
      case "See - Hydrometrie":
        iconName = isActive
          ? `seepegelmessstation_dfu_48x48.svg`
          : `seepegelmessstation_48x48.svg`;
        break;
      case "Grundwasser - Piezometer":
        iconName = isActive
          ? `piezometer_dfu_kanton_32x32.svg`
          : `piezometer_kanton_32x32.svg`;
        break;
      case "Grundwasser - Piezometer Qualität":
        iconName = isActive
          ? `piezometer_q_dfu_kanton_32x32.svg`
          : `piezometer_q_kanton_32x32.svg`;
        break;
      case "Grundwasser - Förderbrunnen Qualität":
        iconName = isActive
          ? `grundwasserfassung_q_dfu_kanton_48x48.svg`
          : `grundwasserfassung_q_kanton_48x48.svg`;
        break;
      case "Grundwasser - Förderbrunnen":
        iconName = isActive
          ? `grundwasserfassung_dfu_kanton_48x48.svg`
          : `grundwasserfassung_kanton_48x48.svg`;
        break;
      case "Grundwasser - Quelle":
        iconName = isActive
          ? `quellfassung_dfu_kanton_48x48.svg`
          : `quellfassung_kanton_48x48.svg`;
        break;
      case "Grundwasser - Quelle Qualität":
        iconName = isActive
          ? `quellfassung_q_dfu_kanton_48x48.svg`
          : `quellfassung_q_kanton_48x48.svg`;
        break;
      case "Niederschlag - Hydrometrie":
        iconName = isActive
          ? `niederschlag_dfu_kanton_48x48.svg`
          : `niederschlag_kanton_48x48.svg`;
        break;
      case "Bodenfeuchte":
        iconName = this.getBodenfeuchteIcon(saugspannung);
        break;
      default:
        iconName = "unbekannt_32x32.svg";
    }
    let icon = null;
    // check if icon is allready in the cache
    if (messdatenImages[iconName]) {
      icon = messdatenImages[iconName];
    } else {
      /*
       * if not in the cache, dynamically import the image
       * to keep the bundle size as small as possible
       */
      icon = await import(
        /* webpackMode: "eager" */
        "../../public/messstation_icons/" + iconName
      );
      messdatenImages[iconName] = icon;
    }
    if (viewer === "ol") {
      return new Style({
        image: new Icon({
          opacity,
          src: icon.default,
          scale
        })
      });
    }
    if (viewer === "cesium") {
      // the returned object can be used as a cesium billboard
      return {
        image: icon.default,
        width,
        height,
        scale
      };
    }
  },

  /*
   * get a style for a ol/Feature based on geojson.properteies._cesiumStyle attribute transfered via url (drawings...).
   * @param {object} style - style to apply to the feature.
   * @returns {object} style - ol/style instance.
   */
  getOlStyleFromCesium: function (geojson) {
    if (!geojson) {
      return false;
    }
    const type = geojson.geometry.type;
    const style = geojson.properties._cesiumStyle;
    if (style instanceof Style) {
      // we allredy have a valid style
      return style;
    }
    switch (type) {
      case "Point":
        const pointStyle = new Style({
          image: new CircleStyle({
            radius: style.radius,
            fill: new Fill({ color: style.fill }),
            stroke: new Stroke({
              color: style.stroke.color,
              width: style.stroke.width
            })
          })
        });
        if (style.text) {
          pointStyle.setText(
            new Text({
              font: style.text.font,
              fill: new Fill({ color: style.text.fill }),
              stroke: new Stroke({ color: style.text.stroke, width: 5 }),
              text: style.text.text
            })
          );
        }
        return pointStyle;

      case "LineString":
        const lineStyle = new Style({
          stroke: new Stroke({
            color: style.stroke.color,
            width: style.stroke.width,
            lineDash: style.stroke.dashed
          })
        });
        return lineStyle;

      case "Polygon":
        const polygonStyle = new Style({
          fill: new Fill({ color: style.fill }),
          stroke: new Stroke({
            color: style.stroke.color,
            width: style.stroke.width
          })
        });
        return polygonStyle;
      default:
        console.warn("not able to style drawing.");
    }
  },
  // credits: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  rgbToHex: function (r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  },
  getOlMessdatenLabel: function (oldStyle, messdaten) {
    return function (feature, resolution) {
      if (resolution > 13) {
        oldStyle.setText(null);
        return oldStyle;
      } else {
        const text = new Text({
          font: "12px/1.2 sans-serif",
          textAlign: "left",
          fill: new Fill({ color: "#ff0000" }),
          stroke: new Stroke({ color: "#ffffff", width: 3 }),
          offsetX: 20
        });
        let label = "";
        for (const key in messdaten.labels) {
          label += key + ": " + messdaten.labels[key] + "\n";
        }
        text.setText(label);
        oldStyle.setText(text);
        return oldStyle;
      }
    };
  }
};

export default styles;
