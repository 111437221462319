import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Highlight, connectAutoComplete } from "react-instantsearch-dom";
import AutoSuggest from "react-autosuggest";
import LockIcon from "@material-ui/icons/Lock";
import "./autocomplete.css";

const AutoComplete = props => {
  const { hits, onSuggestionSelected } = props;
  const [value, setValue] = useState(props.currentRefinement);
  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.input.focus();
  }, []);

  /* sort the results according to the whishes from lisag.
   * otherwise every browser has a different index order.
   */
  const sortedHits = new Array(9);
  hits.forEach(hit => {
    switch (hit.index) {
      case "Geodaten":
        sortedHits[0] = hit;
        break;
      case "Gemeinden":
        sortedHits[1] = hit;
        break;
      case "Liegenschaften":
        sortedHits[2] = hit;
        break;
      case "Selbstrechte":
        sortedHits[3] = hit;
        break;
      case "Projektierte Liegenschaften":
        sortedHits[4] = hit;
        break;
      case "Adressen":
        sortedHits[5] = hit;
        break;
      case "Flurnamen":
        sortedHits[6] = hit;
        break;
      case "Gewaessernamen":
        sortedHits[7] = hit;
        break;
      case "Landw. Nutzungsflaechen":
        sortedHits[8] = hit;
        break;
      default:
        return;
    }
  });
  /*
   * Function declarations
   */
  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const coordinate = checkCoordinate(value);
    if (coordinate) {
      onSuggestionSelected({}, { suggestion: coordinate });
    }
    props.refine(value);
  };

  const onSuggestionsClearRequested = () => {
    props.refine();
  };

  // the value to populate the input after a suggestion get's clicked
  const getSuggestionValue = hit =>
    hit.name_offiziell ? hit.name_offiziell : hit.searchterm;

  // what get's rendered in the suggestions dropdown menu
  const renderSuggestion = hit => {
    return hit.name_offiziell ? (
      <span>
        <Highlight attribute="name_offiziell" hit={hit} tagName="mark" />{" "}
        {hit.secured && <LockIcon style={{ fontSize: "14px" }} />}
      </span>
    ) : (
      <Highlight attribute="searchterm" hit={hit} tagName="mark" />
    );
  };

  const renderSectionTitle = section => {
    if (section.hits.length > 0) {
      return section.index;
    } else {
      return null;
    }
  };

  const getSectionSuggestions = section => section.hits;
  const placeholderText =
    "Suche z.B. Nutzungsplanung, Neuland 11, 12 Andermatt, 2693282/1193471...";
  // config for the search input
  const inputProps = {
    type: "search", // adds the clear icon
    placeholder: placeholderText,
    "aria-label": placeholderText,
    onChange: onChange,
    value
  };
  return (
    <AutoSuggest
      suggestions={sortedHits}
      multiSection={true}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={(event, suggestion) => {
        onSuggestionSelected(event, suggestion);
        setValue("");
      }}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderSectionTitle={renderSectionTitle}
      getSectionSuggestions={getSectionSuggestions}
      ref={inputEl}
    />
  );
};

export default connectAutoComplete(AutoComplete);

AutoComplete.propTypes = {
  createURL: PropTypes.func.isRequired,
  currentRefinement: PropTypes.string.isRequired,
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  refine: PropTypes.func.isRequired
};

/*
 * check for coordinate entries in searchbar
 * @param {string} value - the value entered by the user
 * @returns {object} - search object with "groupname", x-coordinate, y-coordinate and "coordSys" as keys, or null if no match.
 */
const checkCoordinate = value => {
  const stripedValue = value.toString().replace(/['’EeNnXxYylatlngL]/g, ""); // replace hochkomma
  const patterns = {
    "EPSG:2056": /(^[2]\d{6,}\.?\d*)(\D*)([1]\d{6,}\.?\d*)/,
    "EPSG:21781": /(^[5-8]\d{5,}\.?\d*)(\D*)([1-2]\d{5,}\.?\d*)/,
    "EPSG:4326": /(\d{2}\.\d*)(\D*)(\d{1}\.\d*)/
  };
  let coordSys = null;
  if (patterns["EPSG:2056"].test(stripedValue)) {
    coordSys = "EPSG:2056";
  } else if (patterns["EPSG:21781"].test(stripedValue)) {
    coordSys = "EPSG:21781";
  } else if (patterns["EPSG:4326"].test(stripedValue)) {
    coordSys = "EPSG:4326";
  }
  if (coordSys) {
    const parts = stripedValue.match(patterns[coordSys]);
    return {
      groupname: "coordinate",
      x: parts[1],
      y: parts[3],
      coordSys,
      searchterm: `${parts[1]}/${parts[3]}`
    };
  } else {
    return coordSys;
  }
};
