import React from "react";
import PropTypes from "prop-types";
import MenuChip from "./MenuChip";
import "./IconMenu.css";

const IconMenu = ({ items, setItems, mapType, shoppingCart }) => {
  items =
    mapType === "2D"
      ? items
      : items.filter(
          item =>
            item.label === "Geladene Layer" || item.label === "Street View"
        );
  return (
    <div
      className="iconmenu"
      style={{
        width: mapType === "2D" ? "100%" : "initial",
        minWidth: mapType === "2D" ? "initial" : "300px"
      }}
    >
      {items.map(item => {
        if (item.label === "Street Smart") {
          return false;
        }
        if (item.label === "Warenkorb") {
          return (
            <MenuChip
              key={item.label}
              item={item}
              setItems={setItems}
              shoppingCart={shoppingCart}
            />
          );
        } else {
          return <MenuChip key={item.label} item={item} setItems={setItems} />;
        }
      })}
    </div>
  );
};

export default IconMenu;
IconMenu.propTypes = {
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  mapType: PropTypes.string.isRequired,
  shoppingCart: PropTypes.array.isRequired
};
