import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/app/App";
import appState from "./utils/appState";
import * as serviceWorker from "./serviceWorker";

/*
 * normalize vh to work as expexted in mobile browsers
 * credits: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
/*
 * update the map size. necessary when rotating a mobile device.
 * @param {object} params - function parameter object.
 * @param {domElement} params.olMapDiv - the dom element holding the openlayers map.
 * @param {domElement} params.cesiumMapDiv - the dom element holding the cesium map.
 * @param {object} params.olMapObject - openlayers map object.
 * @param {object} params.cesiumViewerObject - cesium viewer object.
 *
 */
const updateMapHeight = ({
  olMapDiv,
  cesiumMapDiv,
  olMapObject,
  cesiumViewerObject
} = {}) => {
  if (!olMapDiv && !cesiumMapDiv) return;
  const vh = window.innerHeight * 0.01;
  const mapHeight = parseInt(vh * 100 - 66);
  olMapDiv.style.height = `${mapHeight}px`;
  cesiumMapDiv.style.height = `${mapHeight}px`;
  if (olMapObject) olMapObject.updateSize();
  if (cesiumViewerObject) cesiumViewerObject.resize();
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  /* the map height must be updated in order to have no empty
   * space on device rotation on mobile devices.
   */
  let updateMapsize;
  /*
   * the resize event got fired in an interval < 500ms
   * therefore we clear the timeout function to update
   * the map size.
   */
  clearTimeout(updateMapsize);

  updateMapsize = setTimeout(() => {
    /*
     * the code in here only get's called, when
     * the resize event did not get fired for 500ms.
     */
    updateMapHeight({
      olMapDiv: document.getElementById("ol-map"),
      cesiumMapDiv: document.getElementById("cesium-map"),
      olMapObject: appState.olMap,
      cesiumViewerObject: appState.cesiumViewer
    });
  }, 500);
});

// listen for iframe resize events => messdaten etc.
window.addEventListener("message", event => {
  const origin = event.origin;
  if (
    origin === "http://app.lisag.ch:8080" ||
    origin === "https://wiski-html-h2eptfuxza-oa.a.run.app"
  ) {
    const messdatenIframes = document.querySelectorAll(".messdaten-iframe");
    if (messdatenIframes) {
      messdatenIframes.forEach(
        frame => (frame.height = event.data.height + "px")
      );
    }
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
