import appState from "./appState";
import {
  getPrintGeoJson,
  getSelectionOrigin,
  getMin,
  getMax,
  getSelectionPolygon
} from "./geoshopUtil";
import stylesUtil from "./stylesUtil";
import { getType, removeVectorLayers } from "./layerUtil";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { Icon } from "ol/style.js";
import { Translate } from "ol/interaction";
import { getCenter, getTopRight, getBottomLeft } from "ol/extent";
import { convertExtent, convertPointCoordinates } from "./projectionUtil";
import GeoJSON from "ol/format/GeoJSON.js";
import {
  HOST,
  PRINT_HOST,
  PRINT_DOCUMENT_NAME,
  GEOSERVER_SEC,
  SWISSTOPO_WMS
} from "./ENV";
import { optionsFromCapabilities } from "ol/source/WMTS";
import { getCapabilitiesInfo } from "../utils/capabilitiesUtil";
const dpi = 150;
/*
 * draw a print rectangle based on format and scale.
 * @param {object} params
 * @param {string} params.scale - the scale of the pdf  (e.g. 1:500)
 * @param {string} params.format - the paper format (e.g. A4 hoch)
 * @param {object} params.callback - function to call when rectangle has changed
 * @returns void
 */
export const drawPrintRectangle = ({ scale, format, callback } = {}) => {
  const map = appState.olMap;
  removeVectorLayers({ map, removeType: "print" });
  const view = map.getView();
  const center = view.getCenter();
  const geojson = getPrintGeoJson({ center, scale, format, type: "print" });
  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojson)
  });
  const printRectangle = new VectorLayer({
    zIndex: appState.olMap.getLayers().getLength() + 5, //one can add 5 layers before the print rectangle gets obscured
    source: vectorSource,
    style: stylesUtil.getPrintRectangleStyle({
      text: `Druckausschnitt: ${format} ${scale}`,
      borderColor: "#CD2626",
      fillColor: "rgba(178, 34, 34, 0.2)"
    })["Polygon"] // we need the polygon key of the returned object
  });
  // add a custom property to be able to filter geohsop pdf extents
  printRectangle.usageType = "print";
  view.fit(vectorSource.getExtent(), {
    duration: 300,
    maxZoom: 17,
    padding: [48, 0, 12, 0] // iconmenu must not obscure print rectangle.
  });

  const translate = new Translate({ layers: [printRectangle] });
  const lv95Extent = convertExtent({
    sourceProj: "EPSG:3857",
    destProj: "EPSG:2056",
    extent: vectorSource.getExtent()
  });
  translate.on("translateend", e => {
    callback(getRectangleMetrics(vectorSource, lv95Extent));
  });
  map.addInteraction(translate);
  map.addLayer(printRectangle);
  callback(getRectangleMetrics(vectorSource, lv95Extent));
};

const getRectangleMetrics = (vectorSource, lv95Extent) => {
  return {
    selection_origin: getSelectionOrigin(vectorSource),
    selection_polygon: getSelectionPolygon(lv95Extent),
    min: getMin(lv95Extent),
    max: getMax(lv95Extent),
    center: getCenter(vectorSource.getExtent()),
    source: vectorSource
  };
};

/*
 * starts the print process on the geoserver
 * @param {object} params
 * @param {array} params.center - center of the print rectangle
 * @param {string} params.layout - paper format e.g. "A4 hoch"
 * @param {number} params.scale - the map scale in meters e.g. 5000
 * @param {string} params.comment - user comment to display on the pdf
 * @param {number} params.rotation - rotation of the map
 * @param {boolean} params.legend - whether or not legend symbols should be printed
 * @param {function} params.setPrintProgress - hide/show print progress and info messages
 * @param {object} params.vectorSource - ol/source/Vector instance.
 * @returns void
 */
export const print = async ({
  center = [970000, 5900000],
  layout = "A4 hoch",
  scale = 5000,
  comment = " ",
  rotation = 0,
  legend = false,
  setPrintProgress = null,
  vectorSource = null
} = {}) => {
  const sortedLayers = sortLayersByZIndex(getVisibleLayers());
  var spec = {
    layout: layout || "A4 hoch",
    mapTitle: "Auszug aus geo.ur",
    comment: comment,
    srs: "EPSG:3857",
    units: "meters",
    geodetic: true,
    outputFilename: PRINT_DOCUMENT_NAME,
    outputFormat: "pdf",
    mergeableParams: {
      cql_filter: {
        defaultValue: "INCLUDE",
        separator: ";"
      }
    },
    layers: await getPrintLayers({
      printExtent: vectorSource.getExtent(),
      layers: sortedLayers,
      scale
    }),
    pages: [
      {
        center,
        scale,
        dpi,
        geodetic: true,
        //strictEpsg4326: true,
        rotation,
        northArrow: `northArrow_${rotation}.jpg`
      }
    ]
  };
  // only print legends if there are more layers than the basemap and the print rectangle
  if (legend && sortedLayers.length > 2) {
    spec.legends = getLegends({
      format: layout,
      extent: vectorSource.getExtent(),
      layers: sortedLayers
    });
  }
  // alert if scale is < 250 and zeitreise layer is active. wmts can't be printed in scale < 1:250
  const zeitreise = spec.layers.filter(
    layer => layer.layer && layer.layer === "ch.swisstopo.swissimage-product"
  );
  if (zeitreise.length > 0 && scale <= 250) {
    setPrintProgress({
      running: false,
      statusText: ``
    });
    alert(
      `Das 'Orthofoto Zeitreise' kann nicht im Massstab 1:${scale} gedruckt werden. Bitte erhöhen Sie den Masstab auf mindestens 1:500`
    );
    return;
  }
  sendPrintRequest(spec, setPrintProgress);
};

const sendPrintRequest = (spec, setPrintProgress) => {
  const url = `${PRINT_HOST}/default/report.pdf?url=${HOST}${GEOSERVER_SEC}pdf/create.json`; //Adresse karten-werk dynamic legend proxy
  fetch(url, {
    method: "POST",
    body: JSON.stringify(spec),
    headers: {
      "Content-Type": "application/json"
    }
  })
    // response has the statusURL and the downloadURL properties
    .then(response => response.json())
    .then(json => {
      updateStatus({
        statusUrl: `${PRINT_HOST}${json.statusURL}`,
        setPrintProgress,
        callback: () => {
          downloadPrintPdf(
            `${PRINT_HOST}${json.downloadURL}`,
            PRINT_DOCUMENT_NAME
          );
          setPrintProgress({
            running: false,
            statusText: "Druck erfolgreich beendet."
          });
        }
      });
    })
    .catch(error => setPrintProgress(error, setPrintProgress));
};

/*
 * function to display and logging printing errors.
 * @param {object} error - the error returned by the request.
 * @param {function} setPrintProgress - setState function to display a status text.
 */
const showPrintError = (error, setPrintProgress) => {
  console.error(error);
  setPrintProgress({
    error: true,
    statusText:
      "Fehler beim Drucken. Bitte wenden Sie sich an die Lisag AG. Tel. +41 41 500 60 60"
  });
};

const updateStatus = async ({ statusUrl, callback, setPrintProgress } = {}) => {
  try {
    const response = await fetch(statusUrl);
    const responseObj = await response.json();
    const { done, status, error } = responseObj;
    if (done) {
      if (status === "error") {
        showPrintError(error, setPrintProgress);
        return;
      }
      callback();
    } else {
      window.setTimeout(updateStatus, 1000, {
        statusUrl,
        callback,
        setPrintProgress
      });
    }
  } catch (error) {
    showPrintError(error, setPrintProgress);
    return;
  }
};

/*
 * collect all the necessary information for printing legends
 * @param {object} params - function parameter object.
 * @param {string} params.format - the paer format to print e.g. "A4 hoch".
 * @param {array} params.extent - print extent.
 * @param {array} params.layers - layers to print.
 * @returns {array} legends - array of legend config objects.
 */
const getLegends = ({ format, extent, layers }) => {
  const legends = [];
  layers.forEach(layer => {
    const layerType = getType(layer.getSource());
    switch (layerType) {
      case "XYZ":
      case "Vector":
        break;
      case "TileWMS":
        const source = layer.getSource();
        const url = source.getUrls()[0];
        //for our internal layers we have to add the host.
        let baseUrl = url.indexOf("https://") === -1 ? `${HOST}${url}` : url;
        const layername = source.getParams().LAYERS;
        // don't print the legend of the basemap
        if (layername.indexOf("basemaps:") !== -1) {
          break;
        }
        const sw = convertPointCoordinates({
          sourceProj: "EPSG:3857",
          destProj: "EPSG:4326",
          coordinates: getBottomLeft(extent)
        });
        const ne = convertPointCoordinates({
          sourceProj: "EPSG:3857",
          destProj: "EPSG:4326",
          coordinates: getTopRight(extent)
        });
        var bbox = `${sw[1].toFixed(6)},${sw[0].toFixed(6)},${ne[1].toFixed(
          6
        )},${ne[0].toFixed(6)}`;
        let { LAYERS, FORMAT } = source.getParams();
        FORMAT = FORMAT ? FORMAT : "image/png";
        //check for ? at the end of the baseUrl and add it if ot does not exist.
        if (baseUrl[baseUrl.length - 1] !== "?") {
          baseUrl += "?";
        }
        let legendGraphicUrl = layer.legendurl
          ? `${layer.legendurl}&WIDTH=20&HEIGHT=20`
          : `${baseUrl}SERVICE=WMS&VERSION=1.3.0&TRANSPARENT=TRUE&REQUEST=GetLegendGraphic&EXCEPTIONS=application/vnd.ogc.se_xml&LAYER=${LAYERS}&FORMAT=${FORMAT}&WIDTH=20&HEIGHT=20&SLD_VERSION=1.1.0`;
        if (layer.serverType === "geoserver") {
          let geoserverOptions =
            "&SRCWIDTH=" +
            Math.round((mapAreas[format].widthPaperM / 0.0254) * dpi) +
            "&" +
            "SRCHEIGHT=" +
            Math.round((mapAreas[format].heightPaperM / 0.0254) * dpi) +
            "&" +
            "BBOX=" +
            bbox +
            "&" +
            "SRS=EPSG:4326&" +
            "LEGEND_OPTIONS=dpi:100;forceLabels:on;fontAntiAliasing:true;fontSize:10;labelMargin:20"; //dpi > 100 is killing geoserver...
          legendGraphicUrl += geoserverOptions;
        }
        legends.push({
          name: layer.name,
          classes: [
            {
              name: "",
              icon: legendGraphicUrl,
              proxyclass: true,
              opacity: layer.getOpacity()
            }
          ]
        });
        break;
      default:
        break;
    }
  });
  return legends;
};
/*
 * creates a list of layers to print for the geoserver print extension.
 * @param {object} params - function parameter object.
 * @param {array} params.printExtent - the printing recangle
 * @returns {array} printLayers - print layer configurations
 */
const getPrintLayers = async ({ printExtent, layers, scale }) => {
  const printLayers = [];
  // for of loop works with async, forEach does not.
  for (const layer of layers) {
    const layerType = getType(layer.getSource());
    switch (layerType) {
      case "XYZ":
        /*
         * we use xyz layers for some of the basemaps (orhtofoto, pkfarbe, pkgrau and zeitreise).
         * problem is, we can't use xyz layers for printing (does not work, don't know why).
         * therefore we have to print them as wmts and we have to retrieve some specific infos necessary for printing
         */
        const xyzUrl = layer.getSource().getUrls()[0];
        const layername = xyzUrl.split("/")[4];
        /* print the swissimage from the swisstopo wms, because we can use 1:250 scale then.
         * small scales like 1:50000 do not work on on A3 or A2 with the wms
         * Therefore we have to use the wmts in those cases...
         */
        if (layername === "ch.swisstopo.swissimage" && scale < 1000) {
          printLayers.push({
            type: "wms",
            layers: ["ch.swisstopo.images-swissimage"],
            opacity: 1,
            baseURL: `${SWISSTOPO_WMS}`,
            format: "image/jpeg"
          });
          break;
        }
        const time = xyzUrl.split("/")[6] || null;
        const wmtsParams = await getWMTSParams({
          url: "https://wmts.geo.admin.ch/EPSG/3857/1.0.0/WMTSCapabilities.xml",
          layername,
          time
        });
        let maxResolution = wmtsParams.resolutions[0];
        const layerConfig = {
          type: "wmts",
          opacity: layer.getOpacity(),
          baseURL: encodeURI(wmtsParams.url),
          version: "1.0.0",
          layer: layername,
          style: wmtsParams.style,
          formatSuffix: "jpeg",
          matrixSet: "3857",
          zoomOffset: 0,
          matrixIds: generateMatrixIds({
            matrixIds: wmtsParams.matrixIds,
            maxResolution
          }),
          maxExtent: wmtsParams.extent,
          tileSize: layer.getSource().tmpSize,
          resolutions: wmtsParams.resolutions,
          extension: "jpeg",
          tileOrigin: layer.getSource().getTileGrid().getOrigin()
        };
        if (wmtsParams.dimensions) {
          layerConfig.dimensions = wmtsParams.dimensions;
          layerConfig.params = wmtsParams.params;
        }
        printLayers.push(layerConfig);
        break;
      case "TileWMS":
        const { LAYERS, FORMAT, TIME } = layer.getSource().getParams();
        const wmsUrl = layer.getSource().getUrls()[0];
        //for our internal layers we have to add the host.
        const baseURL =
          wmsUrl.indexOf("https://") === -1 ? `${HOST}${wmsUrl}` : wmsUrl;
        const printConfig = {
          type: "wms",
          layers: [LAYERS],
          opacity: layer.getOpacity(),
          baseURL,
          format: FORMAT || "image/png",
          customParams: {}
        };
        if (TIME) {
          printConfig.customParams["TIME"] = TIME;
        }
        printLayers.push(printConfig);
        break;
      case "Vector":
        const opacity = layer.getOpacity();
        if (
          layer.usageType &&
          (layer.usageType.toLowerCase() === "kml" ||
            layer.usageType.toLowerCase() === "draw" ||
            layer.usageType.toLowerCase() === "measure" ||
            layer.usageType.toLowerCase() === "featureinfo" ||
            layer.usageType.toLowerCase() === "search" ||
            layer.usageType.toLowerCase() === "messdaten" ||
            layer.usageType.toLowerCase() === "foto")
        ) {
          const printFeatures = layer
            .getSource()
            .getFeaturesInExtent(printExtent);
          if (printFeatures.length > 0) {
            const printVectors = featuresToPrintVectors({
              features: printFeatures,
              opacity,
              name: layer.name || "drawing layer"
            });
            printVectors.forEach(vector => printLayers.push(vector));
          }
        }
        break;
      default:
        console.warn(
          "Attention we did not recognice this layerType for printing:",
          layerType
        );
        break;
    }
  }
  return printLayers;
};

const downloadPrintPdf = (downloadUrl, documentName) => {
  window.open(`${downloadUrl}`);
  //window.open(`${downloadUrl}?Content-Disposition=${documentName}`);
};

const generateMatrixIds = ({ matrixIds = [], maxResolution } = {}) => {
  const topLeftCorner = [-20037508.3428, 20037508.3428];
  const matrizes = [];
  matrixIds.forEach(id => {
    maxResolution = id === "0" ? maxResolution : maxResolution / 2;
    matrizes.push({
      identifier: id,
      resolution: maxResolution,
      tileSize: [256, 256],
      matrixSize: [Math.pow(2, parseInt(id)), Math.pow(2, parseInt(id))],
      topLeftCorner
    });
  });
  return matrizes;
};

/*
 * retrieves infos relevant for printing for certain WMTS layer and it's getCapabilities document
 * @param {object} params
 * @param {string} params.url - getCapabilieties url to an xml document
 * @param {string} params.layername - name of the layer to get the infos from
 * @returns {object} - information relevant for printing or false in case of failure
 */
const getWMTSParams = async ({ url, layername, time = "current" } = {}) => {
  if (!url || !layername) {
    return false;
  }
  try {
    const layerInfos = await getCapabilitiesInfo({ type: "wmts", url });
    const options = optionsFromCapabilities(layerInfos, {
      layer: layername,
      matrixSet: "EPSG:3857"
    });
    return {
      resolutions: options.tileGrid.getResolutions(),
      extent: options.tileGrid.getExtent(),
      matrixIds: options.tileGrid.getMatrixIds(),
      url: options.urls[0].replace("{Time}", time),
      style: options.style,
      dimensions: options.dimensions ? Object.keys(options.dimensions) : null,
      params: options.dimensions
        ? transformDimensionsToUpperCase(options.dimensions)
        : null
    };
  } catch (error) {
    alert("Sorry, es gab einen Fehler beim Drucken");
  }
};

/*
 * transforms dimension keys to uppercase
 * this is necessary for geoserver wmts print
 * @param {object} dimensions - dimensions object like received from getCapabilities
 * @returns {object} - dimensions object with uppercase keys, false in case of failure
 */
const transformDimensionsToUpperCase = dimensions => {
  if (!dimensions) {
    return false;
  }
  const keys = Object.keys(dimensions);
  const result = {};
  keys.forEach(key => {
    result[key.toUpperCase()] = dimensions[key];
  });
  return result;
};

// define the map area without margins in meters
const mapAreas = {
  "A4 hoch": { widthPaperM: 0.178, heightPaperM: 0.248 },
  "A4 quer": { widthPaperM: 0.264, heightPaperM: 0.162 },
  "A3 hoch": { widthPaperM: 0.264, heightPaperM: 0.37 },
  "A3 quer": { widthPaperM: 0.386, heightPaperM: 0.252 },
  "A2 hoch": { widthPaperM: 0.382, heightPaperM: 0.546 },
  "A2 quer": { widthPaperM: 0.558, heightPaperM: 0.38 }
};

/*
 * get mapfish print styles for a geojson object.
 * @param {object} params - function parameter object.
 * @param {string} params.type - geometry type (Point, MultiPoint, LineString, MultiLineString, Polygon, MultiPolygon)
 * @param {object} params.vectorStyle - ol/Style instance.
 * @param {number} params.opacity - the opacity of the layer.
 */
const getGeojsonPrintStyle = ({
  type,
  vectorStyle,
  label,
  opacity = 1,
  measure = false
} = {}) => {
  const styles = {
    fillColor: "#ff0000",
    fillOpacity: opacity,
    graphicName: "circle",
    graphicOpacity: 0.4,
    pointRadius: 4,
    strokeColor: "#000000",
    strokeOpacity: opacity,
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  };
  if (!type || !vectorStyle) {
    return styles;
  }
  switch (type) {
    case "Point":
    case "MultiPoint":
      // case we have an icon
      if (vectorStyle.getImage() instanceof Icon) {
        const splittedSrc = vectorStyle.getImage().getSrc().split("/");
        let iconName = splittedSrc[splittedSrc.length - 1].split(".")[0]; // get the unhashed icon name
        /*
         * mapfish print v2 can't print .svg images.
         * therefore i had to convert them to .png and
         * store them on a external server. it did not work if
         * the images are stored on the same server as the webapp.
         * needs investigation.
         */
        const externalGraphic =
          "https://hochebene.ch/messdaten_images/" + iconName + ".png";
        return {
          externalGraphic,
          pointRadius: 10,
          graphicOpacity: opacity,
          ...getLabelFormat({ label, vectorStyle, measure: true, type })
        };
      } else {
        // case we do not have an icon
        let fillColor = false;
        if (
          vectorStyle.getImage() &&
          vectorStyle.getImage().getFill() &&
          vectorStyle.getImage().getFill().getColor()
        ) {
          fillColor = vectorStyle.getImage().getFill().getColor();
        }
        if (fillColor) {
          const transparentFill = fillColor.indexOf("rgba") !== -1;
          styles.fillColor = transparentFill ? rgbaToHex(fillColor) : fillColor;
          styles.fillOpacity = transparentFill ? 0.5 : 1;
        } else {
          // make background transparent
          styles.fillOpacity = 0;
        }
        styles.strokeColor = vectorStyle.getImage().getStroke().getColor();
        styles.strokeWidth = vectorStyle.getImage().getStroke().getWidth();
      }
      break;
    case "LineString":
    case "MultiLineString":
      styles.strokeColor = vectorStyle.getStroke().getColor();
      styles.strokeWidth = vectorStyle.getStroke().getWidth();
      if (vectorStyle.getStroke().getLineDash()) {
        styles.strokeDashstyle = "dash";
      }
      break;
    case "Polygon":
    case "MultiPolygon":
    case "GeometryCollection":
      const polyFillColor = vectorStyle.getFill().getColor();
      const polyStrokeColor = vectorStyle.getStroke().getColor();
      const polyTransparent = polyFillColor.indexOf("rgba") !== -1;
      styles.fillColor = polyTransparent
        ? rgbaToHex(polyFillColor)
        : polyFillColor;
      styles.strokeColor = polyStrokeColor;
      styles.strokeWidth = vectorStyle.getStroke().getWidth();
      styles.fillOpacity = polyTransparent ? 0.5 : 1;
      break;
    default:
  }
  if (label) {
    return {
      ...styles,
      ...getLabelFormat({ label, vectorStyle, measure, type })
    };
  }
  return styles;
};

/*
 * formats the label for the printing engine.
 * @param {object} params - function parameter object.
 * @param {string} params.label - the string to label.
 * @param {object} params.vectorStyle - ol/style/Style member.
 * @param {boolean} params.measure - if the geometry is for measure purposes.
 * @param {string} params.type - geometry type of the feature which should be labeled.
 * @returns {object} result - label format parameters.
 */
const getLabelFormat = ({ label, vectorStyle, measure, type } = {}) => {
  const result = {};
  if (!label) {
    return result;
  }
  if (vectorStyle) {
    const text = vectorStyle.getText();
    if (text) {
      result.fontColor = text.getFill().getColor();
      result.fontFamily = text.getFont().split(" ")[1];
      result.fontSize = text.getFont().split(" ")[0].substr(0, 4);
      result.fontStyle = "normal";
      result.fontWeight = "bold";
      // unfortunately halos do not work.
      // see: https://mapfish.github.io/mapfish-print-doc/styles.html
      result.label = label;
      result.labelAlign = "cm";
      result.labelYOffset = "16.0";
      // hide the underlaying points if we are in drawing mode
      if (measure === false) {
        result.pointRadius = 0;
        result.labelYOffset = 0;
      }
      if (measure === true && type === "Point") {
        result.labelYOffset = "50.0";
        result.labelAlign = "lm";
        result.label = label.split("\n").join("\n\n");
      }
    }
  }
  return result;
};

/*
 * returns a new array of visible layers.
 */
const getVisibleLayers = () => {
  return appState.olMap
    .getLayers()
    .getArray()
    .filter(layer => layer.getVisible() === true);
};

/*
 * converts rgba color to hex
 * @param {string} rgba - rgba like "rgba(255,0,0,0.5)"
 * @returns {string} hex - #ff0000
 */
const rgbaToHex = rgba => {
  const rgb = rgba.substring(5, rgba.length - 1).split(",");
  const r = parseInt(rgb[0].trim());
  const g = parseInt(rgb[1].trim());
  const b = parseInt(rgb[2].trim());
  return stylesUtil.rgbToHex(r, g, b);
};
/*
 * sort an array of layer objects by the zIndex property.
 * @param {array} layers - ol layer objects.
 * @returns {array} - the same array but sorted by layer.zIndex.
 */
const sortLayersByZIndex = layers => {
  if (!layers || layers.length === 0) {
    return [];
  }
  layers.sort((first, second) => {
    if (first.getZIndex() < second.getZIndex()) {
      return -1;
    } else {
      return 1;
    }
  });
  return layers;
};

/*
 * convert openlayers features to geojson objects
 * which are printable by geoserver mapfish print.
 * @param {array} features - array of ol/feature/Feature objects.
 * @return {array} printVectors - printable geojson objects.
 */
const featuresToPrintVectors = ({ features, opacity, name } = {}) => {
  const printVectors = [];
  if (!features) {
    return printVectors;
  }
  for (var i = 0; i < features.length; i++) {
    const feature = features[i];
    const vectorStyle = getFeatureStyle(feature);
    const geoJson = JSON.parse(new GeoJSON().writeFeature(feature));
    // remove a potential z value from point geometries
    // because 0 z values won't display in cesium.
    if (
      geoJson.geometry.type === "Point" &&
      geoJson.geometry.coordinates.length > 2
    ) {
      geoJson.geometry.coordinates.pop();
    }
    geoJson.properties = { _style: 1 };
    // create an object that is printable by geoserver mapfish print.
    printVectors.push({
      type: "vector",
      opacity: opacity,
      geoJson,
      name,
      styles: {
        version: "1",
        styleProperty: "_style",
        1: getGeojsonPrintStyle({
          type: feature.getGeometry().getType(),
          vectorStyle,
          opacity,
          measure: feature.measure,
          label: vectorStyle.getText() ? vectorStyle.getText().getText() : null
        })
      }
    });
  }
  return printVectors;
};

/*
 * extracts the style of a ol/Feature
 * @param {object} feature - ol/Feature member
 * @returns {object} style - ol/style/Style member
 */
const getFeatureStyle = feature => {
  if (!feature) {
    return null;
  }
  let style = feature.getStyle();
  if (typeof style === "function") {
    const styleFunc = feature.getStyleFunction();
    style = styleFunc(feature, appState.olMap.getView().getResolution());
  }
  return style;
};
