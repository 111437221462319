import React from "react";
import PropTypes from "prop-types";
import { Button, Collapse, FormHelperText } from "@material-ui/core";
import Authenticate from "../dialog/Authenticate";
import {
  getSidebarVisibilityState,
  removeDrawingInteractions
} from "../../utils/sidebarUtil";
import appState from "../../utils/appState";
import {
  streetSmartLogin,
  loadStreetSmartWfsClient,
  updateStreetSmartLayer,
  createStreetSmartLayer
} from "../../utils/toolsUtil";
import ButtonExpander from "../tools/ButtonExpander";
import Measure from "../tools/Measure";
import Drawing from "../tools/Drawing";
import "./Sidebar.css";

const ToolsSidebar = ({ menuItems, setMenuItems, setLayer, layers }) => {
  const menuItem = menuItems.filter(item => item.label === "Tools");

  return (
    <div
      className={menuItem[0].visible ? "sidebar" : "sidebar sidebar--collapsed"}
      style={{ zIndex: menuItem[0].zIndex }}
    >
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => {
          removeDrawingInteractions();
          setMenuItems(oldState =>
            getSidebarVisibilityState(oldState, menuItem[0].label)
          );
        }}
      >
        schliessen
      </Button>
      <section className="sidebar__content">
        <Collapse in={menuItem[0].visible} mountOnEnter={true}>
          <ButtonExpander title="Messen">
            <Measure />
          </ButtonExpander>
          <ButtonExpander title="Zeichnen" margin="16px">
            <Drawing setLayer={setLayer} layers={layers} />
          </ButtonExpander>
          <ButtonExpander
            title="Street Smart"
            margin="16px"
            onclick={loadStreetSmartWfsClient}
          >
            <Authenticate
              authenticate={async (username, pw) => {
                const xmlString = await streetSmartLogin(username, pw);
                appState.streetSmartActive = true;
                appState.streetSmartUser = username;
                appState.streetSmartPw = pw;
                if (appState.wfsClient) {
                  createStreetSmartLayer();
                  appState.streetSmartLayer.mapLayer.setVisible(true);
                  // add features if the zoom is >= 17
                  if (appState.olMap.getView().getZoom() >= 17) {
                    updateStreetSmartLayer(xmlString);
                  }
                }
                setMenuItems(oldState =>
                  getSidebarVisibilityState(oldState, "Street Smart")
                );
              }}
            >
              <FormHelperText
                style={{
                  fontSize: "16px",
                  lineHeight: 1.5,
                  paddingTop: "12px"
                }}
              >
                Melden Sie sich bitte an um <strong>Street Smart™</strong>{" "}
                nutzen zu können.
              </FormHelperText>
            </Authenticate>
          </ButtonExpander>
        </Collapse>
      </section>
    </div>
  );
};

export default ToolsSidebar;

ToolsSidebar.propTypes = {
  menuItems: PropTypes.array.isRequired,
  setMenuItems: PropTypes.func.isRequired,
  setLayer: PropTypes.func.isRequired,
  layers: PropTypes.array.isRequired
};
