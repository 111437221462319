import { getMessdaten } from "./requestUtil";
import { projectGeojson, createVectorLayer } from "./layerUtil";
import {
  KIWIS_HOST,
  TIME_SERIES_LIST,
  LATEST_MEASURMENT,
  TS_GROUP_ID,
  LATEST_MEASUREMENT_PERIOD
} from "./ENV";
import styles from "./stylesUtil";
import appState from "./appState";

export const addMessdatatenProps = async layer => {
  try {
    if (checkMessdaten() === false) {
      const messdaten = await getMessdaten();
      //convert to epsg:3857
      const geojson3857 = JSON.parse(
        projectGeojson({
          geojson: messdaten,
          featureProjection: "EPSG:4326",
          dataProjection: "EPSG:3857"
        })
      );
      appState.messdaten = { data: geojson3857, date: new Date() };
    }
    // filter out the necessary stations based on the layername
    // and add the appropriate style
    const filteredStations = { type: "FeatureCollection", features: [] };
    for (const station of appState.messdaten.data.features) {
      const { object_type, station_status, station_id } = station.properties;
      if (station_status && station_status !== "Geschlossen") {
        station.properties.usageType = "messdaten"; // needed to get the right infoBox content.
        station.properties.name = layer.name;
        switch (layer.name) {
          case "Oberflächengewässer Pegel und Abfluss":
            // filter
            if (
              object_type.indexOf("See - Hydrometrie") !== -1 ||
              object_type.indexOf("Fliessgewässer - Hydrometrie") !== -1
            ) {
              // add styles
              station.properties._olStyle = await styles.getMessdatenStyle({
                station,
                viewer: "ol"
              });
              station.properties._cesiumStyle = await styles.getMessdatenStyle({
                station,
                viewer: "cesium"
              });
              filteredStations.features.push(station);
            }
            break;
          case "Grundwasser und Quellen":
            if (object_type.indexOf("Grundwasser - ") !== -1) {
              station.properties._olStyle = await styles.getMessdatenStyle({
                station,
                viewer: "ol"
              });
              station.properties._cesiumStyle = await styles.getMessdatenStyle({
                station,
                viewer: "cesium"
              });
              filteredStations.features.push(station);
            }
            break;
          case "Niederschlag":
            if (object_type.indexOf("Niederschlag - Hydrometrie") !== -1) {
              station.properties._olStyle = await styles.getMessdatenStyle({
                station,
                viewer: "ol"
              });
              station.properties._cesiumStyle = await styles.getMessdatenStyle({
                station,
                viewer: "cesium"
              });
              filteredStations.features.push(station);
            }
            break;
          case "Bodenfeuchte":
            if (object_type.indexOf("Boden") !== -1) {
              const timeSeriesGroupId = TS_GROUP_ID[layer.name];
              // the style must be based on the latest measurements.
              // therefore we have to get potential time series.
              const time_series = await getStationTimeSeries(
                station_id,
                timeSeriesGroupId
              );
              // we only need Saugspannung Unterboden [35 cm] and Saugspannung Oberboden [20 cm]
              const boden_series = time_series.filter(
                serie =>
                  serie.stationparameter_name ===
                    "Saugspannung Oberboden [20 cm]" ||
                  serie.stationparameter_name ===
                    "Saugspannung Unterboden [35 cm]"
              );
              // get the values for each series
              const saugspannung = {};
              for (const serie of boden_series) {
                const latest_measurement = await getTimeSeriesMeasurement(
                  serie.ts_id
                );
                if (latest_measurement && latest_measurement.length > 0) {
                  // add the values to the saugspannung object.
                  saugspannung[serie.stationparameter_name] =
                    latest_measurement[0].data[0][1];
                }
              }
              station.properties._olStyle = await styles.getMessdatenStyle({
                station,
                viewer: "ol",
                saugspannung
              });
              station.properties._cesiumStyle = await styles.getMessdatenStyle({
                station,
                viewer: "cesium",
                saugspannung
              });
              filteredStations.features.push(station);
            }
            break;
          default:
            break;
        }
      }
    }
    const vectorLayer = createVectorLayer({
      geojson: filteredStations,
      name: layer.name,
      usageType: "messdaten"
    });

    layer.mapLayer = vectorLayer.mapLayer;
    layer.cesiumGeojson = vectorLayer.cesiumGeojson;
  } catch (error) {
    throw new Error(error);
  }
};

/*
 * get the type of a measure station.
 * @param {object} station - geojson feature.
 * @returns {string} - the object type of this station.
 */
export const getStationType = station => {
  if (!station) {
    return "unknown";
  }
  const { object_type, STA_LOCATION_TYPE } = station.properties;
  if (object_type.indexOf("Fliessgewässer - Hydrometrie") !== -1) {
    return "Fliessgewässer - Hydrometrie";
  }
  if (object_type.indexOf("See - Hydrometrie") !== -1) {
    return "See - Hydrometrie";
  }

  // "Grundwasser und Quellen"
  if (object_type.indexOf("Grundwasser - Hydrometrie") !== -1) {
    const quality = object_type.indexOf("Qualität") === -1 ? false : true;
    if (typeof STA_LOCATION_TYPE === "string") {
      if (STA_LOCATION_TYPE.toLowerCase().indexOf("quelle") !== -1) {
        return quality
          ? "Grundwasser - Quelle Qualität"
          : "Grundwasser - Quelle";
      }
      if (STA_LOCATION_TYPE.toLowerCase().indexOf("förderbrunnen") !== -1) {
        return quality
          ? "Grundwasser - Förderbrunnen Qualität"
          : "Grundwasser - Förderbrunnen";
      }
      if (STA_LOCATION_TYPE.toLowerCase().indexOf("piezometer") !== -1) {
        return quality
          ? "Grundwasser - Piezometer Qualität"
          : "Grundwasser - Piezometer";
      }
    }
  }

  // Niederschlag
  if (object_type.indexOf("Niederschlag - Hydrometrie") !== -1) {
    return "Niederschlag - Hydrometrie";
  }

  // Bodenfeuchte
  if (object_type.indexOf("Boden") !== -1) {
    return "Bodenfeuchte";
  }
  return "unknown";
};

/*
 * checks if there are messdaten cached.
 * and that they are not too old.
 * returns {boolean} - false if messdaten are not available or too old, true otherwise.
 */
const checkMessdaten = () => {
  if (!appState.messdaten) {
    return false;
  }
  const now = new Date();
  const messdatenDate = appState.messdaten.date;
  const elapsed = Math.floor((messdatenDate - now) / 1000);
  if (elapsed > 600) {
    // > 10 minutes
    return false;
  } else {
    return true;
  }
};

/*
 * sorts a timeseries array in a specific order.
 * @param {array} time_series - the time series objects.
 * @param {array} sortOrder - the asc parameter sort order (top first).
 * @returns {array} - ts_copy - a sorted copy of the time_series array from the input parameter.
 */
const sortTimeSeries = (time_series, sortOrder) => {
  const ts_copy = [...time_series];
  for (var i = sortOrder.length; i >= 0; i--) {
    const searchterm = sortOrder[i];
    const searchterm_index = ts_copy.findIndex(
      element => element.stationparameter_name === searchterm
    );
    if (searchterm_index !== -1) {
      //remove the element from the ts_copy...
      const removedElement = ts_copy.splice(searchterm_index, 1);
      //...and add the it to the start of the ts_copy
      ts_copy.splice(0, 0, removedElement[0]);
    }
  }
  return ts_copy;
};

export const labelMessdatenLayer = async layer => {
  const features = layer.mapLayer.getSource().getFeatures();
  for (const key in features) {
    const feature = features[key];
    const timeSeriesGroupId = TS_GROUP_ID[layer.name];
    const stationProps = feature.getProperties();
    const { station_id } = stationProps;
    const station = { station_id, labels: {} };
    // get possible diagrams/timeseries this station
    try {
      let time_series = await getStationTimeSeries(
        station_id,
        timeSeriesGroupId
      );
      if (time_series.length > 0) {
        // sort fliessgewaesser and grundwasser timeseries
        if (
          (stationProps.object_type &&
            stationProps.object_type.toLowerCase().indexOf("grundwasser") !==
              -1) ||
          stationProps.object_type.toLowerCase().indexOf("fliessgewässer") !==
            -1
        ) {
          const sortOrder = [
            "Abfluss",
            "Pegel",
            "Wassertemperatur",
            "pH-Wert",
            "Elektrische Leitfähigkeit"
          ];
          const sorted_time_series = sortTimeSeries(time_series, sortOrder);
          time_series = sorted_time_series;
        }
        for (const j in time_series) {
          const serie = time_series[j];
          const { ts_id } = serie;
          const latest_measurement = await getTimeSeriesMeasurement(ts_id);
          if (latest_measurement && latest_measurement.length > 0) {
            let {
              parametertype_name,
              ts_unitsymbol,
              data
            } = latest_measurement[0];
            // translate the unitsymbol if necessary
            const unitsymbol = unitSymbols[ts_unitsymbol]
              ? unitSymbols[ts_unitsymbol]
              : ts_unitsymbol;
            if (data && data.length > 0) {
              let absoulteValue = data[0][2];
              let label = "";
              if (absoulteValue) {
                if (
                  parametertype_name === "Grundwasserspiegel" ||
                  parametertype_name === "Pegel"
                ) {
                  label = `${data[0][1]} ${ts_unitsymbol} / ${data[0][2]} ${unitsymbol}`;
                } else {
                  label = `${data[0][1]} ${unitsymbol} / ${data[0][2]} ${unitsymbol}`;
                }
              } else {
                label = `${data[0][1]} ${unitsymbol}`;
              }
              station.labels[parametertype_name] = label;
            }
          }
        }
        const oldStyle = feature.getStyle().clone();
        const newStyle = styles.getOlMessdatenLabel(oldStyle, station);
        feature.setStyle(newStyle);
      }
    } catch (error) {
      console.error(error);
      appState.setSnackbar({
        open: true,
        message: `Messdaten können nicht mit einem Label versehen werden. ${error}`
      });
    }
  }
};

/*
 * get a list of timeseries for a station.
 * @param {string} station_id - id of the station.
 * @param {number} timeSeriesGroupId - the id of the timeseries group to query. defaults to 41608 (Hydrometrie)
 * @returns {array} - array of timeseries objects.
 */
const getStationTimeSeries = async (station_id, timeSeriesGroupId = 41608) => {
  try {
    const timeSeriesResponse = await fetch(
      `${KIWIS_HOST}${TIME_SERIES_LIST}&station_id=${station_id}&timeseriesgroup_id=${timeSeriesGroupId}`
    );
    const timeseriesJson = await timeSeriesResponse.json();
    // filter out statistical timeseries
    return timeseriesJson.filter(series => {
      const ts_shortname = series.ts_shortname.toLowerCase();
      if (
        ts_shortname.indexOf("min") !== -1 ||
        ts_shortname.indexOf("max") !== -1 ||
        ts_shortname.indexOf("mean") !== -1
      ) {
        return false;
      }
      return true;
    });
  } catch (error) {
    throw new Error(error);
  }
};

/*
 * get the latest data/value for a timeseries.
 * @param {string} ts_id - timeseries id.
 * @returns {array} - array of objects with measurement data.
 */
const getTimeSeriesMeasurement = async ts_id => {
  try {
    const latest_measurement_response = await fetch(
      `${KIWIS_HOST}${LATEST_MEASURMENT}&ts_id=${ts_id}`
    );
    const result = await latest_measurement_response.json();
    // there might be an error in the latest measurement.
    // in this case, we look for a valid measurement during the latest 6h.
    if (result[0].data.length > 0 && result[0].data[0][1] === null) {
      const last_period_response = await fetch(
        `${KIWIS_HOST}${LATEST_MEASURMENT}&ts_id=${ts_id}&period=${LATEST_MEASUREMENT_PERIOD}`
      );
      const last6h = await last_period_response.json();
      const data = last6h[0].data;
      for (let i = data.length - 1; i >= 0; i--) {
        const measurement = data[i];
        if (measurement[1]) {
          result[0].data[0] = measurement;
          break;
        }
        i--;
      }
    }
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

/*
 * translations for measure unit symbols (if necessary).
 */
const unitSymbols = {
  cumec: "m3/s",
  m: "m.ü.Meer"
};
