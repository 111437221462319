import React from "react";
import PropTypes from "prop-types";
import { ImageList, Typography, ImageListItem } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import appState from "../../utils/appState";
const colors = [
  { name: "black", hex: "#000000", rgba: "rgba(0,0,0,0.5" },
  { name: "white", hex: "#ffffff", rgba: "rgba(255,255,255,0.5)" },
  { name: "red", hex: "#ff0000", rgba: "rgba(255,0,0,0.5)" },
  { name: "green", hex: "#008000", rgba: "rgba(0,128,0,0.5)" },
  { name: "blue", hex: "#0000FF", rgba: "rgba(0,0,255,0.5)" },
  { name: "yellow", hex: "#FFFF00", rgba: "rgba(255,255,0,0.5)" },
  { name: "cyan", hex: "#00FFFF", rgba: "rgba(0,255,255,0.5)" },
  { name: "magenta", hex: "#FF00FF", rgba: "rgba(255,0,255,0.5" }
];
const Colors = ({ selectedColor, setColor }) => {
  return (
    <ImageList rowHeight="auto" cols={8}>
      <ImageListItem style={{ width: "100%" }}>
        <Typography variant="caption">Farbe</Typography>
      </ImageListItem>
      {colors.map(color => (
        <ImageListItem key={color.name}>
          <div
            title={color.name}
            className="tools__color"
            style={{
              backgroundColor: color.hex
            }}
            onClick={() => {
              setColor(color.hex);
              appState.drawingTool.setColor(color);
            }}
          >
            {color.hex === selectedColor && (
              <CheckCircleOutlineIcon className="tools__checkCircle" />
            )}
          </div>
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default Colors;

Colors.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired
};
