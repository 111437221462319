import React from "react";
import PropTypes from "prop-types";
import { Button, Collapse } from "@material-ui/core";
import GeodatenkatalogContainer from "../geodatenkatalog/GeodatenkatalogContainer";
import { getSidebarVisibilityState } from "../../utils/sidebarUtil";
import "./Sidebar.css";

const GeodatenkatalogSidebar = ({
  menuItems,
  setMenuItems,
  layers,
  setModal,
  setLayer,
  addToCart
}) => {
  const menuItem = menuItems.filter(item => item.label === "Geodatenkatalog");
  return (
    <div
      className={menuItem[0].visible ? "sidebar" : "sidebar sidebar--collapsed"}
      style={{ zIndex: menuItem[0].zIndex }}
    >
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() =>
          setMenuItems(oldState =>
            getSidebarVisibilityState(oldState, menuItem[0].label)
          )
        }
      >
        schliessen
      </Button>
      <section className="sidebar__content">
        <Collapse in={menuItem[0].visible} mountOnEnter={true}>
          <GeodatenkatalogContainer
            visibleLayers={layers}
            addToCart={addToCart}
            setModal={setModal}
            setLayer={setLayer}
            setMenuItems={setMenuItems}
          />
        </Collapse>
      </section>
    </div>
  );
};

export default GeodatenkatalogSidebar;

GeodatenkatalogSidebar.propTypes = {
  menuItems: PropTypes.array.isRequired,
  setMenuItems: PropTypes.func.isRequired,
  layers: PropTypes.array.isRequired,
  setModal: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  setLayer: PropTypes.func.isRequired
};
