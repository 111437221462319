import React from "react";
import PropTypes from "prop-types";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Badge, withStyles } from "@material-ui/core";
import { newsStyles } from "./newsStyles";
const Notifications = ({ classes, latestNewsCount }) => {
  return (
    <Badge
      classes={{ colorPrimary: classes.news }}
      badgeContent={latestNewsCount}
      color="primary"
      overlap="rectangular"
    >
      <NotificationsIcon />
    </Badge>
  );
};
export default withStyles(newsStyles)(Notifications);

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
  latestNewsCount: PropTypes.number.isRequired
};
