import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";

const FormatSelector = ({
  printFormats,
  printFormat,
  setPrintFormat,
  styles,
  formatError
}) => {
  const inputLabel = formatError
    ? "Bitte wählen Sie das Papierformat."
    : "Papierformat:";
  return (
    <FormControl style={styles.fullWidth} error={formatError}>
      <InputLabel>{inputLabel}</InputLabel>
      <NativeSelect
        value={printFormat}
        onChange={e => {
          setPrintFormat(e.target.value);
        }}
        inputProps={{
          name: "format",
          id: "print-format"
        }}
      >
        {printFormats.map(format => (
          <option key={format} value={format}>
            {format}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};
export default FormatSelector;
FormatSelector.propTypes = {
  printFormats: PropTypes.array.isRequired,
  printFormat: PropTypes.string.isRequired,
  setPrintFormat: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  formatError: PropTypes.bool.isRequired
};
