import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import {
  Card,
  CardMedia,
  CardContent,
  makeStyles,
  Typography
} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    margin: "25px 0 0 0"
  }
}));
const NewsItem = ({ item }) => {
  const creationdate = new Date(item.datum);
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      {item.bild && (
        <CardMedia
          component="img"
          alt={item.titel}
          image={`https://webgis.lisag.ch/backend_geour/news/bilder/${item.bild}`}
          title={item.titel}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {item.titel}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text) }}
        ></Typography>
        {item.pdf && (
          <Typography variant="body2">
            <a
              href={`https://webgis.lisag.ch/backend_geour/news/pdf/${item.pdf}`}
            >
              <PictureAsPdfIcon /> Weitere Informationen
            </a>
          </Typography>
        )}
        <div
          style={{
            borderBottom: "1px dashed lightgrey",
            padding: "16px 0 0 0"
          }}
        ></div>
        <Typography variant="caption" color="textSecondary">
          {creationdate.toLocaleDateString("de-ch")} / {item.ersteller}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NewsItem;
NewsItem.propTypes = {
  item: PropTypes.object.isRequired
};
