import React, { useRef } from "react";
import PropTypes from "prop-types";
import { loadScript } from "../../utils/loadScript";
import { useEffect } from "react";
import appState from "../../utils/appState";

const diagramData = {
  datasets: [
    {
      label: "Geländeprofil",
      fill: true,
      pointRadius: 0,
      backgroundColor: "rgba(251,192,45,0.7)",
      borderColor: "black",
      borderWidth: 2,
      tension: 0.1
    }
  ]
};

const ProfileGraph = ({ diagramPanel }) => {
  const canvas = useRef(null);
  const profileChart = useRef(null);
  useEffect(() => {
    if (diagramPanel.data) {
      if (typeof window.Chart === "undefined") {
        const chartjs =
          "https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.5.0/chart.min.js";
        loadScript(chartjs)
          .then(response => {
            diagramData.labels = diagramPanel.data.labels;
            diagramData.datasets[0].data = diagramPanel.data.data;
            const ctx = canvas.current;
            profileChart.current = new window.Chart(ctx, {
              type: "line",
              data: diagramData,
              options: {
                locale: "de-CH",
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: "Höhe [m]",
                      font: { size: 11, weight: "bold" }
                    }
                  },
                  x: {
                    title: {
                      display: true,
                      text: "Entfernung [m]",
                      font: { size: 11, weight: "bold" }
                    },
                    ticks: { maxTicksLimit: 20, font: { size: 11 } }
                  }
                },
                parsing: {
                  xAxisKey: "id",
                  yAxisKey: "nested.height"
                },
                interaction: {
                  intersect: false
                },
                plugins: {
                  tooltip: {
                    displayColors: false,
                    callbacks: {
                      title: context => {
                        return "";
                      },
                      label: context => {
                        //moving circle on the corresponding position of the line.
                        const profilePointSource = appState.profilePointLayer.getSource();
                        if (appState.profilePointLayer.getVisible() === false) {
                          appState.profilePointLayer.setVisible(true);
                        }
                        profilePointSource
                          .getFeatureById(1)
                          .getGeometry()
                          .setCoordinates([
                            context.raw.nested.easting,
                            context.raw.nested.northing
                          ]);
                        return [
                          `Entfernung: ${context.label} m`,
                          `Höhe: ${context.formattedValue} m.ü.M`
                        ];
                      }
                    }
                  }
                }
              }
            });
          })
          .catch(error => {
            console.error(error);
            return <div>Diagramm Library konnte nicht geladen werden</div>;
          });
      } else {
        diagramData.labels = diagramPanel.data.labels;
        diagramData.datasets[0].data = diagramPanel.data.data;
        profileChart.current.data = diagramData;
        profileChart.current.update();
      }
    }
  }, [diagramPanel]);

  return (
    <div>
      <canvas
        ref={canvas}
        id="profileGraph"
        width={window.innerWidth}
        height={window.innerHeight * 0.25}
        onMouseLeave={e => appState.profilePointLayer.setVisible(false)}
      ></canvas>
    </div>
  );
};

export default ProfileGraph;

ProfileGraph.propTypes = {
  data: PropTypes.object
};
