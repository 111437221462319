import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Collapse,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  IconButton,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getCapabilitiesInfo } from "../../utils/capabilitiesUtil";

const predefinedWMS = [
  {
    label: "WMS geo.admin.ch",
    url:
      "https://wms.geo.admin.ch/?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities&lang=de"
  },
  {
    label: "WMS Kt. Glarus",
    url:
      "https://wms.geo.gl.ch/?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities"
  },
  {
    label: "WMS Kt. Schwyz",
    url:
      "https://map.geo.sz.ch/mapserv_proxy?SERVICE=WMS&REQUEST=GetCapabilities&lang=de"
  },
  {
    label: "geodienste.ch, Amtliche Vermessung",
    url: "https://geodienste.ch/db/av_0/deu?SERVICE=WMS&REQUEST=GetCapabilities"
  },
  {
    label: "geodienste.ch, KbS",
    url:
      "https://geodienste.ch/db/kataster_belasteter_standorte_v1_4_0/deu?SERVICE=WMS&REQUEST=GetCapabilities"
  }
];

/*
 * checks if webMercator (EPSG:3857) projection is available
 * @param {array} projections - array with EPSG projections strings.
 * @returns {boolean} - true if webMercator is available, false otherwise.
 */
const checkWebMercator = projections => {
  const result = projections.filter(
    projection => projection.toUpperCase() === "EPSG:3857"
  );
  return result.length > 0 ? true : false;
};

const WmsImportContainer = ({
  setGeodaten,
  setFiltered,
  setCategorizedLayers,
  setCategories
}) => {
  const [url, setUrl] = useState("");
  const [requesting, setRequesting] = useState(false);
  const [wmsSuggestions, setWmsSuggestions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [expanded, setExpandedd] = useState(false);
  /*
   * checks if a wms has layers which can be displayed on
   * on the geo.ur map, and open the side panel if so.
   * @param {string} url - the url of the wms service.
   */
  const checkWms = async url => {
    setRequesting(true);
    setWmsSuggestions(false);
    try {
      const wmsInfos = await getCapabilitiesInfo({
        type: "wms",
        url
      });
      // check for https
      const splittedOnlineResource = wmsInfos.Service.OnlineResource.split(":");
      if (splittedOnlineResource[0] === "http") {
        splittedOnlineResource[0] = "https";
        wmsInfos.Service.OnlineResource = splittedOnlineResource.join(":");
      }
      const layerList = wmsInfos.Capability.Layer.Layer;
      const wmsList = [];
      layerList.forEach(layer => {
        const wmsObj = {
          name_offiziell: layer.Title,
          serviceName: layer.Name,
          extern: true,
          getCapabilities: url,
          onlineResource:
            wmsInfos.Service.OnlineResource || url.slice(0, url.indexOf("/?")),
          attribution: wmsInfos.Service.Title,
          geodaten_kategorie: wmsInfos.Service.Title,
          searchterm: layer.Title,
          geoshop_user_id: 0,
          abstract: layer.Abstract ? layer.Abstract : layer.Title,
          legende: ""
        };
        if (layer.Style) {
          const style = layer.Style[0];
          if (style.LegendURL[0]) {
            wmsObj.legende = style.LegendURL[0].OnlineResource;
          }
        }
        wmsList.push(wmsObj);
      });
      const { CRS } = wmsInfos.Capability.Layer;
      if (CRS) {
        // wms version=1.3.0 => check for webMercator projection
        if (checkWebMercator(CRS)) {
          setGeodaten(geodaten => [...geodaten, ...wmsList]);
          setFiltered(geodaten => [...geodaten, ...wmsList]);
          setCategorizedLayers(geodaten => [...geodaten, ...wmsList]);
          setCategories(oldCategories => {
            return { ...oldCategories, [wmsList[0].geodaten_kategorie]: true };
          });

          setErrorMessage("");
          setSuccessMessage("Der Geodatenkatalog wurde erfolgreich erweitert.");
        } else {
          setSuccessMessage("");
          setErrorMessage(
            "Dieser WMS untersützt die WebMercator Projektion (EPSG:3857) nicht, und kann deshalb nicht dargestellt werden."
          );
        }
      } else {
        // wms version=1.0.0
        setGeodaten(geodaten => [...geodaten, ...wmsList]);
        setFiltered(geodaten => [...geodaten, ...wmsList]);
        setCategorizedLayers(geodaten => [...geodaten, ...wmsList]);
        setCategories(oldCategories => {
          return { ...oldCategories, [wmsList[0].geodaten_kategorie]: true };
        });
        setErrorMessage("");
        setSuccessMessage("Der Geodatenkatalog wurde erfolgreich erweitert.");
      }
    } catch (error) {
      setErrorMessage(error);
      setSuccessMessage("");
    }
    setRequesting(false);
  };
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={() => {
        setExpandedd(!expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ root: "AccordionSummary", expanded: "expanded" }}
      >
        <Typography>WMS Import...</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: "row" }}>
        <section>
          <FormControl
            error={errorMessage.length > 0}
            variant="outlined"
            style={{ width: "100%", margin: "0 0 8px 0" }}
          >
            <InputLabel htmlFor="wmsInput">WMS Url</InputLabel>
            <OutlinedInput
              onFocus={() => {
                setWmsSuggestions(true);
                setSuccessMessage("");
              }}
              fullWidth={true}
              id="wmsInput"
              type="text"
              value={url}
              onChange={e => setUrl(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={async () => {
                      await checkWms(url);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          {errorMessage.length > 0 && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          {requesting && <LinearProgress color="secondary" />}
          <Collapse in={wmsSuggestions}>
            <List dense={true} disablePadding={true} className="wmsList">
              {predefinedWMS.map(url => (
                <ListItem
                  button
                  key={url.label}
                  divider={true}
                  onClick={async () => {
                    setUrl(url.url);
                    await checkWms(url.url);
                  }}
                >
                  <ListItemText>{url.label}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Collapse>
          {successMessage.length > 0 && (
            <Typography variant="caption" style={{ color: "green" }}>
              {successMessage}
            </Typography>
          )}
        </section>
      </AccordionDetails>
    </Accordion>
  );
};

export default WmsImportContainer;

WmsImportContainer.propTypes = {
  setGeodaten: PropTypes.func.isRequired,
  setFiltered: PropTypes.func.isRequired,
  setCategorizedLayers: PropTypes.func.isRequired,
  setCategories: PropTypes.func.isRequired
};
