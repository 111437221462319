export const loadScript = url => {
  return new Promise((resolve, reject) => {
    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    // there are several events for cross browser compatibility
    script.onreadystatechange = () => resolve("script loaded");
    script.onload = () => resolve("script loaded");
    script.onerror = () => reject("failed loading script");
    // fire the loading
    head.appendChild(script);
  });
};

export const loadCss = url => {
  return new Promise((resolve, reject) => {
    var head = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = url;
    // there are several events for cross browser compatibility
    link.onreadystatechange = () => resolve("css loaded");
    link.onload = () => resolve("css loaded");
    link.onerror = () => reject("failed loading css");

    // fire the loading
    head.appendChild(link);
  });
};
