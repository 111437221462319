import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CategoryChip from "./CategoryChip";

const useStyles = makeStyles({
  grid: {
    border: "1px dotted grey",
    padding: "12px",
    margin: "8px 0"
  }
});

const GeodatenkatalogCategoryFilter = ({
  categories,
  setCategories,
  layers,
  setCategorizedLayers
}) => {
  const classes = useStyles();
  return (
    <Grid container classes={{ root: classes.grid }}>
      <Grid item xs={12} sm={12}>
        Kategorienfilter
      </Grid>
      {Object.keys(categories).map(category => {
        return (
          <CategoryChip
            category={category}
            categories={categories}
            setCategories={setCategories}
            layers={layers}
            setCategorizedLayers={setCategorizedLayers}
            key={category}
          />
        );
      })}
    </Grid>
  );
};

export default GeodatenkatalogCategoryFilter;
