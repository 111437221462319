import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import PrintContainer from "../print/PrintContainer";
import { getSidebarVisibilityState } from "../../utils/sidebarUtil";
import { changeVectorLayerVisibility } from "../olMap/renderOlMap";
import appState from "../../utils/appState";
import "./Sidebar.css";

const printFormats = [
  "Bitte wählen...",
  "A4 hoch",
  "A4 quer",
  "A3 hoch",
  "A3 quer",
  "A2 hoch",
  "A2 quer"
];
const PrintSidebar = ({ menuItems, setMenuItems }) => {
  const menuItem = menuItems.filter(item => item.label === "Drucken");
  const [printFormat, setPrintFormat] = useState(printFormats[0]);
  return (
    <div
      className={menuItem[0].visible ? "sidebar" : "sidebar sidebar--collapsed"}
      style={{ zIndex: menuItem[0].zIndex }}
    >
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => {
          changeVectorLayerVisibility({
            map: appState.olMap,
            type: "hide",
            usageType: "print"
          });
          setMenuItems(oldState =>
            getSidebarVisibilityState(oldState, menuItem[0].label)
          );
        }}
      >
        schliessen
      </Button>
      <section className="sidebar__content">
        <Typography variant="subtitle1" gutterBottom={true}>
          DRUCKEINSTELLUNGEN:
        </Typography>
        <Collapse in={menuItem[0].visible} mountOnEnter={true}>
          <PrintContainer
            printFormats={printFormats}
            printFormat={printFormat}
            setPrintFormat={setPrintFormat}
          />
        </Collapse>
      </section>
    </div>
  );
};

export default PrintSidebar;

PrintSidebar.propTypes = {
  menuItems: PropTypes.array.isRequired,
  setMenuItems: PropTypes.func.isRequired
};
