import yellow from "@material-ui/core/colors/yellow";
export const styles = theme => ({
  root: {
    margin: "0 0 5px 0"
  },
  thumb: {
    backgroundColor: yellow["A700"]
  },
  markLabel: {
    fontSize: "10px"
  },
  iconButton: {
    padding: "8px"
  },
  cartButton: {
    padding: "8px"
  },
  switch: {
    marginLeft: "12px",
    color: theme.palette.primary.light
  },
  colorSwitchBase: {
    color: yellow[500], //knob unchecked => yellow lighter

    "&$colorChecked": {
      color: yellow["A700"], //knob checked => yellow
      "& + $colorBar": {
        backgroundColor: theme.palette.primary.light,
        opacity: 1
      }
    }
  },
  colorBar: { opacity: 0.2 },
  colorChecked: {},
  formControlLabel: {
    marginRight: "4px",
    maxWidth: "91%"
  },
  switchLabel: {
    fontSize: "12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    paddingLeft: "4px",
    textOverflow: "ellipsis"
  },
  colorAction: {
    color: "limegreen"
  }
});
