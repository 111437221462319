import React from "react";
import DOMPurify from "dompurify";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const styles = {
  video: { minWidth: "100%" },
  image: { maxWidth: "100%" }
};
const Faq = ({ faqs }) => {
  return (
    <>
      {faqs.length > 0 &&
        faqs.map(faq => {
          const antwort = JSON.parse(faq.antwort);
          return (
            <Accordion TransitionProps={{ unmountOnExit: true }} key={faq.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${faq.id}-content`}
                id={`${faq.id}-header`}
              >
                <Typography variant="h6">{faq.frage}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexWrap: "wrap" }}>
                {faq.bild && (
                  <section>
                    <img
                      src={`https://webgis.lisag.ch/backend_geour/faq/bilder/${faq.bild}`}
                      alt={faq.frage}
                      style={styles.image}
                    />
                  </section>
                )}
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(antwort.html)
                  }}
                />
                {faq.video && (
                  <section style={styles.video}>
                    <iframe
                      title={faq.frage}
                      width="100%"
                      height="258"
                      src={`https://www.youtube.com/embed/${faq.video}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </section>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
};
export default Faq;
