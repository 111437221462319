import Overlay from "ol/Overlay";
import { getArea, getLength } from "ol/sphere";
import { LineString, Polygon, Point } from "ol/geom";
import Draw from "ol/interaction/Draw";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { unByKey } from "ol/Observable";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import { GeoJSON } from "ol/format";
import { convertPointCoordinates } from "../utils/projectionUtil";
import { getHeightUrl, getProfileUrl } from "../utils/ENV";
import { projectGeojson } from "./layerUtil";
import appState from "./appState";

export default class MeasureTool {
  constructor(map, setActiveButton) {
    this.map = map;
    this.setActiveButton = setActiveButton;
    this.mode = "LineString";
    this.sketch = null;
    this.continueMsg = "Klicken um mit dem Messen fortzufahren";
    this.source = new VectorSource();
    this.vector = this.getVectorLayer();
    this.draw = this.getDraw();
    this.measureTooltips = [];
    this.helpTooltips = [];
    this.style = new Style({
      fill: new Fill({
        color: "rgba(255, 255, 255, 0.2)"
      }),
      stroke: new Stroke({
        color: "#ff0000",
        width: 2
      }),
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({
          color: "#ff0000"
        }),
        stroke: new Stroke({
          color: "#ff0000",
          width: 3
        })
      }),
      text: new Text({
        scale: 0, //make it invisible, we only need it for printing
        font: "10px sans-serif",
        fill: new Fill({ color: "#000000" }),
        stroke: new Stroke({ color: "#000000" }),
        text: ""
      })
    });
  }
  /*
   * creates a Vector layer to display the drawings.
   * it has the same source as the Draw.
   * this layer displays the drawings after "drawend" event gets called.
   * @return {object} vectorlayer - ol/Layer/Vector instance
   */
  getVectorLayer() {
    if (this.vector) {
      return this.vector;
    } else {
      const vectorlayer = new VectorLayer({
        source: this.source,
        style: null,
        zIndex: 1000
      });
      vectorlayer.usageType = "measure";
      this.map.addLayer(vectorlayer);
      return vectorlayer;
    }
  }
  /*
   * create a new Draw object with the necessary source,styles and event listeners
   * @returns {Draw} draw - ol Draw instance.
   */
  getDraw() {
    const draw = new Draw({
      source: this.source,
      type: this.mode,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)"
        }),
        stroke: new Stroke({
          color: "rgba(255, 0, 0, 0.8)",
          lineDash: [10, 10],
          width: 2
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(255, 0, 0, 0.7)"
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)"
          })
        })
      })
    });
    draw.on("drawstart", evt => {
      let tooltipCoord = [];
      this.sketch = evt.feature;
      const geometry = evt.feature.getGeometry();
      if (geometry instanceof Point) {
        tooltipCoord = geometry.getCoordinates();
        this.measureTooltipElement.innerHTML = this.formatPoint(tooltipCoord);
        this.measureTooltip.setPosition(tooltipCoord);
        return;
      }
      this.geometrychangelistener = this.sketch
        .getGeometry()
        .on("change", evt => {
          var geom = evt.target;
          let output = "";
          if (geom instanceof Polygon) {
            output = this.formatArea(geom);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            output = this.formatLength(geom);
            tooltipCoord = geom.getLastCoordinate();
          }
          this.measureTooltipElement.innerHTML = output;
          this.measureTooltip.setPosition(tooltipCoord);
        });
    });

    draw.on("drawend", e => {
      const feature = e.feature;
      this.measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
      this.measureTooltip.setOffset([0, -7]);
      feature.measure = true;
      const style = this.style.clone(); // important, otherwise we have the same labels on every geometry
      style.getText().setText(this.measureTooltipElement.innerText);
      feature.setStyle(style); // style on feature level in order to print measure geometries
      this.style = style; //important because we add the height to this style later
      // unset sketch
      this.sketch = null;
      // unset tooltip so that a new one can be created
      this.measureTooltipElement = null;
      this.createMeasureTooltip();
      unByKey(this.geometrychangelistener);
      if (feature.getGeometry() instanceof LineString) {
        this.createProfileGraph(feature);
      }
    });
    return draw;
  }

  /*
   * create a profile graph from a line measurement
   * @param {object} feature - ol/feature instance.
   * @returns....
   */
  createProfileGraph(feature) {
    const geojsonFormat = new GeoJSON();
    const geojson = JSON.parse(geojsonFormat.writeFeature(feature));
    const lv95String = projectGeojson({
      geojson,
      dataProjection: "EPSG:2056",
      featureProjection: "EPSG:3857"
    });
    const lv95Geojson = JSON.parse(lv95String).features[0].geometry;
    const url = getProfileUrl(JSON.stringify(lv95Geojson));
    fetch(url)
      .then(response => response.json())
      .then(json => {
        this.toggleProfileWindow(true);
        const data = this.processProfileData(json);
        appState.setDiagramPanel(oldState => {
          return { ...oldState, data };
        });
      })
      .catch(error => {
        console.error(error);
        this.toggleProfileWindow(false);
      });
  }
  toggleProfileWindow(open = true) {
    appState.setDiagramPanel(currentState => {
      return { ...currentState, open };
    });
  }

  processProfileData(rawData) {
    const result = { labels: [], data: [], statistics: {} };
    if (!rawData) {
      return result;
    }
    let uphill = 0;
    let downhill = 0;
    rawData.forEach((element, iteration) => {
      const height = element.alts.DTM2;
      const webMercatorCoordinates = convertPointCoordinates({
        sourceProj: "EPSG:2056",
        destProj: "EPSG:3857",
        coordinates: [element.easting, element.northing]
      });
      result.labels.push(element.dist.toLocaleString("de-CH"));
      result.data.push({
        id: element.dist.toLocaleString("de-CH"),
        nested: {
          dist: element.dist,
          height,
          easting: webMercatorCoordinates[0],
          northing: webMercatorCoordinates[1],
          lv95Easting: element.easting,
          lv95Northing: element.northing
        }
      });
      if (iteration === 0) {
        result.statistics.top = height;
        result.statistics.bottom = height;
      } else {
        const previousHeight = rawData[iteration - 1].alts.DTM2;
        if (result.statistics.top < height) {
          result.statistics.top = height;
        }
        if (result.statistics.bottom > height) {
          result.statistics.bottom = height;
        }
        if (height < previousHeight) {
          downhill += previousHeight - height;
        }
        if (height > previousHeight) {
          uphill += height - previousHeight;
        }
      }
    });
    const drawingFeatures = this.source.getFeatures();
    const lastDrawingFeature = drawingFeatures[drawingFeatures.length - 1];
    result.statistics.uphill = uphill;
    result.statistics.downhill = downhill;
    result.statistics.hdiff = uphill - downhill;
    result.statistics.length = getLength(
      lastDrawingFeature.getGeometry()
    ).toFixed(2);
    return result;
  }

  /**
   * Handle pointer move.
   * @param {object} evt - ol/MapBrowserEvent"
   */
  pointerMoveHandler(evt) {
    if (evt.dragging) {
      return;
    }
    this.helpMsg = "Klicken um mit dem Messen zu beginnen";

    if (this.sketch) {
      const geom = this.sketch.getGeometry();
      if (geom instanceof Polygon || geom instanceof LineString) {
        this.helpMsg = this.continueMsg;
      }
    }
    this.helpTooltipElement.innerHTML = this.helpMsg;
    this.helpTooltip.setPosition(evt.coordinate);
    this.helpTooltipElement.style.display = "block";
  }

  /*
   * handle mouse out - remove help tooltip
   */
  mouseOutHandler() {
    const helpTooltips = document.getElementsByClassName("ol-tooltip-help");
    helpTooltips[0].style.display = "none";
  }

  /**
   * Format length output.
   * @param {LineString} line The line.
   * @return {string} The formatted length.
   */
  formatLength(line) {
    const length = getLength(line);
    return `${(Math.round(length * 100) / 100).toLocaleString("de-CH")} m`;
  }

  /**
   * Format area output.
   * @param {Polygon} polygon The polygon.
   * @return {string} Formatted area.
   */
  formatArea(polygon) {
    const area = getArea(polygon);
    return `${(Math.round(area * 100) / 100).toLocaleString(
      "de-CH"
    )} m<sup>2</sup>`;
  }

  /**
   * Format point output.
   * @param {Point} point - The point.
   * @return {string} Formatted point coordinate and height values.
   */
  formatPoint(point) {
    const lv95coord = convertPointCoordinates({
      sourceProj: "EPSG:3857",
      destProj: "EPSG:2056",
      coordinates: point
    });
    const wgs84oord = convertPointCoordinates({
      sourceProj: "EPSG:3857",
      destProj: "EPSG:4326",
      coordinates: point
    });
    const heighturl = getHeightUrl({
      easting: Math.round(lv95coord[0]),
      northing: Math.round(lv95coord[1])
    });
    const heightText = document.createElement("div");
    fetch(heighturl)
      .then(response => response.json())
      .then(json => {
        const tooltips = document.getElementsByClassName(
          "ol-tooltip ol-tooltip-static"
        );
        const lastTooltip = tooltips[0];
        heightText.innerHTML =
          "<span>Höhe ü. Meer:<strong> " +
          parseFloat(json.height).toLocaleString("de-CH") +
          "m</strong></span>";
        lastTooltip.appendChild(heightText);
        this.style.getText().setText(lastTooltip.innerText);
      })
      .catch(error => {
        const tooltips = document.getElementsByClassName(
          "ol-tooltip ol-tooltip-static"
        );
        const lastTooltip = tooltips[0];
        heightText.innerHTML =
          "<span>Höhe ü. Meer:<strong> Nicht verfügbar.</strong></span>";
        lastTooltip.appendChild(heightText);
      });

    return (
      "<span>LV95:<strong> " +
      parseInt(lv95coord[0]).toLocaleString("de-CH") +
      ", " +
      parseInt(lv95coord[1]).toLocaleString("de-CH") +
      "</strong></span><br />" +
      "WGS84:<strong> " +
      wgs84oord[1].toPrecision(9) +
      ", " +
      wgs84oord[0].toPrecision(8) +
      "</strong>"
    );
  }

  /*
   * remove the drawing interaction and event listeners
   */
  removeInteraction() {
    this.map.removeInteraction(this.draw);
    this.helpTooltips.forEach(helpTooltip =>
      this.map.removeOverlay(helpTooltip)
    );
    unByKey(this.pointermoveevent);
    this.map
      .getViewport()
      .removeEventListener("mouseout", this.mouseOutHandler);
    this.setActiveButton({});
  }

  /*
   * remove measure vectors and overlays
   */
  deleteOverlays() {
    this.removeInteraction();
    this.source.clear();
    this.measureTooltips.forEach(measureTooltip =>
      this.map.removeOverlay(measureTooltip)
    );
    this.toggleProfileWindow(false);
    window.requestAnimationFrame(() => {
      appState.profilePointLayer.setVisible(false);
    });
  }

  /**
   * Creates a new help tooltip
   */
  createHelpTooltip() {
    if (this.helpTooltipElement) {
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
    }
    this.helpTooltipElement = document.createElement("div");
    this.helpTooltipElement.className = "ol-tooltip ol-tooltip-help";
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: "center-left"
    });
    this.helpTooltips.push(this.helpTooltip);
    this.map.addOverlay(this.helpTooltip);
  }

  /**
   * Creates a new measure tooltip
   */
  createMeasureTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(
        this.measureTooltipElement
      );
    }
    this.measureTooltipElement = document.createElement("div");
    this.measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: "bottom-center"
    });
    this.measureTooltips.push(this.measureTooltip);
    this.map.addOverlay(this.measureTooltip);
  }
  /*
   * set the drawing mode
   * @param {string} mode - one of "Polygon, LineString, Point"
   * @returns {string} mode - the actual drawing mode
   */
  setMode(mode) {
    this.map.removeInteraction(this.draw);
    if (mode) {
      this.mode = mode;
    } else {
      this.mode = "LineString";
    }
    this.createHelpTooltip();
    this.createMeasureTooltip();
    this.draw = this.getDraw();
    this.map.addInteraction(this.draw);
    // add the event necessary event listeners
    this.pointermoveevent = this.map.on("pointermove", evt =>
      this.pointerMoveHandler(evt)
    );
    this.map.getViewport().addEventListener("mouseout", this.mouseOutHandler);
    return this.mode;
  }
}
