import React from "react";
import PropTypes from "prop-types";
import Basemaps from "../basemaps/Basemaps";
import Zeitreise from "../basemaps/Zeitreise";
import { Button } from "@material-ui/core";
import { getSidebarVisibilityState } from "../../utils/sidebarUtil";
import {
  orthoBasemap,
  pkFarbeBasemap,
  pkGrauBasemap,
  avfarbigBasemap,
  avgrauBasemap,
  whiteBasemap
} from "../basemaps/basemapDefinitions";
import "./Sidebar.css";

const BasemapSidebar = ({
  menuItems,
  setMenuItems,
  sendBasemapMachine,
  currentBasemap,
  setLayer,
  layers
}) => {
  const menuItem = menuItems.filter(item => item.label === "Basiskarten");

  return (
    <div
      className={menuItem[0].visible ? "sidebar" : "sidebar sidebar--collapsed"}
      style={{ zIndex: menuItem[0].zIndex }}
    >
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => {
          setMenuItems(oldState =>
            getSidebarVisibilityState(oldState, menuItem[0].label)
          );
        }}
      >
        schliessen
      </Button>
      <section className="sidebar__content">
        <Basemaps
          basemaps={[
            orthoBasemap,
            avfarbigBasemap,
            avgrauBasemap,
            pkFarbeBasemap,
            pkGrauBasemap,
            whiteBasemap
          ]}
          sendBasemapMachine={sendBasemapMachine}
          currentBasemap={currentBasemap}
        />
        <Zeitreise setLayer={setLayer} layers={layers} />
      </section>
    </div>
  );
};

export default BasemapSidebar;

BasemapSidebar.propTypes = {
  menuItems: PropTypes.array.isRequired,
  setMenuItems: PropTypes.func.isRequired
};
