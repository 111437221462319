import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  FormControlLabel,
  Switch,
  IconButton,
  Slider
} from "@material-ui/core";
import {
  DragIndicator,
  AddShoppingCart,
  Info,
  Delete,
  History
} from "@material-ui/icons";
import LayerInfos from "../dialog/LayerInfos";
import { styles } from "./layerStyles";
import {
  removeLayer,
  updateLayerOpacity,
  toggleLayer,
  updateLayerTime,
  checkDimensionMarkLabel
} from "../../utils/layerUtil";
import { updateUrlVisibilityOpacity } from "../../utils/urlUtil";
import { createAndAddItemToCart } from "../../utils/geoshopUtil";
import "./Layer.css";

const Layer = ({
  layer,
  setLayer,
  classes,
  setModal,
  addToCart,
  setFeatureInfos
}) => {
  const [checked, toggle] = useState(layer.visible);
  const [opacity, setOpacity] = useState(layer.opacity);
  const [dimensions, setDimensions] = useState([]);
  const [marks, setMarks] = useState([]);
  useEffect(() => {
    if (layer.dimensions) {
      const marks = [];
      layer.dimensions.values.forEach((timeString, index) => {
        const year = new Date(timeString).getFullYear();
        if (checkDimensionMarkLabel(index, layer.dimensions.values.length)) {
          marks.push({ value: year, label: year });
        } else {
          marks.push({ value: year });
        }
      });
      setMarks(marks);
      const dimValues = [marks[0].value, marks[marks.length - 1].value];
      setDimensions(dimValues);
      updateLayerTime({ layer, val: dimValues });
    }
  }, [layer.dimensions, layer]);

  return (
    <Paper elevation={1} className={classes.root} title={layer.name}>
      <div className="layer__top">
        <FormControlLabel
          classes={{
            label: classes.switchLabel,
            root: classes.formControlLabel
          }}
          control={
            <Switch
              size="small"
              classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked,
                track: classes.colorBar,
                root: classes.switch
              }}
              checked={checked}
              onChange={e => {
                toggle(!checked); // component state (switch)
                toggleLayer(layer, checked); // ol and cesium layers
                updateUrlVisibilityOpacity(layer); //url
              }}
              value={layer.name}
            />
          }
          label={layer.name}
        />
        <span className="spacer" />
        <IconButton
          disabled={layer.name === "Zeichnung (aktiv)" ? true : false}
          aria-label="Delete"
          classes={{ root: classes.iconButton }}
          color="primary"
          title="Layer entfernen"
          size="small"
          onClick={() => {
            removeLayer(layer, setLayer, setFeatureInfos);
          }}
        >
          <Delete fontSize="small" />
        </IconButton>
      </div>
      <div className="layer__bottom">
        <div className="layer__handle" title="Layer hoch oder runterschieben">
          <DragIndicator />
        </div>
        <div className={"layer__bottom-slider"}>
          <Slider
            min={0}
            max={1}
            step={0.1}
            marks
            classes={{ thumb: classes.thumb }}
            value={opacity}
            aria-label="opacity"
            aria-valuetext="opacity"
            onChange={(e, value) => {
              setOpacity(value); // component state (slider)
              updateLayerOpacity(layer, value); // ol and cesium layers
            }}
            onChangeCommitted={() => updateUrlVisibilityOpacity(layer)} // update the url
          />
        </div>
        <span className="spacer" />
        {layer.extern && (
          <span title="externer Layer" className="extwms">
            ext. Layer
          </span>
        )}
        {layer?.metadata?.geoshop_user_id > 0 && (
          <IconButton
            classes={{ root: classes.cartButton }}
            color="primary"
            title="Geodaten bestellen"
            size="small"
            onClick={async event => {
              try {
                await createAndAddItemToCart({
                  setModal,
                  layer,
                  addToCart,
                  checkGeoshop: true
                });
              } catch (e) {
                setModal({
                  type: ""
                });
                alert(
                  `Fehler: Layer konnte nicht dem Warenkorb hinzugefügt werden. Bitte wenden Sie sich an die Lisag (041 500 60 60).\n${e}`
                );
              }
            }}
          >
            <AddShoppingCart />
          </IconButton>
        )}

        {layer.name.indexOf("Zeitreise") === -1 &&
          layer.name.indexOf(".kml") === -1 &&
          layer.name.indexOf("Fotos") === -1 &&
          layer.name.indexOf("Zeichnung") === -1 && (
            <IconButton
              classes={{ root: classes.iconButton }}
              color="primary"
              title="Layer Infos"
              size="small"
              onClick={() => {
                if (typeof layer.metadata === "string") {
                  layer.metadata = {
                    kurzbeschrieb: layer.metadata,
                    legende: layer.legendurl
                      ? layer.legendurl
                      : `${layer.onlineResource}SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&LAYER=${layer.serviceName}&FORMAT=image/png&SLD_VERSION=1.1.0`
                  };
                }
                setModal({
                  type: "layerinfo",
                  layer,
                  content: <LayerInfos metadata={layer.metadata} />
                });
              }}
            >
              <Info fontSize="small" />
            </IconButton>
          )}
      </div>
      {layer.dimensions && (
        <div className="layer__bottom">
          <div className="layer__bottom-timetitle">
            <History className="layer__bottom-historyicon" />
            Zeiteinstellung:
          </div>
          <div className={"layer__bottom-timeslider"}>
            <Slider
              value={dimensions}
              marks={marks}
              min={marks[0]?.value || 0}
              max={marks[marks.length - 1]?.value || 0}
              classes={{ thumb: classes.thumb, markLabel: classes.markLabel }}
              onChange={(e, val) => {
                setDimensions(val);
                updateLayerTime({ layer, val });
              }}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
            />
          </div>
        </div>
      )}
    </Paper>
  );
};
Layer.propTypes = {
  classes: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired,
  setLayer: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  setFeatureInfos: PropTypes.func.isRequired
};
export default withStyles(styles)(Layer);
