import React from "react";
import PropTypes from "prop-types";
import GeodatenkatalogLayer from "./GeodatenkatalogLayer";
import { FixedSizeList } from "react-window";
import ListItem from "@material-ui/core/ListItem";

const Geodatenkatalog = ({
  visibleLayers,
  layers,
  addToCart,
  setModal,
  setLayer,
  setMenuItems
}) => {
  const Row = ({ index, style }) => {
    return (
      <ListItem style={style} key={index}>
        <GeodatenkatalogLayer
          visibleLayers={visibleLayers}
          layer={layers[index]}
          addToCart={addToCart}
          setModal={setModal}
          setLayer={setLayer}
          setMenuItems={setMenuItems}
        />
      </ListItem>
    );
  };
  if (layers.length === 0) {
    return (
      <section className="geodatenkatalog__nolayers">
        <span>Es gibt keine Daten die Ihrem Suchbegriff entsprechen...</span>
      </section>
    );
  }
  if (layers.length === 1 && "message" in layers[0]) {
    return (
      <section className="geodatenkatalog__nolayers">
        <span>{layers[0].message}</span>
      </section>
    );
  }
  if (layers.length > 0) {
    return (
      <FixedSizeList
        className="geodatenkatalog__list"
        height={window.innerHeight - 345}
        itemCount={layers.length}
        itemSize={87}
      >
        {Row}
      </FixedSizeList>
    );
  }
};
export default Geodatenkatalog;

Geodatenkatalog.propTypes = {
  visibleLayers: PropTypes.array.isRequired,
  layers: PropTypes.array.isRequired,
  addToCart: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setLayer: PropTypes.func.isRequired,
  setMenuItems: PropTypes.func.isRequired
};
