import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import appState from "../../utils/appState";

const useStyles = makeStyles({
  outlinedPrimary: {
    backgroundColor: "#fbc02d !important",
    border: "1px solid black !important"
  }
});
const GeometryButtonGroup = ({ buttons, activeButton, setActiveButton }) => {
  const classes = useStyles();
  return (
    <ButtonGroup aria-label={"Geometrien zeichnen"}>
      {buttons.map(button => (
        <Button
          classes={{
            outlinedPrimary: classes.outlinedPrimary
          }}
          color={activeButton === button ? "primary" : "default"}
          key={button.title}
          size="small"
          title={button.title}
          onClick={() => {
            if (appState.measureTool) {
              appState.measureTool.removeInteraction();
            }
            if (button.format !== "delete selected") {
              setActiveButton(button);
              // keep the select interaction after
              // the delete button was clicked.
              appState.drawingTool.removeInteraction();
            }
            appState.drawingTool.addInteraction(button);
          }}
        >
          <img src={button.image} alt="Punkt" style={{ height: "24px" }} />
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default GeometryButtonGroup;

GeometryButtonGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
  activeButton: PropTypes.object.isRequired,
  setActiveButton: PropTypes.func.isRequired
};
