import React from "react";
import PropTypes from "prop-types";
import { Button, Collapse, IconButton } from "@material-ui/core";
import {
  Close,
  TrendingDown,
  TrendingUp,
  Height,
  VerticalAlignBottom,
  VerticalAlignTop,
  CloudDownload
} from "@material-ui/icons";
import length from "./img/length.svg";
import ProfileGraph from "../profileGraph/ProfileGraph";
import "./Sidebar.css";

const DiagramSidebar = ({ diagramPanel, setDiagramPanel }) => {
  return (
    <div
      className={
        diagramPanel.open
          ? "diagramsidebar"
          : "diagramsidebar sidebar--collapsed"
      }
    >
      <section>
        <Collapse in={diagramPanel.open}>
          <div className="diagramsidebar__content">
            <div className="diagramsidebar__header">
              <Button
                variant="contained"
                color="default"
                startIcon={<CloudDownload />}
                size="small"
                title="Download als CSV Datei"
                onClick={() => {
                  const header = ["distance,easting,northing,height"];
                  const csvArray = [header.join(",")];
                  for (let i = 0; i < diagramPanel.data.data.length; i++) {
                    const data = diagramPanel.data.data[i];
                    const row = [
                      data.nested.dist,
                      data.nested.lv95Easting,
                      data.nested.lv95Northing,
                      data.nested.height
                    ];
                    csvArray.push(row.join(","));
                  }
                  // stringify the csv and add line breaks after every array element
                  const csv = csvArray.join("\r\n");
                  //Download the file as CSV
                  var downloadLink = document.createElement("a");
                  var blob = new Blob([csv], { type: "text/csv" });
                  var url = URL.createObjectURL(blob);
                  downloadLink.href = url;
                  downloadLink.download = "hoehenprofil.csv"; //Name the file here
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }}
              >
                csv
              </Button>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() =>
                  setDiagramPanel(currentState => {
                    return { ...currentState, open: false };
                  })
                }
              >
                <Close fontSize="inherit" />
              </IconButton>
            </div>
            <ProfileGraph diagramPanel={diagramPanel} />
            {diagramPanel.data?.statistics?.top && (
              <section className="diagramsidebar__statisticsection">
                <span
                  className="diagramsidebar__statistic"
                  title="Höhendifferenz Start-Ende"
                >
                  <Height />
                  <span>{diagramPanel.data.statistics.hdiff.toFixed(2)}m</span>
                </span>
                <span className="diagramsidebar__statistic" title="Aufstieg">
                  <TrendingUp />
                  <span>{diagramPanel.data.statistics.uphill.toFixed(2)}m</span>
                </span>
                <span className="diagramsidebar__statistic" title="Abstieg">
                  <TrendingDown />
                  <span>
                    {diagramPanel.data.statistics.downhill.toFixed(2)}m
                  </span>
                </span>
                <span
                  className="diagramsidebar__statistic"
                  title="Tiefster Punkt"
                >
                  <VerticalAlignBottom />
                  <span>
                    {diagramPanel.data.statistics.bottom.toFixed(2)} m.ü.M
                  </span>
                </span>
                <span
                  className="diagramsidebar__statistic"
                  title="Höchster Punkt"
                >
                  <VerticalAlignTop />
                  <span>
                    {diagramPanel.data.statistics.top.toFixed(2)} m.ü.M
                  </span>
                </span>
                <span
                  className="diagramsidebar__statistic"
                  title="Länge (Luftlinie)"
                  style={{ flexGrow: 1 }}
                >
                  <img
                    src={length}
                    alt="luftlinie"
                    className="diagramsidebar__statistic--image"
                  />
                  <span>&nbsp;{diagramPanel.data.statistics.length} m</span>
                </span>
                <span className="diagramsidebar__statistic--geodata">
                  swissALTI3D/DHM25
                </span>
              </section>
            )}
          </div>
        </Collapse>
      </section>
    </div>
  );
};

export default DiagramSidebar;

DiagramSidebar.propTypes = {
  diagramPanel: PropTypes.object.isRequired,
  setDiagramPanel: PropTypes.func.isRequired
};
