import Overlay from "ol/Overlay";
import appState from "./appState";
import { removeVectorLayers } from "./layerUtil";
import { removeParamFromUrl } from "./urlUtil";
const popupUtil = {
  model: {
    popupContainer: null,
    popupCloser: null,
    popupContent: null,
    overlay: null
  },
  controller: {
    init: () => {
      popupUtil.controller.createPopupContainer();
      popupUtil.controller.registerPopupEvents(popupUtil.model.popupCloser);
    },
    getPopupContainer: () => popupUtil.model.popupContainer,
    setPopupContent: content =>
      (popupUtil.model.popupContent.innerHTML = content),
    createPopupContainer: () => {
      popupUtil.model.popupContainer = document.createElement("div");
      popupUtil.model.popupContainer.id = "popup";
      popupUtil.model.popupContainer.className = "ol-popup";
      popupUtil.model.popupCloser = document.createElement("span");
      popupUtil.model.popupCloser.className = "ol-popup-closer";
      popupUtil.model.popupContent = document.createElement("div");
      popupUtil.model.popupContent.id = "popup-content";
      popupUtil.model.popupContent.innerHTML = "Hello from the connnntent";
      popupUtil.model.popupContainer.appendChild(popupUtil.model.popupCloser);
      popupUtil.model.popupContainer.appendChild(popupUtil.model.popupContent);
    },
    getOverlay: () => {
      return popupUtil.model.overlay;
    },
    setOverlay: overlay => (popupUtil.model.overlay = overlay),
    registerPopupEvents: closeIcon => {
      closeIcon.onclick = function (e) {
        e.preventDefault();
        removeVectorLayers({
          map: appState.olMap,
          removeType: "featureInfo_search"
        });
        removeParamFromUrl("search");
        popupUtil.model.overlay.setPosition(undefined);
        closeIcon.blur();
        return false;
      };
    }
  },
  view: {
    displayPopup: ({ content, position } = {}) => {
      if (!content || !position) {
        return false;
      }
      if (!popupUtil.controller.getPopupContainer()) {
        popupUtil.controller.init();
      }
      if (!popupUtil.controller.getOverlay()) {
        popupUtil.controller.setOverlay(
          new Overlay({
            element: popupUtil.controller.getPopupContainer()
          })
        );
      }
      popupUtil.controller.setPopupContent(content);
      popupUtil.controller.getOverlay().setPosition(position);
      appState.olMap.addOverlay(popupUtil.controller.getOverlay());
    },
    getContent: geojson => {
      const properties = Array.isArray(geojson.features)
        ? geojson.features[0].properties
        : geojson.properties;
      const type = properties.groupname;
      switch (type) {
        case "Gemeinde":
          return `Gemeinde ${properties.searchterm}`;
        case "Flurnamen":
          return `Flurname: <br />${properties.searchterm}`;
        case "Adressen":
          return `Adresse: <br />${properties.searchterm}`;
        case "Gewaessernamen":
          return `Gewässername: <br />${properties.searchterm}`;
        case "Liegenschaften":
        case "Projektierte Liegenschaften":
        case "Selbstaendige Rechte":
          return `<h3>${
            properties.groupname
          } Info</h3>${popupUtil.view.getLiegenschaftenTable(properties)}`;
        case "coordinate":
          return `<h3>Koordinate</h3>${properties.Koordinate}`;
        default:
          return "hello I'm the popup";
      }
    },
    getLiegenschaftenTable: properties => {
      return `<table class="attributes__table" size="small" border="1">
      <tbody>
              <tr class="attributes__tablerow">
                <td class="attributes__tablecell-grey">Nummer</td>
                <td class="attributes__tablecell">
                  ${properties.nummer}
                </td>
              </tr>
              <tr class="attributes__tablerow">
                <td class="attributes__tablecell-grey">Gemeinde</td>
                <td class="attributes__tablecell">
                  ${properties.gemeinde}
                </td>
              </tr>
              <tr class="attributes__tablerow">
                <td class="attributes__tablecell-grey">Eigentümer</td>
                <td class="attributes__tablecell">
                  <a href="${
                    properties.terrintra
                  }" target="_blank" rel="noopener noreferrer">Link Grundbuch</a>
                </td>
              </tr>
              <tr class="attributes__tablerow">
                <td class="attributes__tablecell-grey">Fläche</td>
                <td class="attributes__tablecell">
                  ${parseFloat(properties.flaechenmass).toLocaleString(
                    "de-CH"
                  )} m<sup>2</sup>
                </td>
              </tr>
              <tr class="attributes__tablerow">
                <td colSpan="2" class="attributes__tablecell">
                  <a href="https://oereb.ur.ch/?basemap=AV&egrid=CH390777684674" target="_blank" rel="noopener noreferrer">Link ÖREB-Kataster</a>
                </td>
              </tr>
      </tbody>
    </table>`;
    }
  }
};

export default popupUtil;
