import React from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import LockIcon from "@material-ui/icons/Lock";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddtoPhotosIcon from "@material-ui/icons/AddToPhotos";
import IconButton from "@material-ui/core/IconButton";
import LayerInfos from "../dialog/LayerInfos";
import {
  getLayerInfo,
  addLisagLayerPreview,
  layerInTOC,
  addExternalLayerPreview,
  checkPermission,
  removeLayer
} from "../../utils/layerUtil";
import { processSuggestion } from "../../utils/searchUtil";
import { addLayerToCart } from "../../utils/geoshopUtil";
import appState from "../../utils/appState";

const styles = {
  externalWmsName: {
    alignItems: "center",
    cursor: "default",
    fontSize: "12px",
    padding: "8px 0 0 0",
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
};
const GeodatenkatalogLayer = ({
  visibleLayers,
  layer,
  addToCart,
  setModal,
  setLayer,
  setMenuItems
}) => {
  const backgroundColors = {
    "Grundlagen und Planung": "#8da0cb",
    "Natur und Umwelt": "#66c2a5",
    "Bevölkerung und Wirtschaft": "#fc8d62"
  };
  if (!layer) {
    return <div>Keine Layer vorhanden...</div>;
  }
  // normalize some props between internal and external layers.
  const serviceName = layer.extern
    ? layer.serviceName
    : `${layer.namespace_geoserver}:${layer.service_name_geoserver}`;
  if (layer.extern) {
    const length = layer.onlineResource.length;
    const lastChar = layer.onlineResource[length - 1];
    if (lastChar !== "?" && lastChar !== "&") {
      layer.onlineResource = `${layer.onlineResource}?`;
    }
  }
  const loaded =
    layerInTOC({
      layer: {
        serviceName
      },
      layers: visibleLayers
    }) === true
      ? true
      : false;
  return (
    <Card
      title={layer.name_offiziell}
      className="layercard"
      onMouseEnter={() => {
        /* if there is already a preview layer,
        remove it before adding a new one. */
        if (appState.previewLayer) {
          appState.olMap.removeLayer(appState.previewLayer);
        }
        // exclude secured or messdaten layers from preview.
        if (layer.secured || serviceName.indexOf(":messdaten_") !== -1) {
          return;
        }
        if (layer.extern) {
          appState.previewLayer = addExternalLayerPreview(layer);
          return;
        }
        appState.previewLayer = addLisagLayerPreview({
          ns: layer.namespace_geoserver,
          serviceName,
          opacity: 1
        });
      }}
      onMouseLeave={() => {
        appState.olMap.removeLayer(appState.previewLayer);
      }}
    >
      <CardHeader
        style={{
          backgroundColor:
            backgroundColors[layer.geodaten_kategorie] || "gainsboro",
          cursor: "default",
          padding: "8px"
        }}
        title={layer.name_offiziell}
        titleTypographyProps={{
          style: {
            fontSize: "13px"
          }
        }}
      ></CardHeader>
      <CardActions style={{ padding: "4px" }}>
        <div className="layeractions">
          {layer.extern && (
            <div style={styles.externalWmsName} title={layer.attribution}>
              {layer.attribution}
            </div>
          )}
          <span className="layeractions__item">
            <IconButton
              color="primary"
              title={"Layer Infos"}
              size="small"
              onClick={async () => {
                let layerMetadata = {};
                appState.olMap.removeLayer(appState.previewLayer);
                if (layer.extern) {
                  layerMetadata.legende = `${layer.legende}`;
                  layerMetadata.name_offiziell = layer.name_offiziell;
                  layerMetadata.kurzbeschrieb = layer.abstract;
                } else {
                  layerMetadata = await getLayerInfo(layer.name_offiziell);
                }
                setModal({
                  type: "layerinfo",
                  layer,
                  content: <LayerInfos metadata={layerMetadata} />
                });
              }}
            >
              <InfoIcon />
            </IconButton>
          </span>
          <span className="layeractions__item">
            {layer.secured && (
              <IconButton
                color="primary"
                title={"Geschützter Datensatz, benötigt Passwort"}
                size="small"
                disableFocusRipple
                disableRipple
              >
                <LockIcon />
              </IconButton>
            )}
          </span>
          <span className="layeractions__item">
            {!layer.extern && layer.geoshop_user_id !== 0 && (
              <IconButton
                color="primary"
                title={"Geodaten bestellen"}
                size="small"
                onClick={async event => {
                  appState.olMap.removeLayer(appState.previewLayer);
                  if (layer.secured) {
                    const response = await checkPermission({
                      serviceName: `${layer.namespace_geoserver}:${layer.service_name_geoserver}`
                    });
                    if (response.ok) {
                      await addLayerToCart({ setModal, layer, addToCart });
                    } else {
                      const suggestion = { ...layer, groupname: "Geodaten" };
                      /* processSuggestion is an existing function that will handle the
                         authentification, but will also add the layer to the map. */
                      const enhancedLayer = await processSuggestion({
                        suggestion,
                        setLayer,
                        layers: visibleLayers,
                        setModal,
                        setMenuItems
                      });
                      // if we have a layer, authentification was successfull.
                      if (enhancedLayer) {
                        await addLayerToCart({ setModal, layer, addToCart });
                        /* because processSuggestion will add the layer to the map,
                           we have to remove it here. */
                        removeLayer(enhancedLayer, setLayer);
                      }
                    }
                  } else {
                    await addLayerToCart({ setModal, layer, addToCart });
                  }
                }}
              >
                <AddShoppingCartIcon />
              </IconButton>
            )}
          </span>
          <span className="layeractions__item">
            <IconButton
              color="primary"
              size="small"
              onClick={event => {
                appState.olMap.removeLayer(appState.previewLayer);
                const suggestion = { ...layer, groupname: "Geodaten" };
                processSuggestion({
                  suggestion,
                  setLayer,
                  layers: visibleLayers,
                  setModal,
                  setMenuItems
                });
              }}
              title={
                loaded
                  ? "Der Layer wurde bereits geladen"
                  : "Layer der Karte hinzufügen"
              }
            >
              {loaded === true && <CheckCircleIcon />}
              {loaded === false && <AddtoPhotosIcon />}
            </IconButton>
          </span>
        </div>
      </CardActions>
    </Card>
  );
};

export default GeodatenkatalogLayer;

GeodatenkatalogLayer.propTypes = {
  visibleLayers: PropTypes.array.isRequired,
  addToCart: PropTypes.func.isRequired,
  layer: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setLayer: PropTypes.func.isRequired,
  setMenuItems: PropTypes.func.isRequired
};
