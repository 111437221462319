import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Typography,
  ImageList,
  ImageListItem
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DrawingTool from "../../utils/drawingUtil";
import { geometryButtons } from "../../utils/toolsUtil";
import appState from "../../utils/appState";
import Colors from "./Colors";
import GeometryButtonGroup from "./GeometryButtonGroup";
import "./Tools.css";

const Drawing = ({ layers, setLayer }) => {
  const [color, setColor] = useState("#000000");
  const [activeButton, setActiveButton] = useState({});
  const [fontSize, setFontSize] = useState("20px sans-serif");
  useEffect(() => {
    if (appState.drawingTool) {
      return;
    } else {
      appState.drawingTool = new DrawingTool(
        appState.olMap,
        setActiveButton,
        layers,
        setLayer
      );
    }
  }, []);

  return (
    <section style={{ padding: "12px 0 0 0" }}>
      <ImageList rowHeight="auto" cols={3}>
        <ImageListItem style={{ width: "20%" }}>
          <Typography variant="caption">Punkt</Typography>
        </ImageListItem>
        <ImageListItem style={{ width: "40%" }}>
          <Typography variant="caption">Linien</Typography>
        </ImageListItem>
        <ImageListItem style={{ width: "40%" }}>
          <Typography variant="caption">Flächen</Typography>
        </ImageListItem>

        <ImageListItem style={{ width: "20%" }}>
          <GeometryButtonGroup
            buttons={geometryButtons.point}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          />
        </ImageListItem>
        <ImageListItem style={{ width: "40%" }}>
          <GeometryButtonGroup
            buttons={geometryButtons.lines}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          />
        </ImageListItem>
        <ImageListItem style={{ width: "40%" }}>
          <GeometryButtonGroup
            buttons={geometryButtons.polygons}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          />
        </ImageListItem>
      </ImageList>
      <ImageList rowHeight="auto" cols={3} style={{ paddingTop: "8px" }}>
        <ImageListItem style={{ width: "20%" }}>
          <Typography variant="caption">Text</Typography>
        </ImageListItem>
        <ImageListItem style={{ width: "40%" }}>
          <Typography variant="caption">Textgrösse</Typography>
        </ImageListItem>
        <ImageListItem style={{ width: "40%" }}>
          <Typography variant="caption">Selektieren</Typography>
        </ImageListItem>
        <ImageListItem style={{ width: "20%" }}>
          <GeometryButtonGroup
            buttons={geometryButtons.text}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          />
        </ImageListItem>
        <ImageListItem style={{ width: "40%" }}>
          <select
            className="input__fontsize"
            onChange={e => {
              appState.drawingTool.setFontSize(e.target.value);
              setFontSize(e.target.value);
            }}
            value={fontSize}
          >
            <option value="14px sans-serif">winzig (14px)</option>
            <option value="20px sans-serif">klein (20px)</option>
            <option value="30px sans-serif">mittel (30px)</option>
            <option value="50px sans-serif">gross (50px)</option>
            <option value="80px sans-serif">riesig (80px)</option>
          </select>
        </ImageListItem>
        <ImageListItem style={{ width: "40%" }}>
          <GeometryButtonGroup
            buttons={geometryButtons.select}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          />
        </ImageListItem>
      </ImageList>
      <div style={{ height: "8px" }}></div>
      <Colors selectedColor={color} setColor={setColor} />
      <div style={{ height: "20px" }}></div>
      <ButtonGroup fullWidth aria-label="Messen beenden Buttons">
        <Button
          size="small"
          title="Geometrien löschen / Messen beenden"
          onClick={() => {
            appState.drawingTool.removeInteraction();
            appState.drawingTool.deleteDrawings();
            appState.setSnackbar({ open: false, message: "" });
            setActiveButton({});
          }}
        >
          <DeleteForeverIcon />
          alle löschen
        </Button>

        <Button
          size="small"
          title="Messen beenden"
          onClick={() => {
            appState.drawingTool.removeInteraction();
            appState.setSnackbar({ open: false, message: "" });
            setActiveButton({});
          }}
        >
          beenden
        </Button>
      </ButtonGroup>
    </section>
  );
};
export default Drawing;

Drawing.propTypes = {
  setLayer: PropTypes.func.isRequired,
  layers: PropTypes.array.isRequired
};
