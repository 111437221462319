import { WFS_BASE_URL, ALL_MESSDATEN } from "./ENV";
/*
 * perform getFeatureInfo request to geoserver for all the
 * visible layers on the openlayers map.
 * @param {object} event - the click event fired when the ol map get's clicked.
 * @param {object} map - openlayers map.
 * @returns {array} results - geojson objects of the request results.
 */
export const getOlFeatureInfo = async (event, map) => {
  const resolution = map.getView().getResolution();
  const projection = map.getView().getProjection();
  const layers = map.getLayers().getArray();
  // remove basemap and non visible layers
  const queryLayers = layers.filter(
    (layer, index) =>
      layer.getVisible() === true &&
      index !== 0 &&
      layer.getSource().getFeatureInfoUrl
  );
  queryLayers.sort(sortByZindex);
  return Promise.all(
    queryLayers.map(layer => {
      const url = layer
        .getSource()
        .getFeatureInfoUrl(event.coordinate, resolution, projection, {
          INFO_FORMAT:
            layer.serverType === "geoserver"
              ? "application/json"
              : "text/plain",
          FEATURE_COUNT: 50
        });
      return getAttributeInfos({ name: layer.name, url });
    })
  );
};

const sortByZindex = (element1, element2) => {
  const zIndex1 = element1.getZIndex();
  const zIndex2 = element2.getZIndex();
  if (zIndex1 < zIndex2) {
    return 1;
  }
  if (zIndex1 === zIndex2) {
    return 0;
  }
  if (zIndex1 > zIndex2) {
    return -1;
  }
};

const getAttributeInfos = ({ name, url }) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => response.text())
      .then(text =>
        resolve(normalizeFeatureInfos({ layername: name, response: text }))
      )
      .catch(error => reject(error));
  });
};

/*
 *  Requests a wfs feature based on a algolia search result.
 * @param {object} params object with function parameters
 * @param {string} params.srs - the spatial reference system for the wfs result geometry.
 * @param {string} params.groupname - algolia searchresult groupname
 * @param {string} params.searchterm - algolia searchresult searchterm
 * @returns {object} response or string if function params are missing.
 */
export const getWfsResult = async ({ srs, groupname, searchterm } = {}) => {
  if (!groupname || !searchterm) {
    return "missing function parameters";
  } else {
    const wfsRequestParams = {
      srs,
      layer: "suche:t11_gemeindegrenze_ims_diss",
      filterattribute: "searchterm",
      searchterm
    };
    switch (groupname) {
      case "Gemeinde":
        return await requestWfs(
          createWfsUrl({
            ...wfsRequestParams,
            layer: "suche:t11_gemeindegrenze_ims_diss"
          })
        );
      case "Flurnamen":
        return await requestWfs(
          createWfsUrl({
            ...wfsRequestParams,
            layer: "suche:t07_flurname"
          })
        );
      case "Adressen":
        return await requestWfs(
          createWfsUrl({
            ...wfsRequestParams,
            layer: "suche:adressen"
          })
        );
      case "Gewaessernamen":
        return await requestWfs(
          createWfsUrl({
            ...wfsRequestParams,
            layer: "suche:gn10_uri_routen_mit_gebieten"
          })
        );
      case "LWNutzungsflaechen":
        return await requestWfs(
          createWfsUrl({
            ...wfsRequestParams,
            layer: "suche:ch153_1_nutzungsflaechen"
          })
        );
      case "Liegenschaften":
      case "Projektierte Liegenschaften":
      case "Selbstaendige Rechte":
        return await requestWfs(
          createWfsUrl({
            ...wfsRequestParams,
            layer: "suche:all_egrid_data"
          })
        );

      default:
        return {};
    }
  }
};

/*
 * performs a wfs request to a server
 * @param {string} url - wfs request url
 * @returns {object} a geoJson object or null in case of a failure
 */
const requestWfs = async url => {
  if (!url) {
    return null;
  }
  try {
    const response = await fetch(url);
    const json = await response.json();
    return json;
  } catch (error) {
    alert(`Fehler beim Anfordern eines WFS Features: ${error}`);
    return null;
  }
};

/*
 * creates the url for a wfs request based on a few parameters
 * @param {object} params object with function parameters
 * @param {string} params.srs - spatial reference system for the request e.g. EPSG:3857 or EPSG:4326
 * @param {string} params.layer - namespace and layername as stored in geoserver, separated by a colon (ns:layername)
 * @param {string} params.filterattribute - the attribute name to use for the filter
 * @param {string} params.serachterm - the attribute value to search for
 */
export const createWfsUrl = ({
  srs = "EPSG:4326",
  layer,
  filterattribute,
  searchterm
} = {}) => {
  if (!layer || !filterattribute || !searchterm) {
    return "missing function parameters";
  }
  return `${WFS_BASE_URL}&srsName=${srs}&typename=${layer}&CQL_FILTER=${filterattribute}=%27${searchterm}%27&outputFormat=application/json`;
};

/*
 * infos from a getFeatureInfo request can come in various forms.
 * this function normalises the request response to be able to display it in the info box.
 * @param {array} featureInfos - request responses like returned from getOlFeatureInfo.
 * @return {array} - normalized request responses or false in case of failure.
 */
export const normalizeFeatureInfos = (featureInfo = null) => {
  if (!featureInfo) {
    return false;
  }
  try {
    // geojson objects
    const features = JSON.parse(featureInfo.response).features;
    const result = features.map(feature => {
      return { ...feature, layername: featureInfo.layername, id: feature.id };
    });
    return result;
  } catch (error) {
    return getAttributeObject(featureInfo);
  }
};
/*
 * translates a string response from a getFeatureInfo Request to a js object.
 * @param {object} params - function parameter object.
 * @param {string} layername - name of the layer.
 * @param {string} response - the getFeatureInfo response.
 * @returns {array} - array containing an object with id (layername) and properties or false in case when no attributes are available.
 */
export const getAttributeObject = ({ layername, response }) => {
  const attributes = response.split("\n");
  const notEmpty = attributes.filter(attribute => {
    if (
      attribute.trim() !== "" &&
      attribute.toLowerCase().indexOf("getfeatureinfo") === -1 &&
      attribute.toLowerCase().indexOf("<")
    ) {
      return attribute;
    }
    return false;
  });
  return [
    {
      id: layername || "Layername unbekannt",
      layername,
      properties: notEmpty.map(item => item.trim())
    }
  ];
};

/*
 * query all dynamic measured data from the kiwis service.
 * @returns {geojson} - geojson FeatureCollection
 */
export const getMessdaten = async () => {
  try {
    return await fetch(ALL_MESSDATEN).then(response => response.json());
  } catch (error) {
    return error;
  }
};
