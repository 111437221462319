import React, { useState } from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Collapse from "@material-ui/core/Collapse";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import GeoshopLayerConfig from "./GeoshopLayerConfig";
const styles = {
  cartItem: {
    fontSize: "11px",
    wordBreak: "break-all"
  }
};
const CartItem = ({ product, removeItem, updatePerimeterButtons, index }) => {
  const [geoshopConfig, setGeoshopConfig] = useState(true);
  const borderTop = index > 0 ? "1px solid black" : "none";
  return (
    <section>
      <ListItem style={{ backgroundColor: "#dcdcdc", borderTop }}>
        <IconButton
          edge="start"
          aria-label="delete"
          onClick={() => removeItem()}
        >
          <DeleteIcon />
        </IconButton>

        <ListItemText
          style={styles.cartItem}
          primary={product.currentProduct.split("-")[0]}
        />
        <ListItemSecondaryAction
          onClick={() => {
            setGeoshopConfig(!geoshopConfig);
          }}
        >
          <IconButton edge="end" aria-label="delete">
            {geoshopConfig && <ExpandLessIcon />}
            {!geoshopConfig && <ExpandMoreIcon />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={geoshopConfig}>
        <GeoshopLayerConfig
          layer={product}
          setGeoshopConfig={setGeoshopConfig}
          updatePerimeterButtons={updatePerimeterButtons}
        />
      </Collapse>
    </section>
  );
};

export default CartItem;
CartItem.propTypes = {
  product: PropTypes.object.isRequired,
  removeItem: PropTypes.func.isRequired,
  updatePerimeterButtons: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};
