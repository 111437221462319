import React from "react";
import PropTypes from "prop-types";

import { DialogContentText, Button } from "@material-ui/core";

const NoPermission = ({ layer, showAuth }) => {
  return (
    <DialogContentText>
      <span style={{ fontSize: "20px", margin: "0 0 12px 0" }}>
        Geschätzter Benutzer
      </span>
      <br />
      Mit dem Benutzernamen mit dem Sie momentan angemeldet sind, haben Sie{" "}
      <strong>keinen Zugriff</strong> auf den Layer{" "}
      <strong>{layer.name}.</strong>
      <br />
      Sie können sich entweder mit einem neuen Benutzer/Passwort anmelden, oder
      die Anfrage abbrechen. <br />
      Aus Sicherheitsgründen wurden{" "}
      <strong>alle geschützten Layer aus dem Viewer entfernt.</strong>
      <br />
      <Button
        variant="contained"
        onClick={showAuth}
        style={{ margin: "16px 0 0 0" }}
      >
        Neu anmelden
      </Button>
    </DialogContentText>
  );
};
NoPermission.propTypes = {
  layer: PropTypes.object.isRequired,
  showAuth: PropTypes.func.isRequired
};
export default NoPermission;
