import WMTSCapabilities from "ol/format/WMTSCapabilities";
import WMSCapabilities from "ol/format/WMSCapabilities";
const wmtsParser = new WMTSCapabilities();
const wmsParser = new WMSCapabilities();

/*
 * get layer infos from wms getCapabilities url
 * @param {object} params - parameter object.
 * @param {string} params.type - the type of service (wms/wmts) to request. defaults to wmts
 * @param {string} params.url - the url to the capabilities document.
 * @returns {object} - wms properties as a javascript object.
 */
export const getCapabilitiesInfo = async ({ type = "wmts", url } = {}) => {
  const parser = type === "wmts" ? wmtsParser : wmsParser;
  return new Promise(async (resolve, reject) => {
    if (!url) {
      reject("Bitte geben Sie eine Url ein.");
    }
    try {
      const response = await fetch(url);
      const text = await response.text();
      resolve(parser.read(text));
    } catch (error) {
      reject(
        "🤔 Diese URL zeigt nicht auf ein WMS Capabilities Dokument. Bitte versuchen sie es nochmal."
      );
    }
  });
};
