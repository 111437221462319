import { svCoverage } from "../components/basemaps/basemapDefinitions";
const appState = {
  olMap: null,
  cesiumViewer: null,
  shoppingCart: {
    cart: [],
    extent: [],
    email: "",
    customerType: ""
  },
  cancelDrawing: null,
  geodatenkatalog: [],
  svCoverage,
  gps: false,
  streetSmartActive: false
};
export default appState;
