import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button, TextField, LinearProgress } from "@mui/material";

const Authenticate = props => {
  const { authenticate } = props;
  const [username, setUsername] = useState("");
  const [pw, setPw] = useState("");
  const [progress, setProgress] = useState(false);
  const [error, setError] = useState(false);
  return (
    <form sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
      {props.children}
      <TextField
        error={error}
        sx={{ marginBottom: "4px" }}
        autoFocus
        margin="dense"
        id="username"
        label="Benutzername"
        type="text"
        value={username}
        onChange={event => setUsername(event.target.value)}
      />
      <TextField
        error={error}
        margin="dense"
        id="password"
        label="Passwort"
        type="password"
        value={pw}
        onChange={event => setPw(event.target.value)}
      />

      <Button
        sx={{ margin: "28px 0 12px 0" }}
        color="primary"
        onClick={async () => {
          try {
            setProgress(true);
            await authenticate(username, pw);
            setProgress(false);
            setError(false);
          } catch (error) {
            console.error(error);
            setProgress(false);
            setError(true);
          }
        }}
        variant="outlined"
      >
        Login
      </Button>
      {progress && <LinearProgress />}
    </form>
  );
};
Authenticate.propTypes = {
  authenticate: PropTypes.func.isRequired
};
export default Authenticate;
