export const modalReducer = (state, action) => {
  switch (action.type) {
    case "layerinfo":
    case "login":
      return {
        ...state,
        type: action.type,
        layer: action.layer,
        content: action.content,
        reject: action.reject
      };
    case "forbidden":
      return {
        ...state,
        type: action.type,
        layer: action.layer,
        content: action.content,
        keypress: action.keypress
      };
    case "info":
    case "faq":
    case "news":
      return {
        ...state,
        type: action.type,
        content: action.content
      };
    default:
      return { type: "", content: null, layer: { name: "" } };
  }
};
