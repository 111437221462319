import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Collapse } from "@material-ui/core";

const ButtonExpander = ({
  children,
  title,
  margin = "8px",
  onclick = null,
  expanded = false
}) => {
  const [open, setOpen] = useState(expanded);
  return (
    <div style={{ marginTop: margin }}>
      <Button
        color="secondary"
        fullWidth={true}
        variant="outlined"
        onClick={() => {
          setOpen(!open);
          if (onclick) {
            onclick();
          }
        }}
      >
        {title}
      </Button>
      <Collapse in={open}>{children}</Collapse>
    </div>
  );
};

export default ButtonExpander;

ButtonExpander.propTypes = {
  onclick: PropTypes.func,
  children: PropTypes.object.isRequired,
  margin: PropTypes.string,
  title: PropTypes.string.isRequired
};
