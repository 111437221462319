import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
const closeModalConfig = {
  type: ""
};
const styles = theme => ({
  dialogTitle: {
    backgroundColor: "#c3c3c3",
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900]
  }
});
const GeourDialog = ({
  open,
  setOpen,
  title,
  content,
  classes,
  keypress,
  reject,
  fullWidth = false
}) => {
  const closeModal = () => {
    setOpen(closeModalConfig);
    // reject the promise for the add secure layer
    // if the login modal gets closed.
    if (typeof reject !== "undefined") {
      reject();
    }
  };
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={fullWidth === true ? "lg" : "sm"}
      open={open}
      aria-labelledby="dialog-title"
      onKeyPress={event => {
        if (keypress && event.key === "Enter") {
          keypress();
        }
      }}
    >
      <DialogTitle
        disableTypography
        id="dialog-title"
        className={classes.dialogTitle}
      >
        <Typography variant="h5">{title}</Typography>
        <IconButton
          aria-label="Close"
          onClick={closeModal}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Schliessen</Button>
      </DialogActions>
    </Dialog>
  );
};
GeourDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.object,
  keypress: PropTypes.func
};
export default withStyles(styles)(GeourDialog);
