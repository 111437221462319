import React from "react";
import { Menu, MenuItem } from "@mui/material";
import sxMenuItem from "./sxMenuItem";
const LinksMenu = ({ linkAnchor, isLinkMenuOpen, handleMenuClose }) => {
  return (
    <Menu
      anchorEl={linkAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isLinkMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{ sx: { backgroundColor: "primary.main", color: "white" } }}
    >
      <MenuItem
        onClick={handleMenuClose}
        component="a"
        href="https://oereb.ur.ch"
        target="_blank"
        sx={sxMenuItem}
      >
        ÖREB-Kataster
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component="a"
        href="https://lisag.ch"
        target="_blank"
        sx={sxMenuItem}
      >
        Lisag AG
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        component="a"
        href="https://oereb.ur.ch/auflage"
        target="_blank"
        sx={sxMenuItem}
      >
        APO
      </MenuItem>
    </Menu>
  );
};

export default LinksMenu;
