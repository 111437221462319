import React, { useEffect, useReducer, useState } from "react";
import AppHeader from "../header/AppHeader";
import IconMenu from "../menu/IconMenu";
import InfoBox from "../infoBox/InfoBox";
import { Machine } from "xstate";
import { useMachine } from "../../utils/useMachine";
import { basemapState, basemapActions } from "../basemaps/basemapState";
import GeourDialog from "../dialog/GeourDialog";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Print,
  MenuBook,
  ShoppingCart,
  Build,
  Toc,
  Layers,
  Streetview,
  Close
} from "@mui/icons-material";
import { Snackbar, IconButton } from "@mui/material";
import { mapState } from "./mapState";
import { parseSearchParams } from "../../utils/urlUtil";
import { initStreetview } from "../../utils/streetviewUtil";
import { addDnD } from "../../utils/dndUtil";
import {
  addLayersFromSearchParams,
  removeSearchOverlays,
  createCesiumLayer,
  addDrawingGeojson
} from "../../utils/layerUtil";
import { processSuggestion } from "../../utils/searchUtil";
import { hideStreetSmart } from "../../utils/toolsUtil";
import {
  removeSidebars3D,
  removeDrawingInteractions,
  openToc
} from "../../utils/sidebarUtil";
import GeodatenkatalogSidebar from "../sidebar/GeodatenkatalogSidebar";
import WarenkorbSidebar from "../sidebar/WarenkorbSidebar";
import PrintSidebar from "../sidebar/PrintSidebar";
import ToolsSidebar from "../sidebar/ToolsSidebar";
import LayerSidebar from "../sidebar/LayerSidebar";
import BasemapSidebar from "../sidebar/BasemapSidebar";
import StreetViewSidebar from "../sidebar/StreetviewSidebar";
import StreetSmartSidebar from "../sidebar/StreetSmartSidebar";
import {
  setCesiumWindow,
  loadCesiumLibrary,
  loadCesiumViewer
} from "../cesiumMap/createCesiumViewer";
import { layerReducer } from "./layerReducer";
import { modalReducer } from "./modalReducer";
import { renderOlMap, getViewParams } from "../olMap/renderOlMap";
import appState from "../../utils/appState";
import "./App.css";
import "ol/ol.css";
import "../olMap/olMap.css";
import DiagramSidebar from "../sidebar/DiagramSidebar";

//the state machine for the map (2D/3D)
const mapMachine = Machine(mapState);
const theme = createTheme({
  palette: {
    primary: { dark: "#212121", main: "#424242", light: "#757575" },
    secondary: { main: "#fbc02d" }
  }
});
const initializePopState = () => {
  const historyLength = window.history.length;
  // open the site which was open previously of geo.ur
  window.onpopstate = event => {
    window.history.go(-(historyLength - 1));
  };
};
// Start App Component
const App = () => {
  const basemapMachine = Machine(basemapState, basemapActions);
  const [currentBasemap, sendBasemapMachine] = useMachine(basemapMachine);
  const [mapType, sendMapMachine] = useMachine(mapMachine); // 2D vs. 3D map
  const [layers, setLayer] = useReducer(layerReducer, []);
  const [shoppingCart, setShoppingCart] = useState([]);
  const [modal, setModal] = useReducer(modalReducer, {
    type: "",
    layer: { name: "" },
    content: null
  });
  // open/close the menu
  const [menuVisible, setMenu] = useState(true);
  // open/close the layerpanel
  const [layerPanelOpen, setLayerPanel] = useState(true);
  // open/close the diagram panel
  const [diagramPanel, setDiagramPanel] = useState({ open: false });
  // open/close infoBox
  const [featureInfos, setFeatureInfos] = useState([]);
  const [svCoords, setSvCoords] = useState([]);
  appState.layers = layers;
  appState.svCoords = svCoords;
  appState.setSvCoords = setSvCoords;
  //open/close snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "Klicken um mit dem Zeichnen zu beginnen."
  });
  appState.snackbar = snackbar;
  appState.setSnackbar = setSnackbar;
  appState.setDiagramPanel = setDiagramPanel;

  /*
   *menuItems state
   */
  const [menuItems, setMenuItems] = useState([
    { label: "Geladene Layer", icon: <Toc />, visible: false, zIndex: 1 },
    { label: "Basiskarten", icon: <Layers />, visible: false, zIndex: 0 },
    {
      label: "Geodatenkatalog",
      icon: <MenuBook />,
      visible: false,
      zIndex: 0
    },
    {
      label: "Warenkorb",
      icon: <ShoppingCart />,
      visible: false,
      zIndex: 0
    },
    { label: "Drucken", icon: <Print />, visible: false, zIndex: 0 },
    { label: "Tools", icon: <Build />, visible: false, zIndex: 0 },
    {
      label: "Street View",
      icon: <Streetview />,
      visible: false,
      zIndex: 0
    },
    {
      label: "Street Smart",
      icon: <Streetview />,
      visible: false,
      zIndex: 0
    }
  ]);

  useEffect(() => {
    console.log("app use effect called...");
    const searchParams = parseSearchParams(window.location.search);
    // handle back/forward button click
    initializePopState();
    // renderOlMap returns the OpenLayers map object, wich is assigned to the global app state.
    appState.olMap = renderOlMap({
      searchParams,
      setFeatureInfos
    });
    // Parse search params for basemaps and layers.
    if (searchParams.basemap) {
      sendBasemapMachine(searchParams.basemap);
    }
    if (searchParams.layers) {
      addLayersFromSearchParams({
        searchParams,
        layers,
        setLayer,
        setModal
      });
    }
    if (searchParams.search) {
      const suggestion = JSON.parse(searchParams.search);
      processSuggestion({
        suggestion,
        setLayer,
        layers,
        setModal,
        setFeatureInfos,
        mapType: mapType.value
      });
    }
    if (searchParams.drawings) {
      addDrawingGeojson({ drawings: searchParams.drawings, setLayer, layers });
    }
    if (searchParams.layersidebarvisible !== "false") {
      setMenuItems(oldState => openToc(oldState));
    }
    initStreetview();
    //enable dnd funtionality for kml and fotos
    addDnD({ setLayer });
  }, []);

  const switchMap = async () => {
    // remove any search geometries from the map and close the InfoBox.
    removeSearchOverlays(setFeatureInfos, mapType);
    removeDrawingInteractions();
    hideStreetSmart();
    setSnackbar({ open: false, message: "" });
    const searchParams = parseSearchParams(window.location.search);
    // we need to set center/zoom of the newly opened map
    if (mapType.value === "2D") {
      // if cesium is not loaded yet, load it.
      if (!appState.cesiumViewer) {
        await loadCesiumLibrary();
        loadCesiumViewer({ Cesium: window.Cesium, setFeatureInfos });
      }
      // add all loaded layers to cesium
      if (layers.length > 0) {
        var i = layers.length - 1;
        while (i >= 0) {
          // create new drawing layers every time because the json may have changed
          if (layers[i].name === "Zeichnung (aktiv)") {
            appState.cesiumViewer.dataSources.remove(layers[i].cesiumLayer);
            layers[i].cesiumLayer = await createCesiumLayer(layers[i]);
          }
          // create new wms layers only if they do not exist yet.
          else {
            if (!layers[i].cesiumLayer) {
              layers[i].cesiumLayer = await createCesiumLayer(layers[i]);
            }
          }
          i--;
        }
      }
      // check for gps
      if (appState.gps === true && !appState.positionDatasource) {
        appState.positioning.updateCesiumGps();
      }
      setCesiumWindow(window.Cesium, appState.cesiumViewer, searchParams);
      //remove any sidebars except geladene layer and street view.
      setMenuItems(removeSidebars3D(menuItems));
      setDiagramPanel(currentState => {
        return { ...currentState, open: false };
      });
    } else {
      const { center, zoom, rotation } = getViewParams(searchParams);
      appState.olMap.getView().animate({ center }, { zoom }, { rotation });
      // open the diagram panel if a diagram is available
      if (diagramPanel.data?.data?.length > 2) {
        setDiagramPanel(currentState => {
          return { ...currentState, open: true };
        });
      }
    }
    sendMapMachine("TOGGLE");
    return mapType.value;
  };
  /*
   * Adds a Geoshop product to the shopping cart.
   * @param {object} product - geoshop product with all the necessary options.
   * @return {array} shoppingCart - the shoppingCart with the added product, false in case the product is allready in the cart.
   */
  const addToCart = product => {
    // check if product is not allready in cart
    const productInCart = shoppingCart.filter(
      item => item.currentProduct === product.currentProduct
    );
    if (productInCart.length > 0) {
      console.warn("product allready in cart");
      return false;
    } else {
      const newCart = [...shoppingCart, product];
      setShoppingCart(newCart);
      return newCart;
    }
  };
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        {featureInfos.length > 0 && (
          <InfoBox
            setFeatureInfos={setFeatureInfos}
            featureInfos={featureInfos}
            mapType={mapType}
          />
        )}
        <AppHeader
          layers={layers}
          menuVisible={menuVisible}
          setMenu={setMenu}
          layerPanelOpen={layerPanelOpen}
          setLayerPanel={setLayerPanel}
          switchMap={switchMap}
          mapType={mapType.value}
          setLayer={setLayer}
          setModal={setModal}
          setFeatureInfos={setFeatureInfos}
          setMenuItems={setMenuItems}
        />
        <IconMenu
          items={menuItems}
          setItems={setMenuItems}
          mapType={mapType.value}
          shoppingCart={shoppingCart}
        />
        <LayerSidebar
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          layers={layers}
          setLayer={setLayer}
          setModal={setModal}
          addToCart={addToCart}
          setFeatureInfos={setFeatureInfos}
        />
        <GeodatenkatalogSidebar
          addToCart={addToCart}
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          layers={layers}
          shoppingCart={shoppingCart}
          setShoppingCart={setShoppingCart}
          setModal={setModal}
          setLayer={setLayer}
        />
        <WarenkorbSidebar
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          shoppingCart={shoppingCart}
          setShoppingCart={setShoppingCart}
        />
        <PrintSidebar menuItems={menuItems} setMenuItems={setMenuItems} />
        <ToolsSidebar
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          setLayer={setLayer}
          layers={layers}
        />

        <BasemapSidebar
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          sendBasemapMachine={sendBasemapMachine}
          currentBasemap={currentBasemap.value}
          setLayer={setLayer}
          layers={layers}
        />
        <StreetViewSidebar
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          svCoords={svCoords}
          mapType={mapType.value}
        />
        <StreetSmartSidebar
          menuItems={menuItems}
          setMenuItems={setMenuItems}
          mapType={mapType.value}
        />
        <DiagramSidebar
          setDiagramPanel={setDiagramPanel}
          diagramPanel={diagramPanel}
        />
        <main className="main">
          <div
            id="ol-map"
            style={{
              backgroundSize: "20px 20px",
              backgroundImage:
                "linear-gradient(to right, gainsboro 1px, transparent 1px), linear-gradient(to bottom, gainsboro 1px, transparent 1px)",
              visibility: mapType.value === "2D" ? "visible" : "hidden",
              zIndex: mapType.value === "2D" ? 0 : -10
            }}
          />
          <div
            id="cesium-map"
            style={{
              backgroundColor: "yellowgreen",
              visibility: mapType.value === "3D" ? "visible" : "hidden",
              zIndex: mapType.value === "3D" ? 0 : -10
            }}
          />
        </main>

        {/* Modal section */}
        <GeourDialog
          open={modal.type === "login"}
          setOpen={setModal}
          title={"Geschützter Layer"}
          content={modal.content}
          reject={modal.reject}
        />
        <GeourDialog
          open={modal.type === "layerinfo"}
          setOpen={setModal}
          title={"Layer Infos"}
          content={modal.content}
        />
        <GeourDialog
          open={modal.type === "forbidden"}
          setOpen={setModal}
          title={"Keine Berechtigung"}
          content={modal.content}
          keypress={modal.keypress}
        />
        <GeourDialog
          open={modal.type === "info"}
          setOpen={setModal}
          title={"Information"}
          content={modal.content}
        />
        <GeourDialog
          open={modal.type === "faq"}
          setOpen={setModal}
          title={"FAQ (Frequently asked questions)"}
          content={modal.content}
        />
        <GeourDialog
          open={modal.type === "news"}
          setOpen={setModal}
          title={"News"}
          content={modal.content}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={snackbar.open}
          message={snackbar.message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={(event, reason) => {
                  if (reason === "clickaway") {
                    return;
                  }
                  setSnackbar({ open: false, message: "" });
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </ThemeProvider>
    </div>
  );
};

export default App;
