/*
prevents the rotation from beeing negative and from beeing > 360 degrees
@param {number} rotation - rotation in degrees
@returns {number} normalized rotation
*/
export const normalizeOlRotation = rotation => {
  rotation =
    rotation > 360 || rotation < -360
      ? Math.ceil(rotation % 360)
      : Math.ceil(rotation);
  if (rotation <= 0) {
    return 360 - Math.abs(rotation);
  }
  if (rotation >= 0) {
    return rotation;
  }
};

/*
prevents the rotation from beeing negative and from beeing > 360 degrees
plus makes sure it is the same way around as in the OL viewer.
@param {number} rotation - rotation in degrees
@returns {number} normalized rotation
*/
export const normalizeCesiumRotation = cesiumRotation => {
  let rotation = Math.abs(cesiumRotation); //no negative rotations
  // 180 degrees is the same in ol and cesium
  if (rotation === 180) {
    return rotation;
  }
  // we don't want multiple rotation over the same axis because the view is the same anyway.
  rotation = rotation > 360 ? Math.ceil(rotation % 360) : Math.ceil(rotation);

  if (rotation >= 0 && rotation <= 360) {
    return Math.ceil(Math.abs(360 - rotation));
  } else {
    return 0;
  }
};
