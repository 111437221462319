import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import XYZ from "ol/source/XYZ";
import avfarbeImage from "./img/av.jpg";
import avswImage from "./img/av_sw.jpg";
import orthoImage from "./img/ortho.jpg";
import pkfarbeImage from "./img/pkfarbe.jpg";
import pkgrauImage from "./img/pkgrau.jpg";
import whiteImage from "./img/white.jpg";
import { GEOSERVER_OVERLAY } from "../../utils/ENV";

/*
 * Copyright Infos on the map
 */
export const attribution =
  "© Geodaten: <a href='https://www.swisstopo.admin.ch/de/home.html'>Swisstopo</a> | <a href='https://www.lisag.ch'>Lisag AG</a> | App: <a href='https://karten-werk.ch'>Karten-Werk";

/*
 * This section describes the basemap layer objects.
 * It can be any valid ol Layer member but make
 * sure it is performant e.g. tiled.
 */

/*
 * Orthofoto basemap from swisstopo
 */
export const orthoBasemap = new TileLayer({
  source: new XYZ({
    url:
      "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg",
    attributions: attribution
  }),
  zIndex: 0
});
orthoBasemap.backgroundImage = orthoImage;
orthoBasemap.active = true;
orthoBasemap.name = "Luftbild";
orthoBasemap.id = "luftbild";

/*
 * Pixelkarte farbig from Swisstopo
 */
export const pkFarbeBasemap = new TileLayer({
  source: new XYZ({
    url:
      "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg",
    attributions: attribution
  }),
  zIndex: 0
});
pkFarbeBasemap.backgroundImage = pkfarbeImage;
pkFarbeBasemap.active = false;
pkFarbeBasemap.name = "Karte farbig";
pkFarbeBasemap.id = "pkfarbe";

/*
 * Pixelkarte grau from Swisstopo
 */
export const pkGrauBasemap = new TileLayer({
  source: new XYZ({
    url:
      "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-grau/default/current/3857/{z}/{x}/{y}.jpeg",
    attributions: attribution
  }),
  zIndex: 0
});
pkGrauBasemap.backgroundImage = pkgrauImage;
pkGrauBasemap.active = false;
pkGrauBasemap.name = "Karte grau";
pkGrauBasemap.id = "pkgrau";

/*
 * AV farbig basemap
 */
export const avfarbigBasemap = new TileLayer({
  source: new TileWMS({
    attributions: attribution,
    url: `${GEOSERVER_OVERLAY}wms`,
    params: {
      LAYERS: "basemaps:basemap_av_farbe",
      FORMAT: "image/jpeg",
      SRS: "EPSG:3857",
      TILED: true
    },
    serverType: "geoserver"
  }),
  zIndex: 0
});
avfarbigBasemap.backgroundImage = avfarbeImage;
avfarbigBasemap.active = false;
avfarbigBasemap.name = "AV farbig";
avfarbigBasemap.id = "avfarbig";

/*
 * AV sw basemap
 */
export const avgrauBasemap = new TileLayer({
  source: new TileWMS({
    attributions: attribution,
    url: `${GEOSERVER_OVERLAY}wms`,
    params: {
      LAYERS: "basemaps:basemap_av_sw",
      FORMAT: "image/jpeg",
      SRS: "EPSG:3857",
      TILED: true
    },
    serverType: "geoserver"
  }),
  zIndex: 0
});
avgrauBasemap.backgroundImage = avswImage;
avgrauBasemap.active = false;
avgrauBasemap.name = "AV grau";
avgrauBasemap.id = "avgrau";

/*
 * white background basemap
 */
export const whiteBasemap = new TileLayer({
  source: new TileWMS({
    attributions: attribution,
    url: `${GEOSERVER_OVERLAY}wms`,
    params: {
      LAYERS: "basemaps:basemap_kantonsgrenzen_sw",
      FORMAT: "image/jpeg",
      SRS: "EPSG:3857",
      TILED: true
    },
    serverType: "geoserver"
  }),
  zIndex: 0
});
whiteBasemap.backgroundImage = whiteImage;
whiteBasemap.active = false;
whiteBasemap.name = "Weisse Karte";
whiteBasemap.id = "white";

/*
 * Google Street View coverage
 */
export const svCoverage = new TileLayer({
  source: new XYZ({
    url:
      "https://mts1.googleapis.com/vt?hl=en-US&lyrs=svv|cb_client:apiv3&style=40,18&x={x}&y={y}&z={z}",
    attributions: "| © Street View: <a href='https://www.google.ch'>Google</a>"
  }),
  zIndex: 100,
  visible: false
});
svCoverage.name = "Street View";

export const getZeitreiseLayer = layer => {
  const zeitreiseLayer = new TileLayer({
    source: new XYZ({
      url: `https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage-product/default/${layer.year}/3857/{z}/{x}/{y}.jpeg`
    }),
    visible: layer.visible
  });
  zeitreiseLayer.name = `Orthofoto Zeitreise ${layer.year}`;
  return zeitreiseLayer;
};
