import React from "react";
import PropTypes from "prop-types";
import Layer from "../layer/Layer";
import { Button, Typography } from "@material-ui/core";
import Sortable from "react-sortablejs";
import { getSidebarVisibilityState } from "../../utils/sidebarUtil";
import "./Sidebar.css";

const LayerSidebar = ({
  menuItems,
  setMenuItems,
  layers,
  setLayer,
  setModal,
  addToCart,
  setFeatureInfos
}) => {
  const menuItem = menuItems.filter(item => item.label === "Geladene Layer");
  const layerList = layers.map(layer => (
    <Layer
      data-id={layer}
      layer={layer}
      key={layer.name}
      setLayer={setLayer}
      setModal={setModal}
      addToCart={addToCart}
      setFeatureInfos={setFeatureInfos}
    />
  ));

  return (
    <div
      className={menuItem[0].visible ? "sidebar" : "sidebar sidebar--collapsed"}
      style={{ top: "102px", left: 0, zIndex: menuItem[0].zIndex }}
    >
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => {
          setMenuItems(oldState =>
            getSidebarVisibilityState(oldState, menuItem[0].label)
          );
        }}
      >
        schliessen
      </Button>
      <section className="sidebar__content">
        {" "}
        <div style={{ width: "100%" }}>
          <Typography variant="body1" gutterBottom>
            GELADENE LAYER
          </Typography>
          {layers.length === 0 && <span>Sie haben keine Layer geladen...</span>}
          <Sortable
            onChange={(order, sortable, event) => {
              setLayer({
                type: "reorder",
                oldIndex: event.oldIndex,
                newIndex: event.newIndex
              });
            }}
            options={{
              ghostClass: "ghost",
              handle: ".layer__handle"
            }}
          >
            {layerList}
          </Sortable>
        </div>
      </section>
    </div>
  );
};

export default LayerSidebar;

LayerSidebar.propTypes = {
  menuItems: PropTypes.array.isRequired,
  setMenuItems: PropTypes.func.isRequired,
  layers: PropTypes.array.isRequired,
  setLayer: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  setFeatureInfos: PropTypes.func.isRequired
};
