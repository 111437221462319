import React from "react";
import PropTypes from "prop-types";
import { processSuggestion } from "../../utils/searchUtil";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure, Index } from "react-instantsearch-dom";
import AutoComplete from "./Autocomplete";

const searchClient = algoliasearch(
  "EZA18O6PU6",
  "25369648380de086a5b522a94c0de9c5"
);
const Search = ({
  mapType,
  layers,
  setLayer,
  setModal,
  setFeatureInfos,
  setMenuItems
}) => {
  const onSuggestionSelected = async (event, { suggestion }) => {
    processSuggestion({
      suggestion,
      setLayer,
      layers,
      setModal,
      setFeatureInfos,
      mapType,
      setMenuItems
    });
  };

  return (
    <InstantSearch searchClient={searchClient} indexName="Geodaten">
      <Configure hitsPerPage={20} />
      <AutoComplete onSuggestionSelected={onSuggestionSelected} />
      <Index indexName="Landw. Nutzungsflaechen" />
      <Index indexName="Gewaessernamen" />
      <Index indexName="Flurnamen" />
      <Index indexName="Adressen" />
      <Index indexName="Gemeinden" />
      <Index indexName="Geodaten" />
      <Index indexName="Projektierte Liegenschaften" />
      <Index indexName="Selbstrechte" />
      <Index indexName="Liegenschaften" />
    </InstantSearch>
  );
};
Search.propTypes = {
  mapType: PropTypes.string.isRequired,
  setLayer: PropTypes.func.isRequired,
  layers: PropTypes.array.isRequired,
  setModal: PropTypes.func.isRequired,
  setFeatureInfos: PropTypes.func.isRequired,
  setMenuItems: PropTypes.func.isRequired
};
export default Search;
