import React from "react";
import { PropTypes } from "prop-types";
import Notifications from "../news/Notifications";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { Public, MapOutlined, HelpOutline, Link } from "@mui/icons-material";
import sxMenuItem from "./sxMenuItem";
const MobileMenu = ({
  mobileMoreAnchorEl,
  setMobileMoreAnchorEl,
  mapType,
  isMobileMenuOpen,
  switchMap,
  handleMenuClose,
  openFaqModal,
  news,
  handleLinkMenuOpen,
  openNewsModal
}) => {
  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }
  const sxListItemIcon = { color: "inherit" };
  return (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{
        sx: {
          backgroundColor: "primary.main",
          color: "white",
          minWidth: "200px"
        }
      }}
    >
      <MenuItem
        onClick={() => {
          switchMap();
          handleMenuClose();
        }}
        sx={sxMenuItem}
      >
        <ListItemIcon sx={sxListItemIcon}>
          {mapType === "3D" && <MapOutlined />}
          {mapType === "2D" && <Public />}
        </ListItemIcon>
        <ListItemText>
          {mapType === "3D" && "2D Ansicht"}
          {mapType === "2D" && "3D Ansicht"}
        </ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          openFaqModal();
          handleMenuClose();
        }}
        sx={sxMenuItem}
      >
        <ListItemIcon sx={sxListItemIcon}>
          <HelpOutline />
        </ListItemIcon>
        <ListItemText>FAQ</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleLinkMenuOpen} sx={sxMenuItem}>
        <ListItemIcon sx={sxListItemIcon}>
          <Link />
        </ListItemIcon>
        <ListItemText>Links</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          openNewsModal();
          handleMenuClose();
        }}
        sx={sxMenuItem}
      >
        <ListItemIcon sx={sxListItemIcon}>
          <Notifications latestNewsCount={news.current.length} />
        </ListItemIcon>
        <ListItemText>News</ListItemText>
      </MenuItem>
    </Menu>
  );
};

MobileMenu.propTypes = {
  mobileMoreAnchorEl: PropTypes.object,
  setMobileMoreAnchorEl: PropTypes.func.isRequired,
  mapType: PropTypes.string.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  switchMap: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  openFaqModal: PropTypes.func.isRequired,
  news: PropTypes.object.isRequired,
  handleLinkMenuOpen: PropTypes.func.isRequired,
  openNewsModal: PropTypes.func.isRequired
};
export default MobileMenu;
