export const HOST = "https://geo.ur.ch";
export const GEOSERVER_OVERLAY = "/webmercator/";
export const GEOSERVER_SEC = "/sec-webmercator/";
export const SWISSTOPO_WMS = "https://wms.geo.admin.ch/";
export const WFS_BASE_URL = `${GEOSERVER_OVERLAY}wfs?version=1.0.0&request=GetFeature`;
export const API_URL = `${HOST}/api/`;
export const METADATA_PDF_URL = `${API_URL}createpdf?name=`;
export const PRINT_HOST = "https://legendproxy.hosting.karten-werk.ch";
export const PRINT_DOCUMENT_NAME = "Ausdruck_aus_Geo.Ur.pdf";
export const GRUNDBUCHAUSKUNFT = `${HOST}/grundbuchauskunft?`;
const SWISSTOPO_API_BASE = "https://api3.geo.admin.ch/rest/services";

/*
 * Get a valid request url to a sec layer to check if the user has the necessary permissions.
 * @param {string} layername - geoserver layername (including the namespace).
 * @returns {string} url - the request url to the specific layer.
 */
export const getSecUrl = layername =>
  `${GEOSERVER_SEC}gwc/service/gmaps?layers=${layername}&zoom=17&x=68678&y=46171&gridSet=EPSG:4326&format=image/png`;

export const getHeightUrl = ({ easting, northing }) =>
  `${SWISSTOPO_API_BASE}/height?easting=${easting}&northing=${northing}&elevation_model=DTM2&sr=2056`;

export const getProfileUrl = geojson =>
  `${SWISSTOPO_API_BASE}/profile.json?geom=${geojson}&sr=2056&nb_points=200&distinct_points=true`;

export const getLegendUrl = ({ host, ns, layername }) =>
  `${host}wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&height=15&LAYER=${ns}:${layername}&legend_options=forceLabels:on`;

export const TS_GROUP_ID = {
  Bodenfeuchte: 47043,
  Niederschlag: 41608,
  "Oberflächengewässer Pegel und Abfluss": 41608,
  "Grundwasser und Quellen": 41608
};
export const KIWIS_HOST = "https://kiwis.innetag.ch";
export const ALL_MESSDATEN = `${KIWIS_HOST}/KiWIS/KiWIS?
site_no=*
&returnfields=station_id%2Cstation_no%2Cstation_name%2Cobject_type%2Cparametertype_name%2Csite_name%2Cstation_carteasting%2Cstation_cartnorthing%2Cstation_local_x%2Cstation_local_y%2Cca_sta&stationgroup_id=41543&ca_sta_returnfields=OBJECT_DESCRIPTION%2Cstation_status%2CONLINE_PUBLICATION%2CSTA_LOCATION_TYPE%2COBJECT_DESCRIPTION
&flatten=true
&datasource=1
&service=kisters
&type=queryServices
&request=getStationList
&format=geojson
&bbox=8.35,46.5,8.97,47.0`; // bbox +- canton of uri
export const TIME_SERIES_LIST =
  "/KiWIS/KiWIS?returnfields=station_id%2Cts_id%2Cts_name%2Cts_shortname%2Cts_type_name%2Cparametertype_name%2Cstationparameter_name%2Ccoverage%2Cts_unitname%2Cts_unitsymbol%2Cts_unitname_abs%2Cts_unitsymbol_abs&id=timeSeriesList&datasource=1&service=kisters&type=queryServices&request=getTimeseriesList&format=objson";
export const LATEST_MEASURMENT =
  "/KiWIS/KiWIS?datasource=1&service=kisters&type=queryServices&request=getTimeseriesValues&metadata=true&format=dajson&returnfields=Timestamp,Value,Absolute Value";
export const LATEST_MEASUREMENT_PERIOD = "pt6h";
export const WISKI_HTML_HOST =
  process.env.NODE_ENV === "development"
    ? "http://app.lisag.ch:8080"
    : "https://wiski-html-h2eptfuxza-oa.a.run.app";

export const STREET_SMART_SERVICE =
  "https://streetsmart-pano-service-h2eptfuxza-oa.a.run.app";
