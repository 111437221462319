import React from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import NewsItem from "./NewsItem";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "24px 0 0 0",
    width: "100%"
  },
  archiveButton: {
    backgroundColor: "#fbc02d",
    "&:hover": {
      backgroundColor: "#fbc02d"
    }
  }
}));

/*
 * scrolls a container to a certain top offset
 * @param {HTMLElement} element - the element to scroll.
 * @param {number} offset - the top offset to scroll to.
 */
const scrollToOffset = (element, offset) => {
  window.requestAnimationFrame(() => {
    element.scroll({
      top: offset,
      left: 0,
      behavior: "smooth"
    });
  });
};

export default function NewsArchive({ archive }) {
  const classes = useStyles();
  const expandButton = React.useRef(null);
  const [expanded, setExpanded] = React.useState(false);
  // scroll to top of the archive when it get's opened
  React.useEffect(() => {
    if (expanded === true) {
      const offset = expandButton.current.offsetTop;
      const element =
        expandButton.current.parentElement.parentElement.parentElement;
      // wait for all the archive items to appear before scrolling to top.
      window.setTimeout(() => scrollToOffset(element, offset - 75), 100);
    }
  }, [expanded]);

  return (
    <div className={classes.root}>
      <Button
        ref={expandButton}
        variant="contained"
        className={classes.archiveButton}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        Archiv {expanded ? "schliessen" : "öffnen"}
      </Button>
      <section>
        {expanded &&
          archive.map(item => <NewsItem item={item} key={item.id} />)}
      </section>
    </div>
  );
}

NewsArchive.propTypes = {
  archive: PropTypes.array.isRequired
};
