import appState from "./appState";
import { updateUrl } from "./urlUtil";
export const getSidebarVisibilityState = (oldState, label) => {
  /*
   * geladene layer must work independent of other sidebar items.
   */
  if (label === "Geladene Layer") {
    return oldState.map(item => {
      if (item.label === label) {
        updateUrl({ layersidebarvisible: !item.visible });
        return {
          ...item,
          visible: !item.visible,
          zIndex: item.visible ? 0 : 1
        };
      } else {
        return item;
      }
    });
  } else {
    /*
     * close all other sidebars except for geladene layers.
     */
    return oldState.map(item => {
      if (item.label === label) {
        return {
          ...item,
          visible: !item.visible,
          zIndex: item.visible ? 0 : 1
        };
      }
      if (item.label === "Geladene Layer") {
        return item;
      }
      return { ...item, zIndex: 0, visible: false };
    });
  }
};

export const removeDrawingInteractions = () => {
  // disable drawing modus when measure toolbar closes
  if (appState.measureTool) {
    appState.measureTool.removeInteraction();
  }
  if (appState.drawingTool) {
    appState.drawingTool.removeInteraction();
    appState.drawingTool.setActiveButton({});
  }
  // disable drawing modus when warenkorb toolbar closes
  if (appState.cancelDrawing) {
    appState.cancelDrawing();
  }
};

/*
 * prepares the menuItems array to hide all necessary sidebars.
 * this is helpfull when switching to 3D mode.
 * @param {array} menuItems - menuItem objects like stored in App.jsx.
 * @returns {array} menuItems - new menu Items with all sidebars turned off except for "Geladene Layer".
 */
export const removeSidebars3D = menuItems => {
  return menuItems.map(item => {
    if (item.label === "Geladene Layer" || item.label === "Street View") {
      return item;
    } else {
      return { ...item, visible: false };
    }
  });
};

/*
 * opens the table of content if it is not open yet.
 * @param {array} menuItems - menuItem objects like stored in App.jsx
 * @returns {array} menuItems - new menu items with "Geladene Layer" turned on.
 */
export const openToc = menuItems => {
  return menuItems.map(item => {
    if (item.label === "Geladene Layer" && item.visible === false) {
      return { ...item, visible: true, zIndex: 1 };
    } else {
      return item;
    }
  });
};
