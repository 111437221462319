import { getWfsResult } from "./requestUtil";
import appState from "./appState";
import { convertPointCoordinates, createPointGeojson } from "./projectionUtil";
import { addParamToUrl } from "./urlUtil";
import styles from "./stylesUtil";
import {
  addLayer,
  addOlGeojson,
  addCesiumGeoJson,
  removeCesiumGeojsonOverlays,
  createGeourLayer
} from "./layerUtil";
import { GEOSERVER_OVERLAY, GEOSERVER_SEC } from "./ENV";
import { labelMessdatenLayer } from "./messdatenUtil";
export const processSuggestion = async ({
  suggestion,
  setLayer,
  layers,
  setModal,
  setFeatureInfos,
  setMenuItems,
  mapType,
  serverType = suggestion.extern ? "mapserver" : "geoserver" // one of: 'carmentaserver', 'geoserver', 'mapserver', 'qgis'
} = {}) => {
  switch (suggestion.groupname) {
    case "Geodaten":
      /* important section!
       * normalize the properties from different layer sources (lisag/external)
       */
      const serviceName =
        suggestion.serviceName ||
        `${suggestion.namespace_geoserver}:${suggestion.service_name_geoserver}`;
      const onlineResource =
        suggestion.onlineResource ||
        (suggestion.secured
          ? `${GEOSERVER_SEC}wms?`
          : `${GEOSERVER_OVERLAY}wms?`);

      const updateUrl = suggestion.extern ? false : true;
      let layer = null;
      try {
        layer = await createGeourLayer({
          abstract: suggestion.abstract ? suggestion.abstract : false,
          onlineResource,
          serviceName,
          name: suggestion.name_offiziell,
          sec: suggestion.secured,
          attribution: suggestion.attribution || false,
          extern: suggestion.extern || false,
          legendurl: suggestion.legende || false,
          serverType
        });
        const enhancedLayer = await addLayer({
          layer,
          setLayer,
          layers,
          setModal,
          updateUrl,
          setMenuItems
        });
        if (
          layer.serviceName.indexOf("umwelt:messdaten") !== -1 &&
          layer.name !== "Bodenfeuchte"
        ) {
          labelMessdatenLayer(layer);
        }
        return enhancedLayer;
      } catch (error) {
        appState.setSnackbar({
          open: true,
          message: `Der Layer ${suggestion.name_offiziell} wurde nicht geladen. ${error}`
        });
        return false;
      }
    case "Zeitreise":
      try {
        const enhancedLayer = await addLayer({
          layer: suggestion.layer,
          setLayer,
          layers,
          setMenuItems
        });
        return enhancedLayer;
      } catch (error) {
        appState.setSnackbar({
          open: true,
          message: `Der Layer ${suggestion.layer.name} konnte nicht geladen werden. ${error}`
        });
        return false;
      }
    case "Gemeinde":
    case "Flurnamen":
    case "Adressen":
    case "Liegenschaften":
    case "Projektierte Liegenschaften":
    case "Selbstaendige Rechte":
    case "Gewaessernamen":
    case "LWNutzungsflaechen":
      const geojson = await getWfsResult({
        srs: mapType === "2D" ? "EPSG:3857" : "EPSG:4326",
        groupname: suggestion.groupname,
        searchterm: suggestion.searchterm
      });
      if (geojson) {
        const feature = geojson.features[0];
        if (feature.id.indexOf("adressen.") !== -1) {
          if (window.google && appState.svCoverage.getVisible()) {
            appState.setSvCoords([
              parseFloat(feature.properties.lat),
              parseFloat(feature.properties.lng)
            ]);
          }
        }
        // add layername, usageType and styles
        geojson.features.forEach(feature => {
          const { egris_egrid, searchterm } = feature.properties;
          const { type } = feature.geometry;
          feature.properties.usageType = "search";
          feature.properties._cesiumStyle = styles.cesiumSearch[type];
          feature.properties._olStyle = styles.olSearch[type];
          if (egris_egrid) {
            feature.layername =
              egris_egrid.indexOf("_p") !== -1
                ? `${searchterm} (projektiert)`
                : searchterm;
          } else {
            feature.layername = searchterm;
          }
        });

        if (mapType === "2D") {
          /*
           * setFeatureInfo will display the first feature and zoom to it.
           */
          setFeatureInfos(geojson.features);
        }
        if (mapType === "3D") {
          removeCesiumGeojsonOverlays();
          addCesiumGeoJson({
            type: "search",
            geojson: geojson,
            setFeatureInfos
          });
        }
      }
      addParamToUrl("search", {
        groupname: suggestion.groupname,
        searchterm: suggestion.searchterm
      });
      return geojson;
    case "coordinate":
      if (!suggestion.x || !suggestion.y) {
        if (!suggestion.searchterm.indexOf("/")) {
          return false;
        } else {
          const [x, y] = suggestion.searchterm.split("/");
          suggestion.x = x;
          suggestion.y = y;
        }
      }
      const sourceCoords = [parseFloat(suggestion.x), parseFloat(suggestion.y)];
      const point = convertPointCoordinates({
        sourceProj: suggestion.coordSys,
        destProj: mapType === "2D" ? "EPSG:3857" : "EPSG:4326",
        coordinates: sourceCoords
      });
      const coordinateGeojson = createPointGeojson({
        sourceCoords,
        destCoords: point,
        coordSys: suggestion.coordSys,
        olStyle: styles.olSearch["Point"],
        cesiumStyle: styles.cesiumSearch["Point"]
      });
      if (mapType === "2D") {
        addOlGeojson({
          geojson: coordinateGeojson,
          center: true,
          popup: true,
          usageType: "search"
        });
      } else {
        removeCesiumGeojsonOverlays();
        addCesiumGeoJson({
          type: "search",
          geojson: coordinateGeojson,
          setFeatureInfos
        });
      }
      addParamToUrl("search", {
        groupname: suggestion.groupname,
        searchterm: suggestion.searchterm,
        coordSys: suggestion.coordSys
      });
      return coordinateGeojson;
    default:
      return false;
  }
};
