import React from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import AppBar from "@material-ui/core/AppBar";
import InfoBoxContent from "./InfoBoxContent";
import { ButtonBase, Typography, Toolbar } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { removeSearchOverlays } from "../../utils/layerUtil";
import "./InfoBox.css";
const InfoBox = ({ setFeatureInfos, featureInfos, mapType }) => {
  return (
    <Draggable bounds=".app" handle=".infobox__header">
      <div className="infobox">
        <AppBar position="static" color="default">
          <Toolbar>
            <div className="infobox__header">
              <Typography variant="h6" color="inherit">
                INFO BOX
              </Typography>
            </div>
            <div style={{ padding: "0 0 0 12px" }}>
              <ButtonBase
                className="closeIcon"
                aria-label="close infobox"
                onClick={() => {
                  removeSearchOverlays(setFeatureInfos, mapType);
                }}
              >
                <Close />
              </ButtonBase>
            </div>
          </Toolbar>
        </AppBar>
        <InfoBoxContent featureInfos={featureInfos} mapType={mapType} />
      </div>
    </Draggable>
  );
};

export default InfoBox;

InfoBox.propTypes = {
  featureInfos: PropTypes.array.isRequired,
  setFeatureInfos: PropTypes.func.isRequired,
  mapType: PropTypes.object.isRequired
};
