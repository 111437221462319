import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import { GRUNDBUCHAUSKUNFT } from "../../utils/ENV";

/*
 * Filters out geometric attributes because they contain wrong values in EPSG:3857
 * projection. Remove also attributes with falsy/empty values.
 * @param {object} attributes - attributes of the feature
 * @returns {object} result - attribute object without geometry and empty attributes.
 */
const filterAttributes = (attributes = {}) => {
  const result = {};
  const keys = Object.keys(attributes);
  // remove geometry attributes
  const filteredKeys = keys
    .filter(key => {
      if (
        key !== "boundedBy" &&
        key !== "shape_stle" &&
        key !== "shape_length" &&
        key !== "shape_leng" &&
        key !== "shape_area" &&
        key !== "shape_star" &&
        key !== "shape_stlength__" &&
        key !== "shape_starea__" &&
        key !== "groupname" &&
        key !== "_olStyle" &&
        key !== "_cesiumStyle" &&
        key !== "_cesiumDatasource" &&
        key !== "usageType"
      ) {
        return key;
      }
      return false;
    })
    // filter out attributes with empty values
    .filter(key => {
      if (
        typeof attributes[key] === "string" &&
        attributes[key].trim() === ""
      ) {
        return false;
      }
      if (attributes[key] !== null) {
        return key;
      }
      return false;
    });
  filteredKeys.forEach(key => (result[key] = attributes[key]));
  return result;
};

/*
 * if an attribute contains a link, rename it.
 * @param {object} attributes - (filtered) attribute values.
 * @returns {object} result - object with correct column names for all link fields.
 */
const addLinks = (attributes = {}) => {
  const result = {};
  const keys = Object.keys(attributes);
  keys.forEach(key => {
    if (key === "id_nummer") {
      const [number, bfs] = attributes[key].split(".");
      result["Link Grundbuch"] = `${GRUNDBUCHAUSKUNFT}gem=${bfs}&nr=${number}`;
    } else if (key.slice(0, 5) === "url__") {
      result[`Link ${key.substring(5).toUpperCase()}`] = attributes[key];
    } else if (key.slice(0, 5) === "pdf__") {
      result[
        `Link PDF ${key.slice(5).toUpperCase()}`
      ] = `https://webgis.lisag.ch/PDF${attributes["verzeichnis"]}${attributes[key]}`;
    } else if (key === "flaechenmass") {
      result[key] = attributes[key].toLocaleString("de-CH");
    } else if (key === "datum_upload_sde") {
      // don't show the time in datum_upload_sde
      result[key] = attributes[key].slice(0, attributes[key].indexOf("T"));
    } else {
      result[key] = attributes[key];
    }
  });
  // add oereb link if egrid number is available
  if (attributes.hasOwnProperty("egris_egrid")) {
    result[
      "Link ÖREB-Kataster"
    ] = `https://oereb.ur.ch/?egrid=${attributes["egris_egrid"]}`;
  }
  return result;
};

const Attributes = ({ attributes }) => {
  if (Array.isArray(attributes) && attributes.length > 0) {
    return (
      <table className="attributes__table" size="small" border="1">
        <tbody>
          {attributes.map((attribute, i) => (
            <tr key={`${attribute}_${i}`} className="attributes__tablerow">
              <td className="attributes__tablecell">{attribute}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    const preparedAttributes = addLinks(filterAttributes(attributes));
    const rows = Object.keys(preparedAttributes);
    // remove the verzeichnis attribute cause it is only used for the pdf path.
    if (rows.indexOf("verzeichnis") !== -1) {
      rows.splice(rows.indexOf("verzeichnis"), 1);
    }
    return (
      <table className="attributes__table" size="small" border="1">
        <tbody>
          {/* links */}
          {rows.map(
            (row, i) =>
              row.slice(0, 4).toLowerCase() === "link" && (
                <tr key={`${row}_${i}`} className="attributes__tablerow">
                  <td colSpan="2" className="attributes__tablecell">
                    <a
                      href={preparedAttributes[row]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {row}
                    </a>
                  </td>
                </tr>
              )
          )}
          {/* normal attributes*/}
          {rows.map(
            (row, i) =>
              row.slice(0, 4).toLowerCase() !== "link" && (
                <tr key={`${row}_${i}`} className="attributes__tablerow">
                  {/* vectorlayer descriptions */}
                  {row.toLowerCase() === "description" && (
                    <td
                      colSpan="2"
                      className="attributes__tablecell"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(preparedAttributes[row])
                      }}
                    />
                  )}
                  {row.toLowerCase() === "thumbnail" && (
                    <React.Fragment>
                      <td className="attributes__tablecell-grey">{row}</td>
                      <td
                        className="attributes__tablecell"
                        dangerouslySetInnerHTML={{
                          __html: preparedAttributes[row]
                        }}
                      />
                    </React.Fragment>
                  )}
                  {/* iframes */}
                  {row.toLocaleLowerCase() === "iframeurl" && (
                    <React.Fragment>
                      <td>
                        <iframe
                          className="messdaten-iframe"
                          title={preparedAttributes[row]}
                          frameBorder="0"
                          width="100%"
                          src={preparedAttributes[row]}
                          onLoad={e => {
                            const iframe = e.target;
                            iframe.height = "960px";
                          }}
                        ></iframe>
                      </td>
                    </React.Fragment>
                  )}
                  {row.toLowerCase() !== "description" &&
                    row.toLocaleLowerCase() !== "iframeurl" &&
                    row.toLowerCase() !== "thumbnail" && (
                      <React.Fragment>
                        <td className="attributes__tablecell-grey">{row}</td>
                        <td className="attributes__tablecell">
                          {preparedAttributes[row]}
                        </td>
                      </React.Fragment>
                    )}
                </tr>
              )
          )}
        </tbody>
      </table>
    );
  }
};

export default Attributes;

Attributes.propTypes = {
  attributes: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
