import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";

const ScaleSelector = ({ printScales, printScale, setPrintScale, styles }) => {
  return (
    <FormControl style={styles.fullWidth}>
      <InputLabel>Druckmassstab:</InputLabel>
      <NativeSelect
        value={printScale}
        onChange={e => {
          setPrintScale(parseInt(e.target.value));
        }}
        inputProps={{
          name: "scale",
          id: "print-scale"
        }}
      >
        {printScales.map(scale => (
          <option key={scale} value={scale}>
            1:{scale}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};
export default ScaleSelector;
ScaleSelector.propTypes = {
  printScales: PropTypes.array.isRequired,
  printScale: PropTypes.number.isRequired,
  setPrintScale: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired
};
