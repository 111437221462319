import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { API_URL } from "../../utils/ENV";
import Input from "@material-ui/core/Input";
import LinearProgress from "@material-ui/core/LinearProgress";
import GeodatenkatalogCategoryFilter from "./GeodatenkatalogCategoryFilter";
import { filter, loadGeoshop } from "../../utils/geoshopUtil";
import Geodatenkatalog from "./Geodatenkatalog";
import WmsImportContainer from "../wmsImport/WmsImportContainer";
import { geodatatypes, categorize } from "../../utils/geodatenkatalogUtil";
import appState from "../../utils/appState";
import "./Geodatenkatalog.css";

const GeodatenkatalogContainer = ({
  setModal,
  addToCart,
  setLayer,
  visibleLayers,
  setMenuItems
}) => {
  const [categories, setCategories] = useState(geodatatypes);
  const [filtered, setFiltered] = useState([]);
  const [geodaten, setGeodaten] = useState([]);
  const [categorizedLayers, setCategorizedLayers] = useState([
    { loading: true }
  ]);
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    // check if geodatenkatalog was fetched earlier
    if (appState.geodatenkatalog.length === 0) {
      fetch(`${API_URL}allLayers`)
        .then(response => response.json())
        .then(json => {
          setFiltered(json);
          setGeodaten(json);
          setCategorizedLayers(json);
          appState.geodatenkatalog = json;
        })
        .catch(error => {
          setGeodaten([
            {
              message: `Der Geodatenkatalog konnte nicht geladen werden. ${error}`
            }
          ]);
          setFiltered([
            {
              message: `Der Geodatenkatalog konnte nicht geladen werden. ${error}`
            }
          ]);
        });
      //load necessary geoshop scripts if not available
      if (!window.GSUtil) {
        loadGeoshop()
          .then(response => console.log(response))
          .catch(error => alert(error));
      }
    } else {
      // get the catalog from the global app state
      setFiltered(appState.geodatenkatalog);
      setGeodaten(appState.geodatenkatalog);
      setCategorizedLayers(appState.geodatenkatalog);
    }
  }, []);
  //update filtered items when the categorized layers change
  useEffect(() => {
    const layers = categorize({
      data: categorizedLayers,
      categories
    });
    setFiltered(filter(layers, searchValue, "searchterm"));
  }, [categorizedLayers, categories, searchValue]);
  if (filtered.length === 1 && "loading" in filtered[0]) {
    return (
      <div style={{ padding: "12px", width: "100%" }}>
        LOADING...
        <LinearProgress color="secondary" style={{ marginTop: "16px" }} />
      </div>
    );
  } else {
    return (
      <div style={{ maxWidth: "100%" }}>
        <WmsImportContainer
          setGeodaten={setGeodaten}
          setFiltered={setFiltered}
          setCategorizedLayers={setCategorizedLayers}
          setCategories={setCategories}
        />
        <GeodatenkatalogCategoryFilter
          categories={categories}
          layers={geodaten}
          setCategories={setCategories}
          setCategorizedLayers={setCategorizedLayers}
        />
        <div style={{ border: "1px dotted grey" }}>
          <div style={{ padding: "12px" }}>
            <Input
              className="layerfilter"
              inputProps={{ type: "search" }}
              placeholder="Geodaten suchen..."
              onChange={e => {
                setFiltered(
                  filter(categorizedLayers, e.target.value, "searchterm")
                );
                setSearchValue(e.target.value);
              }}
            />
          </div>
          <Geodatenkatalog
            visibleLayers={visibleLayers}
            layers={filtered}
            addToCart={addToCart}
            setModal={setModal}
            setLayer={setLayer}
            setMenuItems={setMenuItems}
          />
        </div>
      </div>
    );
  }
};

export default GeodatenkatalogContainer;
GeodatenkatalogContainer.propTypes = {
  setModal: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  setLayer: PropTypes.func.isRequired,
  visibleLayers: PropTypes.array.isRequired,
  setMenuItems: PropTypes.func.isRequired
};
