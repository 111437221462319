import { KML, GeoJSON } from "ol/format";
import {
  addGeojsonToSource,
  addLayer,
  createVectorLayer,
  getLayerByName,
  projectGeojson
} from "./layerUtil";
import { convertPointCoordinates } from "./projectionUtil";
import styles from "./stylesUtil";
import appState from "./appState";
let exifr = null;
export const addDnD = ({ setLayer } = {}) => {
  if (!setLayer) {
    return;
  }
  const olmap = document.getElementById("ol-map");
  olmap.addEventListener("dragenter", e => {
    increaseOpacity(olmap);
    e.stopPropagation();
    e.preventDefault();
    return false;
  });
  olmap.addEventListener("dragover", e => {
    increaseOpacity(olmap);
    e.stopPropagation();
    e.preventDefault();
    return false;
  });
  olmap.addEventListener("dragleave", e => {
    resetOpacity(olmap);
    e.stopPropagation();
    e.preventDefault();
    return false;
  });
  olmap.addEventListener("drop", async e => {
    e.stopPropagation();
    e.preventDefault();
    resetOpacity(olmap);
    await displayFiles({ files: e.dataTransfer.files, setLayer });
    return false;
  });
};

const increaseOpacity = olmap => {
  olmap.style.opacity = 0.3;
};
const resetOpacity = olmap => {
  olmap.style.opacity = 1;
};

const displayFiles = async ({ files, setLayer }) => {
  if (!files || !setLayer) {
    return;
  }
  const kmlFormat = new KML(); // needed to convert kml string to ol features.
  const geojsonFormat = new GeoJSON();
  const imageRegex = /\.jpe?g/i;
  const kmlRegex = /(\.kml)/i;
  const imageGeojson = { type: "FeatureCollection", features: [] };
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i);
    // test for kml
    if (kmlRegex.test(file.name)) {
      const kml = await file.text(); // kml as string
      try {
        const features = kmlFormat.readFeatures(kml, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857"
        });
        if (features.length > 0) {
          // create a geojson
          const geojson = geojsonFormat.writeFeatures(features);
          const featureCollection = JSON.parse(geojson);
          // add a style to every geojson feature as a property.
          featureCollection.features.forEach((feature, i) => {
            feature.properties._olStyle = styles.olKml[feature.geometry.type];
            feature.properties._cesiumStyle =
              styles.cesiumKml[feature.geometry.type];
            feature.properties.usageType = "kml";
            feature.properties.name = file.name;
            feature.properties.id = `${file.name}_${i}`;
          });
          const layer = createVectorLayer({
            geojson: featureCollection,
            name: file.name,
            usageType: "kml"
          });
          addLayer({
            setLayer,
            layers: appState.layers,
            layer,
            updateUrl: false
          });
          appState.olMap
            .getView()
            .fit(layer.mapLayer.getSource().getExtent(), { maxZoom: 19 });
        }
      } catch (error) {
        console.error(error);
      }
      continue;
    }
    // test for images
    if (imageRegex.test(file.name)) {
      // lazy load exifr
      if (exifr === null) {
        exifr = await import("exifr");
      }
      try {
        const gpsCoords = await exifr.gps(file);
        if (!gpsCoords) {
          continue;
        }
        const coordinates = convertPointCoordinates({
          sourceProj: "EPSG:4326",
          destProj: "EPSG:3857",
          coordinates: [gpsCoords.latitude, gpsCoords.longitude]
        });

        const thumbUrl = await exifr.thumbnailUrl(file);
        const geojson = {
          type: "Feature",
          geometry: { coordinates, type: "Point" },
          properties: {
            thumbnail: `<img src="${thumbUrl}" alt="thumbnail_${file.name}" style="max-width:300px"/>`,
            name: "Fotos",
            id: file.name, // necessary for cesium
            usageType: "foto", // necessary for cesium
            _olStyle: styles.olFoto,
            _cesiumStyle: styles.cesiumFoto
          }
        };
        imageGeojson.features.push(geojson);
      } catch (error) {
        console.error(error);
      }
      continue;
    }
    console.warn(`Can't add File ${file.name}`);
  }
  if (imageGeojson.features.length > 0) {
    /* check if there is allready a fotos layer
     * if yes, add the featues to the layer
     */
    const fotosLayer = getLayerByName("Fotos");
    if (fotosLayer) {
      const source = fotosLayer.mapLayer.getSource();
      addGeojsonToSource(source, imageGeojson);
      const geojson = geojsonFormat.writeFeaturesObject(source.getFeatures());
      fotosLayer.cesiumGeojson = projectGeojson({ geojson });
      delete fotosLayer.cesiumLayer;
      appState.olMap
        .getView()
        .fit(fotosLayer.mapLayer.getSource().getExtent(), { maxZoom: 19 });
      return;
    }

    const layer = createVectorLayer({
      geojson: imageGeojson,
      name: "Fotos",
      usageType: "foto"
    });
    addLayer({ setLayer, layers: appState.layers, layer, updateUrl: false });
    appState.olMap
      .getView()
      .fit(layer.mapLayer.getSource().getExtent(), { maxZoom: 19 });
  }
};
