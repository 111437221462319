import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { getSidebarVisibilityState } from "../../utils/sidebarUtil";
import "./Sidebar.css";
import appState from "../../utils/appState";
import { STREET_SMART_SERVICE } from "../../utils/ENV";

const StreetSmartSidebar = ({ menuItems, setMenuItems, mapType }) => {
  const menuItem = menuItems.filter(item => item.label === "Street Smart");
  const [showIframe, setShowIframe] = useState(false);
  const streetsmartContainer = useRef(null);
  const iframe = useRef(null);
  const streetsmartWindow = useRef(null);

  useEffect(() => {
    appState.streetSmartActive ? setShowIframe(true) : setShowIframe(false);
  }, [menuItems]);
  useEffect(() => {
    if (!appState.resizeOberserver) {
      appState.resizeOberserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          const height = entry.contentRect.height;
          const width = entry.contentRect.width;
          if (streetsmartWindow.current) {
            streetsmartWindow.current.style.width = `${width}px`;
            streetsmartWindow.current.style.height = `${height - 90}px`;
          }
        });
      });
    }
    //Listen to resize of the streetsmart container
    const streetsmartSidebar = streetsmartContainer.current;
    appState.resizeOberserver.observe(streetsmartSidebar);
    return function cleanup() {
      appState.resizeOberserver.unobserve(streetsmartSidebar);
    };
  });
  return (
    <div
      className={menuItem[0].visible ? "sidebar" : "sidebar sidebar--collapsed"}
      ref={streetsmartContainer}
      style={{
        zIndex: menuItem[0].zIndex,
        maxWidth: "100%",
        minWidth: "300px",
        maxHeight: "86%",
        minHeight: "450px",
        resize: "both",
        overflow: "hidden",
        direction: "rtl", //bottom left handle
        top: mapType === "2D" ? "102px" : "66px"
      }}
    >
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => {
          // disable the drawing modus
          if (appState.measureTool) {
            appState.measureTool.removeInteraction();
          }
          if (appState.streetSmartLayer.mapLayer.getVisible()) {
            appState.streetSmartLayer.mapLayer.setVisible(false);
            appState.streetSmartActive = false;
          }
          setMenuItems(oldState =>
            getSidebarVisibilityState(oldState, menuItem[0].label)
          );
        }}
      >
        schliessen
      </Button>
      <section className="sidebar__content">
        <section>
          {appState.streetSmartUser &&
            appState.streetSmartPw &&
            appState.streetSmartCoords && (
              <Button
                size="small"
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  window.open(
                    iframe.current.src,
                    "_blank",
                    "noopener",
                    "noreferrer"
                  );
                }}
              >
                <OpenInNewIcon fontSize="small" /> &nbsp; &nbsp; vergrössern
              </Button>
            )}
          {/* must be displayed conditionaly because otherwise 
          geladene layer and basiskarten get obscured by iframe */}
          {showIframe && (
            <div
              id="streetsmart"
              style={{ height: "300px", margin: "8px 0 0 0" }}
              ref={streetsmartWindow}
            >
              <iframe
                id="streetsmartframe"
                title="street smart"
                width="100%"
                height="98%"
                src={`${STREET_SMART_SERVICE}/?clickhint=true`}
                frameBorder="0"
                allowFullScreen={true}
                loading="lazy"
                ref={iframe}
              ></iframe>
            </div>
          )}
        </section>
      </section>
    </div>
  );
};

export default StreetSmartSidebar;

StreetSmartSidebar.propTypes = {
  menuItems: PropTypes.array.isRequired,
  setMenuItems: PropTypes.func.isRequired,
  mapType: PropTypes.string.isRequired
};
