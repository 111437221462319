import { assign } from "xstate";
import {
  orthoBasemap,
  pkFarbeBasemap,
  pkGrauBasemap,
  avfarbigBasemap,
  avgrauBasemap,
  whiteBasemap
} from "./basemapDefinitions";
import appState from "../../utils/appState";
import { updateUrl } from "../../utils/urlUtil";
const basemaps = [
  orthoBasemap,
  pkFarbeBasemap,
  pkGrauBasemap,
  avfarbigBasemap,
  avgrauBasemap,
  whiteBasemap
];
/*
Sets the maximum zoomlevel based on which basemap is active
@param {string} basemap - id of the basemap ("avfarbig","luftbild"...)
@param {object} view - openlayers view object
@returns {number} zoomlevel - the maximum zoomlevel of the current basemap or false if no valid basemap is provided.
*/
const setMaxZoom = (basemap, view) => {
  switch (basemap) {
    case "avfarbig":
    case "avgrau":
    case "white":
      view.setMaxZoom(21);
      return 21;
    case "luftbild":
      view.setMaxZoom(20);
      return 20;
    case "pkfarbe":
    case "pkgrau":
      view.setMaxZoom(19);
      return 19;
    default:
      return false;
  }
};
/*
 * changes the basemap on the openLayers map.
 * @param {string} basemapId - the id of the basemap.
 * @returns {boolean} true if basemap was changed, false otherwise.
 */
const changeBasemap = basemapId => {
  if (!basemapId) {
    return false;
  }
  // basemapId must be lowerCase
  const currentId = basemapId.toLowerCase();
  const olMap = appState.olMap;
  const view = olMap.getView();
  setMaxZoom(currentId, view);
  const layers = olMap.getLayers();
  const current = layers.item(0);
  if (current.id === currentId) {
    return false;
  }
  // get then new basemap object
  const newBasemap = basemaps.filter(basemap => basemap.id === currentId)[0];
  layers.removeAt(0);
  layers.insertAt(0, newBasemap);
  return true;
};

/*
 * set the active property of the new basemap to true
 * and the ones of the other basemaps to false.
 */
const updateContext = assign({
  //update the context
  basemaps: (ctx, event) => {
    return ctx.basemaps.map(basemap => {
      if (basemap.name === event.type) {
        basemap.active = true;
      } else {
        basemap.active = false;
      }
      return basemap;
    });
  }
});

export const basemapState = {
  id: "basemaps",
  initial: "luftbild",
  context: { basemaps: basemaps },
  states: {
    luftbild: {},
    pkfarbe: {},
    pkgrau: {},
    avfarbig: {},
    avgrau: {},
    white: {}
  },
  on: {
    // internal transitions
    LUFTBILD: {
      target: ".luftbild",
      internal: true,
      actions: ["updateContext", "changeBasemap", "updateUrl"]
    },
    PKFARBE: {
      target: ".pkfarbe",
      internal: true,
      actions: ["updateContext", "changeBasemap", "updateUrl"]
    },
    PKGRAU: {
      target: ".pkgrau",
      internal: true,
      actions: ["updateContext", "changeBasemap", "updateUrl"]
    },
    AVFARBIG: {
      target: ".avfarbig",
      internal: true,
      actions: ["updateContext", "changeBasemap", "updateUrl"]
    },
    AVGRAU: {
      target: ".avgrau",
      internal: true,
      actions: ["updateContext", "changeBasemap", "updateUrl"]
    },
    WHITE: {
      target: ".white",
      internal: true,
      actions: ["updateContext", "changeBasemap", "updateUrl"]
    }
  }
};

export const basemapActions = {
  actions: {
    updateContext,
    changeBasemap: (ctx, event) => {
      changeBasemap(event.type);
    },
    updateUrl: (ctx, event) => {
      updateUrl({ basemap: event.type });
    }
  }
};
