import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Option from "./Option";
import { updateOptions, getOptions } from "../../utils/geoshopUtil";
import { removeVectorLayers } from "../../utils/layerUtil";
import appState from "../../utils/appState";
const styles = {
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px"
  },
  layerName: {
    fontSize: "1rem",
    wordBreak: "break-word"
  },
  productTitle: {
    margin: "0 0 5px 0",
    fontSize: "14px"
  },
  optionsTitle: {
    margin: "24px 0 12px 0",
    fontSize: "14px"
  },
  productSelect: {
    fontSize: "12px",
    maxWidth: "292px"
  },
  card: {
    margin: "4px"
  },
  configContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "12px"
  }
};
const GeoshopLayerConfig = ({
  layer,
  setGeoshopConfig,
  updatePerimeterButtons,
  classes
}) => {
  const { currentProduct, products, options, gsuser } = layer;
  const [activeProduct, setActiveProduct] = useState(currentProduct);
  const [productOptions, setProductOptions] = useState(options);
  return (
    <section>
      <div className={classes.configContainer}>
        <FormControl fullWidth={true}>
          <h3 className={classes.productTitle}>Produkt</h3>
          <Select
            className={classes.productSelect}
            value={activeProduct}
            onChange={async e => {
              setActiveProduct(e.target.value);
              layer.currentProduct = e.target.value;
              const options = await getOptions(gsuser, e.target.value);
              setProductOptions(options);
              layer.options = options;
              updatePerimeterButtons();
              if (e.target.value.toLowerCase().indexOf("pdf") === -1) {
                removeVectorLayers({ map: appState.olMap, removeType: "pdf" });
              }
            }}
            inputProps={{
              name: "age",
              id: "geoshop-products"
            }}
          >
            {products.map(product => (
              <MenuItem key={product} value={product}>
                {product}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {productOptions.length > 0 && (
          <h3 className={classes.optionsTitle}>Optionen</h3>
        )}
        {productOptions.length > 0 &&
          productOptions.map(option => (
            <Option
              option={option}
              updateOptions={option => {
                setProductOptions(updateOptions(productOptions, option));
              }}
              key={option.name}
            />
          ))}
        {productOptions.length > 0 && (
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={() => {
              setGeoshopConfig(false);
            }}
          >
            Schliessen
          </Button>
        )}
      </div>
    </section>
  );
};

export default withStyles(styles)(GeoshopLayerConfig);
GeoshopLayerConfig.propTypes = {
  layer: PropTypes.object.isRequired,
  setGeoshopConfig: PropTypes.func.isRequired,
  updatePerimeterButtons: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};
