import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import GeoshopContainer from "../geoshop/GeoshopContainer";
import { getSidebarVisibilityState } from "../../utils/sidebarUtil";
import { changeVectorLayerVisibility } from "../olMap/renderOlMap";
import appState from "../../utils/appState";
import "./Sidebar.css";

const WarenkorbSidebar = ({
  menuItems,
  setMenuItems,
  shoppingCart,
  setShoppingCart
}) => {
  const menuItem = menuItems.filter(item => item.label === "Warenkorb");

  return (
    <div
      className={menuItem[0].visible ? "sidebar" : "sidebar sidebar--collapsed"}
      style={{ zIndex: menuItem[0].zIndex }}
    >
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => {
          if (appState.cancelDrawing) {
            appState.cancelDrawing();
          }
          changeVectorLayerVisibility({
            map: appState.olMap,
            type: "hide",
            usageType: "geoshop pdf"
          });
          setMenuItems(oldState =>
            getSidebarVisibilityState(oldState, menuItem[0].label)
          );
        }}
      >
        schliessen
      </Button>
      <section className="sidebar__content">
        <GeoshopContainer
          shoppingCart={shoppingCart}
          setShoppingCart={setShoppingCart}
        />
      </section>
    </div>
  );
};

export default WarenkorbSidebar;

WarenkorbSidebar.propTypes = {
  menuItems: PropTypes.array.isRequired,
  setMenuItems: PropTypes.func.isRequired,
  shoppingCart: PropTypes.array.isRequired,
  setShoppingCart: PropTypes.func.isRequired
};
