import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Slider from "@material-ui/core/Slider";
import yellow from "@material-ui/core/colors/yellow";
import { drawPrintRectangle, print } from "../../utils/printUtil";
import FormatSelector from "./FormatSelector";
import ScaleSelector from "./ScaleSelector";
import LegendSelector from "./LegendSelector";
import appState from "../../utils/appState";
import { removeVectorLayers } from "../../utils/layerUtil";
const useStyles = makeStyles({
  thumb: {
    backgroundColor: yellow["A700"]
  },
  rotationSlider: {
    display: "flex",
    padding: "0 0 12px 0"
  },
  rotationTitle: {
    fontSize: "14px",
    padding: "4px 16px 0 0"
  },
  rotationLabel: {
    fontSize: "14px",
    padding: "4px 0 0 8px"
  }
});

const styles = {
  fullWidth: {
    width: "100%"
  },
  comment: {
    width: "97%"
  },
  spacer: {
    height: "20px"
  }
};
const PrintContainer = ({ printFormats, printFormat, setPrintFormat }) => {
  const printScales = [
    200,
    250,
    500,
    1000,
    2000,
    2500,
    5000,
    10000,
    25000,
    50000
  ];
  const classes = useStyles();

  const [printScale, setPrintScale] = useState(printScales[0]);
  const [comment, setComment] = useState("");
  const [rotation, setRotation] = useState(0);
  const [legend, setLegend] = useState(false);
  const [unRotatedFeature, setUnRotatedFeature] = useState(null);
  const [formatError, setFormatError] = useState(false);
  const [printProgress, setPrintProgress] = useState({
    running: false,
    statusText: ""
  });
  const [rectangleMetrics, setRectangleMetrics] = useState({
    center: appState.olMap.getView().getCenter()
  });
  useEffect(() => {
    if (printFormat === printFormats[0]) {
      removeVectorLayers({ map: appState.olMap, removeType: "print" });
      return;
    }
    drawPrintRectangle({
      format: printFormat,
      scale: `1:${printScale}`,
      callback: metrics => {
        setRectangleMetrics(metrics);
        const newFeature = metrics.source.getFeatures()[0].clone();
        const rotation = newFeature.getProperties().rotation;
        /*
         * if the rectangle is rotated, we have to
         * rotate it back to 0 because a new rotation
         * must start at 0.
         */
        if (rotation > 0) {
          let radian = (rotation * Math.PI) / 180.0;
          newFeature.getGeometry().rotate(radian, metrics.center);
        }
        setUnRotatedFeature(newFeature);
      }
    });
    setRotation(0);
  }, [printFormat, printScale, printFormats]);

  return (
    <div style={styles.fullWidth}>
      <FormatSelector
        printFormats={printFormats}
        printFormat={printFormat}
        setPrintFormat={setPrintFormat}
        styles={styles}
        formatError={formatError}
      />
      <section style={styles.spacer} />
      <ScaleSelector
        printScales={printScales}
        printScale={printScale}
        setPrintScale={setPrintScale}
        styles={styles}
      />
      <section style={styles.spacer} />
      <section className={classes.rotationSlider}>
        <span className={classes.rotationTitle}>Rotation:</span>
        <Slider
          classes={{
            thumb: classes.thumb
          }}
          defaultValue={rotation}
          value={rotation}
          getAriaLabel={value => "i"}
          aria-labelledby="rotation-slider"
          valueLabelDisplay="auto"
          valueLabelFormat={value => `${value}°`}
          step={10}
          min={0}
          max={180}
          onChange={(e, value) => {
            unRotatedFeature.setProperties({ rotation });
            let radian = (value * Math.PI) / 180.0;
            const rotatedFeature = unRotatedFeature.clone();
            rotatedFeature
              .getGeometry()
              .rotate(-radian, rectangleMetrics.center);
            rectangleMetrics.source.clear();
            rectangleMetrics.source.addFeature(rotatedFeature);
            setRotation(value);
          }}
        />

        <span className={classes.rotationLabel}>{rotation}°</span>
      </section>
      <LegendSelector selected={legend} setSelected={setLegend} />
      <TextareaAutosize
        id="comment"
        placeholder="Ihr Kommentar"
        value={comment}
        style={styles.comment}
        aria-label="comment"
        minRows={3}
        onChange={e => setComment(e.target.value)}
      />
      {printProgress.running && (
        <div style={{ padding: "12px 0 0 0" }}>
          <LinearProgress color="secondary" />
          <Typography variant="caption">{printProgress.statusText}</Typography>
        </div>
      )}
      {printProgress.error && (
        <div style={{ padding: "12px 0 0 0" }}>
          <Typography variant="caption" color="error">
            {printProgress.statusText}
          </Typography>
        </div>
      )}

      <section style={styles.spacer} />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={async e => {
          if (printFormat === "Bitte wählen...") {
            setFormatError(true);
            return;
          }
          setFormatError(false);
          setPrintProgress({
            running: true,
            statusText: `Bitte einen Moment Geduld, das PDF wird aufbereitet.`
          });
          await print({
            layout: printFormat,
            scale: printScale,
            comment,
            center: rectangleMetrics.center,
            rotation,
            legend,
            setPrintProgress,
            vectorSource: rectangleMetrics.source
          });
        }}
      >
        Drucken
      </Button>
    </div>
  );
};

export default PrintContainer;
PrintContainer.propTypes = {
  printFormats: PropTypes.array.isRequired,
  printFormat: PropTypes.string.isRequired,
  setPrintFormat: PropTypes.func.isRequired
};
