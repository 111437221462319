import VectorLineIcon from "../components/tools/img/vector-line.svg";
import DashedLineIcon from "../components/tools/img/dashed-line.svg";
import ArrowCursor from "../components/tools/img/arrow-cursor.svg";
import VectorSquare from "../components/tools/img/vector-square.svg";
import VectorPolygon from "../components/tools/img/vector-polygon.svg";
import BlackDotIcon from "../components/tools/img/black-dot.svg";
import TextIcon from "../components/tools/img/text.svg";
import Remove from "../components/tools/img/remove.svg";
import appState from "./appState";
import { STREET_SMART_SERVICE } from "./ENV";
import { createVectorLayer, addGeojsonToSource } from "./layerUtil";

/*
 * creates an object with only the necessary values to style a feature.
 * this is helpfull if we have to transfer the style over the url.
 * @param {object} params - function parameter object.
 * @param {string} params.type - geometry type.
 * @param {object} style - complete feature Style.
 * @returns {object} object - object with only the necessary attributes to style a feature.
 */
export const getSharingStyle = ({ type, style }) => {
  const result = {};
  switch (type) {
    case "Point":
      const fill = style.getImage().getFill().getColor();
      result.fill = fill;
      result.stroke = {
        color: style.getStroke().getColor(),
        width: style.getStroke().getWidth()
      };
      result.radius = style.getImage().getRadius();
      // extract a possible point fill opacity...
      if (fill.indexOf("rgba") !== -1) {
        const replacedFill = fill.replace(/[()]/g, "");
        const fillArr = replacedFill.split(",");
        result.fillOpacity = fillArr[fillArr.length - 1];
      }
      if (style.getText()) {
        result.text = {
          font: style.getText().getFont(),
          fill: style.getText().getFill().getColor(),
          stroke: style.getText().getStroke().getColor(),
          text: style.getText().getText()
        };
      }
      return result;
    case "LineString":
      result.stroke = {
        color: style.getStroke().getColor(),
        width: style.getStroke().getWidth(),
        dashed: style.getStroke().getLineDash()
      };
      return result;
    case "Polygon":
    case "Circle":
      result.fill = style.getFill().getColor();
      result.stroke = {
        color: style.getStroke().getColor(),
        width: style.getStroke().getWidth()
      };
      return result;
    default:
      console.warn(
        "geometry type not recongniced. Not able to style feature",
        type
      );
  }
};

export const geometryButtons = {
  select: [
    {
      title: "Element selektieren/ändern",
      geometryType: "none",
      image: ArrowCursor,
      format: "select"
    },
    {
      title: "Geometrie löschen",
      geometryType: "none",
      image: Remove,
      format: "delete selected"
    }
  ],
  point: [
    { title: "Punkt zeichnen", geometryType: "Point", image: BlackDotIcon }
  ],
  lines: [
    {
      title: "Ausgezogene Linie zeichnen",
      geometryType: "LineString",
      image: VectorLineIcon,
      format: "default"
    },
    {
      title: "Gestrichelte Linie zeichnen",
      geometryType: "LineString",
      format: "dashed",
      image: DashedLineIcon
    }
  ],
  polygons: [
    {
      title: "Polygon zeichnen",
      geometryType: "Polygon",
      image: VectorPolygon,
      format: "default"
    },
    {
      title: "Rechteck zeichnen",
      geometryType: "Circle",
      format: "circle",
      image: VectorSquare
    }
  ],
  text: [
    {
      title: "Text schreiben",
      geometryType: "Point",
      image: TextIcon,
      format: "text"
    }
  ]
};

/*
 * loads and initialize the street smart wfs client.
 * @returns {Promise} - resolved or rejected promise.
 */
export const loadStreetSmartWfsClient = async () => {
  if (!appState.wfsClient) {
    try {
      const WFSClient = await import("./streetSmartWfsclient");
      appState.wfsClient = new WFSClient.default(
        "https://atlas.cyclomedia.com/Recordings/wfs",
        "atlas:Recording",
        "EPSG:3857"
      );
      return Promise.resolve("ok!");
    } catch (error) {
      Promise.reject(error);
    }
  }
};

/*
 * initialize street smart and open the street smart window.
 * @param {objec} params - function parameter object.
 * @param {string} params.username - username for login.
 * @param {string} params.pw - password for login.
 * @param {function} params.setMenuItems - displays the street smart menu.
 * @returns {Promise} - resolved or rejected promise. Resolves with a xml string.
 */
export const streetSmartLogin = async (username, pw) => {
  if (appState.wfsClient) {
    const extent = getStreetsmartExtent(appState.olMap);
    const xml = await appState.wfsClient.queryStreetSmartWfs(
      extent[0],
      extent[1],
      extent[2],
      extent[3],
      username,
      pw
    );
    return xml;
  }
};

/*
 * calculates the extent for the streetsmart wfs to use.
 * if the zoom is too low, we don't display wfs data and therefore
 * we use a default extent only to check the credentials.
 * @param {object} map - ol/map instance.
 * @returns {array} extent - extent to get wfs features.
 */
export const getStreetsmartExtent = map => {
  const defaultExtent = [961143, 5920130, 961404, 5920359];
  const view = map.getView();
  const zoom = view.getZoom();
  const extent =
    zoom >= 17 ? view.calculateExtent(map.getSize()) : defaultExtent;
  return extent;
};

/*
 * creates a street smart layer if it does not allready exist
 * and add's it to the appState.
 * if the layer allready exists, it empties it's source.
 */
export const createStreetSmartLayer = () => {
  if (appState.streetSmartLayer) {
    appState.streetSmartLayer.mapLayer.getSource().clear(true);
  } else {
    appState.streetSmartLayer = createVectorLayer({
      geojson: {
        type: "FeatureCollection",
        features: []
      },
      name: "StreetSmart",
      usageType: "streetsmart"
    });
    //must be always on top
    appState.streetSmartLayer.mapLayer.setZIndex(100);
    appState.olMap.addLayer(appState.streetSmartLayer.mapLayer);
  }
};

/*
 * adds wfs xml response data to the streetSmart layer.
 * @param {string} xmlString - stringified xml data.
 * @returns {object} streetSmartLayer - StreetSmartLayer object.
 */
export const updateStreetSmartLayer = xmlString => {
  const geojson = appState.wfsClient.xmlToGeojson(xmlString);
  if (appState.streetSmartLayer) {
    addGeojsonToSource(appState.streetSmartLayer.mapLayer.getSource(), geojson);
  }
};

/*
 * update the streetsmart panorama image with new coordinates.
 * @param {object} params - function parameter object.
 * @param {array} params.coordinates - the new coordinates for the panorama.
 * @param {string} params.srs - spatial referenence system.
 * @returns {string} url - the updated url for the iframe.
 */
export const updateStreetSmartPanorama = ({ coordinates, srs } = {}) => {
  const iframe = document.getElementById("streetsmartframe");
  const url = `${STREET_SMART_SERVICE}/?srs=${srs}&coords=${coordinates[0]},${
    coordinates[1]
  }&user=${appState.streetSmartUser}&pw=${window.btoa(appState.streetSmartPw)}`;
  iframe.src = url;
  return url;
};

/*
 * hide the street smart layer and disable street smart.
 */
export const hideStreetSmart = () => {
  if (
    appState.streetSmartLayer &&
    appState.streetSmartLayer.mapLayer.getVisible()
  ) {
    appState.streetSmartLayer.mapLayer.setVisible(false);
    appState.streetSmartActive = false;
  }
};
