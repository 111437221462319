import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Authenticate from "../components/dialog/Authenticate";
import NoPermission from "../components/dialog/NoPermission";
import { GEOSERVER_SEC, API_URL, getSecUrl, GEOSERVER_OVERLAY } from "./ENV";
import { attribution } from "../components/basemaps/basemapDefinitions";
import {
  addLayerToUrl,
  removeLayerFromUrl,
  removeParamFromUrl
} from "./urlUtil";
import { addMessdatatenProps, labelMessdatenLayer } from "./messdatenUtil";
import popupUtil from "./popupUtil";
import { openToc } from "./sidebarUtil";
import styles from "./stylesUtil";
import { getZeitreiseLayer } from "../components/basemaps/basemapDefinitions";
import {
  createCesiumWmsImageryProvider,
  createZeitreiseProvider
} from "../components/cesiumMap/createCesiumViewer";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { GeoJSON, WMSCapabilities } from "ol/format";
import { getCenter } from "ol/extent";
import { Vector as VectorSource, OSM, TileWMS, WMTS, XYZ } from "ol/source";
import appState from "./appState";

/*
Does all the work to add a layer to the toc
as well as to the map.
@param {object} layer - layer object with
{
  visible:{boolean} on/off state of the layer,
  opacity: {float} value between 0 and 1,
  ns: {string} geoserver namespace,
  serviceName: {string} service name on geoserver,
  name: {string} official layer name,
  sec: {boolean} if it is a secured layer
}
@param {function} setLayer - hook setLayer function to update the toc.
@param {array} layers - the allready loaded layers.
@param {function} setModal - Hook to load different modals.
@param {boolean} updateUrl - whether or not the url params should be updated.
@returns {object} - the enhanced layer object.
*/
export function addLayer({
  layer,
  setLayer,
  layers,
  setModal,
  updateUrl = true,
  setMenuItems = null
} = {}) {
  return new Promise(async (resolve, reject) => {
    // check if layer is allready in toc. If so, return.
    if (layerInTOC({ layers, layer })) {
      return reject(`⚠ Er wird bereits angezeigt. ⚠`);
    }
    // if it's a sec layer, go through the auth process.
    if (layer.sec) {
      const response = await checkPermission(layer);
      // this function handles also the loading of the layer
      handleResponse({
        response,
        layer,
        layers,
        setModal,
        setLayer,
        setMenuItems,
        resolve,
        reject
      });
    } else {
      const enhancedLayer = await loadLayer(layer, setLayer);
      //open the toc
      if (setMenuItems) {
        setMenuItems(currentState => openToc(currentState));
      }
      if (updateUrl === true) {
        addLayerToUrl({
          name: layer.name,
          visibility: layer.visible,
          opacity: layer.opacity
        });
      }
      resolve(enhancedLayer);
    }
  });
}

/*
 * Handles a http response when loading sec layers
 * @param {object} params - function parameter object.
 * @param {object} params.response - the http response.
 * @param {object} params.layer - the layer object.
 * @param {array} params.layers - list of loaded layers
 * @param {function} params.setModal - Hook to load different modals.
 * @param {function} params.setLayer - hook setLayer function to update the toc.
 * @param {function} params.seMenuItems - hook to open the toc sidebar.
 * @param {function} params.resolve - resolve the Promise from the addLayer function.
 * @param {function} params.reject - reject the Promise from the addLayer function.
 * @returns {object} layer - the added layer.
 */
export const handleResponse = ({
  response,
  layer,
  layers,
  setModal,
  setLayer,
  setMenuItems,
  resolve,
  reject
} = {}) => {
  const showAuth = () => {
    /* function to pass to <Authenticate>.
     *  will be executed on button click.
     */
    const authenticate = async (username, pw) => {
      try {
        const response = await checkPermission(layer, {
          username,
          pw
        });
        handleResponse({
          response,
          layer,
          layers,
          setModal,
          setLayer,
          resolve,
          reject
        });
        return response;
      } catch (error) {
        return Promise.reject("Login nicht möglich");
      }
    };
    setModal({ type: "" });
    setModal({
      type: "login",
      layer,
      reject,
      content: (
        <Authenticate authenticate={authenticate}>
          <FormHelperText
            style={{
              fontSize: "16px",
              lineHeight: 1.5,
              padding: "12px 0"
            }}
          >
            Geschätzter Benutzer
            <br /> Der von Ihnen angeforderte Geodatensatz -{" "}
            <strong>{layer.name}</strong> - unterliegt Sicherheitsbestimmungen
            und kann deshalb nur nach erfolgreicher Authentifizierung eingesehen
            werden. Bitte melden Sie sich mit Ihrem{" "}
            <strong>Benutzernamen</strong> und <strong>Passwort</strong> an.
          </FormHelperText>
        </Authenticate>
      )
    });
  };
  // close any open modal
  setModal({ type: "" });
  switch (response.status) {
    case 401:
      showAuth();
      break;
    case 403:
      //remove all sec layers in order to get no mess
      layers.forEach(layer => {
        if (layer.sec === true) {
          removeLayer(layer, setLayer);
        }
      });
      setModal({
        type: "forbidden",
        layer,
        keypress: showAuth,
        content: <NoPermission layer={layer} showAuth={showAuth} />
      });
      break;
    case 200:
      loadLayer(layer, setLayer);
      // open the toc
      if (setMenuItems) {
        setMenuItems(oldState => openToc(oldState));
      }
      addLayerToUrl({
        name: layer.name,
        visibility: layer.visible,
        opacity: layer.opacity
      });
      resolve(layer);
      break;
    default:
      reject("failed loading layer");
  }
};

/*
* Adds the layer to the toc, the olMap and the cesium map.
* Plus it enhances the layer with further metadata.
* @param {object} layer - layer object with
{
  visible:{boolean} on/off state of the layer,
  opacity: {float} value between 0 and 1,
  ns: {string} geoserver namespace,
  serviceName: {string} service name on geoserver,
  name: {string} official layer name,
  sec: {boolean} if it is a secured layer
}
* @param {function} setLayer - hook setLayer function to update the toc
* @returns {object} layer - the enhanced layer object.
*/
const loadLayer = async (layer, setLayer) => {
  const zeitreise = layer.name.indexOf("Zeitreise") !== -1;
  /* store a reference to the mapLayer in the layer object
   * in case it's a zeitreise layer, the mapLayer property is
   * allready available.
   */
  if (!layer.mapLayer) {
    layer.mapLayer = zeitreise
      ? getZeitreiseLayer(layer)
      : createWmsLayer(layer);
  }
  const zIndex = appState.olMap.getLayers().getLength();
  layer.mapLayer.setZIndex(zIndex);
  appState.olMap.addLayer(layer.mapLayer);
  /* add the layer to the toc even if we don't have all the metadata yet.
   * this is necessary because of performance reasons. The cloud function "getLayerInfo"
   * is initially not very fast.
   */
  setLayer({
    type: "add",
    index: 0,
    layer
  });
  // add the layer to the cesium viewer if it is available.
  if (appState.cesiumViewer) {
    layer.cesiumLayer = await createCesiumLayer(layer);
    // make sure the street view coverage stays on top in the cesium viewer.
    window.requestAnimationFrame(() => {
      appState.cesiumViewer.imageryLayers.raiseToTop(
        appState.cesiumSvCoverageLayer
      );
    });
  }
  if (
    !zeitreise &&
    !layer.extern &&
    layer.name !== "Zeichnung (aktiv)" &&
    !layer.metadata
  ) {
    // enhance the layer with metadata
    const info = await getLayerInfo(`${layer.name}`);
    // it's not necessary to replace the layer because, layer is passed by reference.
    layer.metadata = info;
  }
  if (!zeitreise && layer.name.indexOf("Zeichnung") === -1) {
    // check for dimensions
    const dimensions = await getDimensions(layer);
    if (dimensions?.values) {
      dimensions.values = dimensions.values.split(",");
      layer.dimensions = dimensions;
      setLayer({ type: "replace", layer });
    }
  }
  return layer;
};

/*
 * creates a cesium layer object and add the layer to the cesium viewer.
 * @param {object} layer - layer object like stored in layers.
 * @returns {object} cesiumLayer - the cesium imagery layer.
 */
export const createCesiumLayer = async layer => {
  const { usageType } = layer.mapLayer;
  if (
    (usageType === "kml" ||
      usageType === "draw" ||
      usageType === "messdaten" ||
      usageType === "foto") &&
    layer.cesiumGeojson
  ) {
    const geojson = JSON.parse(layer.cesiumGeojson);
    /*
     * we have to delete the height in the geometry, because it is often set to 0 and
     * therefore the point object get obscured by the terrain.
     */
    geojson.features.forEach(feature => {
      const geometry = feature.geometry;
      const coordinates = geometry.coordinates;
      if (geometry.type === "Point" && coordinates.length > 2) {
        feature.geometry.coordinates = [coordinates[0], coordinates[1]];
      }
    });
    const cesiumLayer = addCesiumGeoJson({
      geojson,
      type: usageType
    });
    cesiumLayer.show = layer.visible ? true : false;
    return cesiumLayer;
  } else {
    const zeitreise = layer.name.indexOf("Zeitreise") !== -1;
    const imageProvider = zeitreise
      ? createZeitreiseProvider({ year: layer.year, Cesium: window.Cesium })
      : createCesiumWmsImageryProvider(layer, window.Cesium);
    const cesiumLayer =
      appState.cesiumViewer.imageryLayers.addImageryProvider(imageProvider);
    cesiumLayer.alpha = layer.opacity || 1.0;
    cesiumLayer.show = layer.visible ? true : false;
    cesiumLayer.name = layer.name;
    return Promise.resolve(cesiumLayer);
  }
};
/*
 * set visibility for ol and cesium layers
 * @param {object} layer - a layer object
 * @param {boolean} checked - if layer is on or off
 * @returns {boolean} - true on success, false otherwise
 */
export const toggleLayer = (layer, checked = true) => {
  if (layer) {
    layer.visible = !checked;
    layer.mapLayer.setVisible(!checked);
    if (layer.cesiumLayer) {
      layer.cesiumLayer.show = !checked;
      appState.cesiumViewer.scene.requestRender();
    }
    return true;
  } else {
    console.warn("Please provide a layer to the toggleLayer function.");
    return false;
  }
};

/*
 * set new opacity values for ol and cesium layers
 * @param {object} layer - a layer object
 * @param {number} newOpacity - floating point number with the new opacity
 * @returns {boolean} - true on success, false otherwise
 */
export const updateLayerOpacity = (layer, newOpacity = 1.0) => {
  if (layer) {
    layer.opacity = newOpacity;
    layer.mapLayer.setOpacity(newOpacity);
    if (layer.cesiumLayer) {
      if (
        layer.cesiumLayer.name.toLowerCase() === "kml" ||
        layer.cesiumLayer.name.toLowerCase() === "draw" ||
        layer.cesiumLayer.name.toLowerCase() === "messdaten"
      ) {
        const entities = layer.cesiumLayer.entities.values;
        entities.forEach(entity => {
          if (entity.point) {
            const fillColor = entity.point.color.getValue();
            const outlineColor = entity.point.outlineColor.getValue();
            outlineColor.alpha = newOpacity;
            if (newOpacity < 0.5) {
              fillColor.alpha = newOpacity;
            } else {
              fillColor.alpha = 0.5;
            }
            entity.point.outlineColor.setValue(outlineColor);
            entity.point.color.setValue(fillColor);
          }
          if (entity.billboard) {
            const billboardColor = entity.point.color.getValue();
            billboardColor.alpha = newOpacity;
            entity.billboard.color.setValue(billboardColor);
          }
          if (entity.polyline) {
            const material = entity.polyline.material;
            const color = material.color.getValue();
            color.alpha = newOpacity;
            material.color.setValue(color);
          }
          if (entity.polygon) {
            const material = entity.polygon.material;
            const color = material.color.getValue();
            if (newOpacity < 0.7) {
              color.alpha = newOpacity;
            } else {
              color.alpha = 0.7;
            }
            material.color.setValue(color);
          }
          appState.cesiumViewer.scene.requestRender();
        });
        return;
      }
      layer.cesiumLayer.alpha = newOpacity;
      // because of performance savings we have to explicitly request a
      // render to view the new transparency in the viewer.
      appState.cesiumViewer.scene.requestRender();
    }
    return true;
  } else {
    console.warn("Please provide a layer to the updateLayerOpacity function.");
    return false;
  }
};

/*
 * removes a layer from the toc, the map and the infoBox.
 * @param {object} layer - layerobject.
 * @param {function} setLayer - useState hook function.
 * @param {function} setFeatureInfos - useState hook function.
 * @returns {void}
 */
export const removeLayer = (layer, setLayer, setFeatureInfos = null) => {
  // remove from TOC
  setLayer({
    type: "remove",
    layer
  });
  appState.olMap.removeLayer(layer.mapLayer); // remove from ol map
  if (appState.cesiumViewer && layer.cesiumLayer) {
    appState.cesiumViewer.imageryLayers.remove(layer.cesiumLayer); // remove imagery layer from cesium
    appState.cesiumViewer.dataSources.remove(layer.cesiumLayer); // remove kml layer from cesium
  }
  removeLayerFromUrl(layer); //remove from url
  if (layer.name === "Zeichnung") {
    removeParamFromUrl("drawings");
  }
  //remove from infobox
  if (setFeatureInfos) {
    setFeatureInfos(currentInfos => {
      const filtered = currentInfos.filter(info => {
        return info.layername !== layer.name;
      });
      if (filtered.length === 0) {
        //remove orphan geojson overlays
        removeVectorLayers({
          map: appState.olMap,
          removeType: "featureInfo_search"
        });
        removeCesiumGeojsonOverlays();
      }
      return filtered;
    });
  }
};

/*
Get infos from geoserver for a specific layer (like if it's a group layer...)
@param {string} name - layername like stored in geoserver "name_offiziell"
@returns {Promise} - object with layer infos e.g. metadata...
*/
export async function getLayerInfo(name) {
  if (name.indexOf("Zeitreise") === -1) {
    const response = await fetch(`${API_URL}layer?name=${name}`);
    if (response.ok) {
      return response.json();
    } else {
      // in case no layer was found on server
      alert(
        `Sorry, der Layer "${name}" konnte auf dem Server nicht gefunden werden 🤷. Bitte prüfen Sie die Schreibweise.`
      );
      return `Sorry, der Layer "${name}" konnte auf dem Server nicht gefunden werden 🤷. Bitte prüfen Sie die Schreibweise.`;
    }
  } else {
    // it's a zeitreise layer
    const year = name.split(" ")[2];
    return new Promise((resolve, reject) => {
      resolve({ name_offiziell: name, year, secured: false });
    });
  }
}

/*
 * Checks if the user has the necessary rights to load a secured layer
 * @param {object} layer - {
  visible:{boolean} on/off state of the layer,
  opacity: {float} value between 0 and 1,
  ns: {string} geoserver namespace,
  serviceName: {string} service name on geoserver,
  name: {string} official layer name,
  sec: {boolean} if it is a secured layer
}
 * @param {object} credentials - object with username and pw key. {username:"blala", pw:"blala"}
 * @returns {object} response - http response object
 */
export const checkPermission = async (layer, credentials) => {
  const headers = { "X-LoginGeoserver": "LoginGeoserver" };
  if (
    credentials &&
    credentials.hasOwnProperty("username") &&
    credentials.hasOwnProperty("pw")
  ) {
    headers.Authorization =
      "Basic " + btoa(credentials.username + ":" + credentials.pw);
  }
  const response = await fetch(getSecUrl(layer.serviceName), {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: headers
  });
  return response;
};

/*
Creates a ol wmts overlay for a geoserver layer
@parma {object} layer - {ns:geoserver namespace, serviceName: geoserver servicename}
@returns {object} TileLayer - ol.TileLayer instance
*/
const getPreviewLayer = layer => {
  const url =
    layer.sec === true ? `${GEOSERVER_SEC}wms` : `${GEOSERVER_OVERLAY}wms`;
  const tileLayer = new TileLayer({
    opacity: layer.opacity,
    zIndex: appState.olMap.getLayers().getLength(), // display it on top of all other layers
    source: new TileWMS({
      attributions: attribution,
      url: url,
      params: {
        LAYERS: `${layer.serviceName}`,
        FORMAT: "image/png",
        CRS: "EPSG:3857",
        SRS: "EPSG:3857",
        WIDTH: 256,
        HEIGHT: 256
      },
      serverType: "geoserver"
    })
  });
  tileLayer.name = layer.name;
  return tileLayer;
};

/*
Creates a ol wms overlay for external wms servers.
@parma {object} layer - url, Name, attribution must be properties of the layer object.
@returns {object} TileLayer - ol.TileLayer instance
*/
export const createWmsLayer = layer => {
  const {
    serviceName,
    attribution,
    onlineResource,
    opacity,
    name,
    serverType,
    legendurl
  } = layer;
  const wmsLayer = new TileLayer({
    zIndex: appState.olMap.getLayers().getLength(), // display it on top of all other layers
    visible: layer.visible,
    opacity: opacity || 1,
    source: new TileWMS({
      attributions: layer.attribution ? ` | ${attribution}` : "",
      url: onlineResource,
      params: {
        LAYERS: serviceName,
        CRS: "EPSG:3857",
        SRS: "EPSG:3857",
        TILED: true
      }
    })
  });
  wmsLayer.name = name;
  wmsLayer.serverType = serverType;
  wmsLayer.legendurl = legendurl;
  return wmsLayer;
};

/*
add layers which are stored in the url layers query string
@param {object} {
  searchParams {object}  - the parsed query paramseters from the url
  layers {array}         - all the loaded layer objects
  setLayer {function}    - manipulate the layers array
  setModal {function}    - open or close modals
}
@param {function} setLayer - useState function to add layer object to the layers array 
*/
export const addLayersFromSearchParams = async ({
  searchParams,
  layers,
  setLayer,
  setModal
} = {}) => {
  let visibilityArr = null;
  let opacityArr = null;
  const layerNames = searchParams.layers.split(",");
  if (searchParams.visibility) {
    visibilityArr = searchParams.visibility.split(",");
  }
  if (searchParams.opacity) {
    opacityArr = searchParams.opacity.split(",");
  }
  let index = layerNames.length - 1;
  while (index >= 0) {
    try {
      const layerInfo = await getLayerInfo(layerNames[index]);
      let visible = true;
      let opacity = 1.0;
      if (visibilityArr && visibilityArr[index]) {
        visible = visibilityArr[index] === "false" ? false : true;
      }
      if (opacityArr && opacityArr[index]) {
        opacity = parseFloat(opacityArr[index]) || 1.0;
      }
      const serviceName = layerInfo.year //zeitreise
        ? layerInfo.name_offiziell
        : `${layerInfo.namespace_geoserver}:${layerInfo.service_name_geoserver}`;
      const year = layerInfo.year || null; // zeitreise
      const onlineResource = layerInfo.secured
        ? `${GEOSERVER_SEC}wms?`
        : `${GEOSERVER_OVERLAY}wms?`;

      const layer = await createGeourLayer({
        metadata: layerInfo,
        visible,
        opacity,
        sec: layerInfo.secured,
        onlineResource,
        serviceName,
        name: layerInfo.name_offiziell,
        year
      });
      await addLayer({
        layer,
        setLayer,
        updateUrl: false,
        layers,
        setModal
      });
      if (
        layer.serviceName.indexOf("umwelt:messdaten") !== -1 &&
        layer.name !== "Bodenfeuchte"
      ) {
        labelMessdatenLayer(layer);
      }
    } catch (error) {
      removeLayerFromUrl({ name: layerNames[index] });
      appState.setSnackbar({
        open: true,
        message: `Der Layer ${layerNames[index]} konnte nicht geladen werden.`
      });
    }
    index--;
  }
};

/*
 * Removes every Geojson overlay from the cesium map
 */
export const removeCesiumGeojsonOverlays = () => {
  if (appState.cesiumViewer) {
    const dataSources = appState.cesiumViewer.dataSources;
    for (var i = 0; i < dataSources.length; i++) {
      const source = dataSources.get(i);
      switch (source.name.toLowerCase()) {
        case "kml":
        case "draw":
        case "gps":
        case "messdaten":
        case "foto":
          break;
        default:
          dataSources.remove(source, true);
      }
    }
    appState.cesiumViewer.scene.requestRender();
  }
};

/*
 * Adds a Geojson Object to the openlayers map
 * @param {object} params - object with function parameters
 * @param {object} params.geojson - valid geojson object
 * @param {boolean} params.center - wether or not the map should be centered on the geosjon
 * @param {boolean} parms.popup - wether or not a popup with attributes should be displayed
 * @param {string} params.usageType - (kml, featureInfo, search...).
 * @returns {object} geojsonLayer - ol VectorLayer instance or null in case of failure
 */
export const addOlGeojson = ({
  geojson,
  center = false,
  popup = false,
  usageType = "search"
} = {}) => {
  const map = appState.olMap;
  removeVectorLayers({
    map: appState.olMap,
    removeType: "featureInfo_search"
  });
  if (!geojson || typeof geojson.type === "undefined") {
    return false;
  }
  // case when making a getFeatureInfo request of an external wms
  if (geojson.type === "Feature" && !geojson.geometry) {
    return false;
  }
  const vectorLayer = createVectorLayer({
    geojson,
    name: geojson.layername,
    usageType
  });
  map.addLayer(vectorLayer.mapLayer);
  /*
  Have to ask lisag if they want animations after selecting a feature.
  Maybe we do something with canvas?
  */
  const extent = vectorLayer.mapLayer.getSource().getExtent();
  if (center === true) {
    const view = map.getView();
    view.fit(extent, { duration: 300, maxZoom: 19 });
  }
  if (popup === true) {
    popupUtil.view.displayPopup({
      position: getCenter(extent),
      content: popupUtil.view.getContent(geojson)
    });
  }
  return vectorLayer;
};

/*
 * Adds a geojson object to the cesium map.
 * @param {object} params - object with function parameters.
 * @param {string} type - one of: "kml", "draw", "search", "featureInfo"
 * @param {object} params.geojson - valid geojson FeatureCollection object.
 * @param {function} params.setFeatureInfos - function to show the info box.
 * @returns {object} datasource - Cesium.CustomDataSource or null in case of failure.
 */
export const addCesiumGeoJson = ({ type, geojson, setFeatureInfos }) => {
  if (!type || !geojson) {
    return null;
  }
  // external wms
  if (
    !Array.isArray(geojson) &&
    !geojson.geometry &&
    !geojson.type === "FeatureCollection"
  ) {
    return null;
  }
  switch (type) {
    case "kml":
    case "draw":
    case "gps":
    case "messdaten":
    case "foto":
      const customDatasource = new window.Cesium.CustomDataSource(type);
      customDatasource.show = true;
      geojsonFeaturesToCesiumEntities({
        features: geojson.features,
        datasource: customDatasource,
        type
      });
      appState.cesiumViewer.dataSources.add(customDatasource);
      appState.cesiumViewer.scene.requestRender();
      return customDatasource;
    case "search":
      const searchDatasource = new window.Cesium.CustomDataSource(type);
      geojsonFeaturesToCesiumEntities({
        features: geojson.features,
        datasource: searchDatasource,
        type,
        addToProps: true
      });
      if (setFeatureInfos) {
        // setFeatureInfos will add the datasource to the map.
        setFeatureInfos(geojson.features);
        /* setFeatureInfos does not center on a geometry because it is
         * not the expected for instance for getFeatureInfo requests.
         * therefore we have to do it here manually.
         */
        centerOnCesiumDatasource(searchDatasource);
      }
      return searchDatasource;

    case "featureInfo":
      // we have to create a datasource for each geojson feature, not one for all features.
      geojson.features.forEach((feature, count) => {
        if (feature.geometry) {
          const datasource = new window.Cesium.CustomDataSource(
            `${type}_${count}`
          );
          geojsonFeaturesToCesiumEntities({
            features: [feature],
            datasource,
            type: `type_${count}`,
            addToProps: true
          });
        }
      });
      if (setFeatureInfos) {
        // this will add the first feature to the map, but not zooming on it.
        setFeatureInfos(geojson.features);
      }
      return;
    default:
      console.warn("not able to create cesium geojson. Type not recognized.");
  }
};

export const centerOnCesiumDatasource = datasource => {
  appState.cesiumViewer
    .flyTo(datasource, {
      duration: 0.8,
      maximumHeight: 2500,
      offset: new window.Cesium.HeadingPitchRange(
        window.Cesium.Math.toRadians(0.0),
        window.Cesium.Math.toRadians(-90),
        750
      )
    })
    .then(response => {
      if (response === false) {
        console.warn("not able to fly to source...");
      }
    });
};

/*
 * converts an array of geojson features to cesium entities and adds them to a datasource
 * @param {object} params - function parameter object.
 * @param {array} params.features - geojson features.
 * @param {object} params.datasource - cesium datasource .
 * @param {string} params.type - the origin of the data... "kml", "search" etc.
 * @param {boolean} params.addToProps - if the datasource should be added to the feature properties
 * @returns void the datasource from the parameter is passed by reference.
 */
const geojsonFeaturesToCesiumEntities = ({
  features,
  datasource,
  type,
  addToProps = false
} = {}) => {
  for (const feature of features) {
    const entity = createCesiumEntity(feature);
    if (entity) {
      const messdaten =
        feature.properties.usageType === "messdaten" ? true : false;
      entity.name = messdaten
        ? feature.properties.station_name
        : feature.properties.name;
      if (!entity.name) {
        entity.name = type;
      }
      entity.properties = feature.properties;
      datasource.entities.add(entity);
      if (addToProps) {
        feature.properties._cesiumDatasource = datasource;
      }
    }
  }
};

/*
 * Adds a preview of a geodatenkatalog layer to the olMap
 * @param {object} layer - {ns:geoserver namespace, name:geoserver serviceName}
 * @returns {object} mapLayer - the olLayer object added to the map
 */
export const addLisagLayerPreview = layer => {
  const previewLayer = getPreviewLayer(layer);
  appState.olMap.addLayer(previewLayer);
  return previewLayer;
};

/*
 * Adds a preview of a external wms geodatenkatalog layer to the olMap
 * @param {object} layer - like received from wms capabilities
 * @returns {object} mapLayer - the olLayer object added to the map
 */
export const addExternalLayerPreview = layer => {
  const wms = createWmsLayer(layer);
  appState.olMap.addLayer(wms);
  return wms;
};

/*
 * checks if a layer is allredy in the table of content
 * @param {object} params - object with function parameters
 * @param {array} layers - the layers allready loaded (in TOC)
 * @param {object} layer - the new layer which should be added
 * @returns {boolean} - true if layer allready loaded, false otherwise
 *
 */
export const layerInTOC = ({ layers, layer } = {}) => {
  if (!layers || !layer) {
    return true;
  }
  return (
    layers.filter(item => item.serviceName === layer.serviceName).length > 0
  );
};

/*
 * update the openlayers ZIndexes of the overlay layers
 * @param {object} zIndexes - key:layername, value:zIndex e.g. {Liegenschaften:3, Hoheitsgrenzen:2}
 * @returns {boolean} - true in case of success, false otherwise
 */
export const updateOlZIndexes = zIndexes => {
  if (!zIndexes) {
    return false;
  }
  appState.olMap.getLayers().forEach((layer, i) => {
    if (layer instanceof VectorLayer && layer.usageType !== "draw") {
      layer.setZIndex(80 + i);
    } else if (layer.name === "Street View") {
      layer.setZIndex(100);
    } else {
      layer.setZIndex(zIndexes[layer.name] || 0);
    }
  });
  return true;
};

/*
 * update the cesiumlayers ZIndexes of the overlay layers
 * @param {object} zIndexes - key:layername, value:zIndex e.g. {Liegenschaften:3, Hoheitsgrenzen:2}
 * @returns {boolean} - true in case of success, false otherwise
 */
export const updateCesiumZIndexes = zIndexes => {
  if (!zIndexes) {
    return false;
  }
  const cesiumLayers = appState.cesiumViewer.imageryLayers;
  for (var i = 0; i < cesiumLayers.length; i++) {
    const layer = cesiumLayers.get(i);
    if (layer.name) {
      const diff = getIndexDiff({
        oldIndex: i,
        newIndex: zIndexes[layer.name]
      });
      changeCesiumLayerOrder({
        layer,
        imageryLayerCollection: cesiumLayers,
        ...diff
      });
    }
  }
  appState.cesiumViewer.render();
  return true;
};

/*
 * checks the differences between two indexes.
 * @param {object} params - function parameter object
 * @param {number} params.oldIndex - old array index
 * @param {number} params.newIndex - new array index
 * @returns {object} - {moveType: "higher", "lower" or "equal", steps: difference bewteen old and newIndex in absolute values}
 */
const getIndexDiff = ({ oldIndex, newIndex }) => {
  let moveType = newIndex < oldIndex ? "lower" : "raise";
  if (oldIndex === newIndex) {
    moveType = "equal";
  }
  return {
    moveType,
    steps: Math.abs(newIndex - oldIndex)
  };
};

/*
 * changes the zIndex of a cesium layer
 * @param {object} params - function parameter object
 * @param {object} params.layer - cesium layer instance
 * @param {object} params.imageryLayerCollection - cesium imageryLayerCollection instaance
 * @param {string} moveType - "upper", "lower" or "equal"
 * @param {number} steps - how many times to go up/down
 * @returns {boolean} - true when successfully moved, false otherwise
 */
const changeCesiumLayerOrder = ({
  layer,
  moveType,
  steps,
  imageryLayerCollection
} = {}) => {
  if (!layer || !moveType || !steps) {
    return false;
  }
  if (moveType === "equal") {
    return true;
  }
  for (var i = 0; i < steps; i++) {
    if (moveType === "lower") {
      imageryLayerCollection.lower(layer);
    } else {
      imageryLayerCollection.raise(layer);
    }
  }
  return true;
};

/*
 * detects the type of of a source.
 * this is useful, because transpilers, like webpack, change the constructor.name property.
 * but with this function, the problem can be avoided.
 * @param {object} source - ol/source instance.
 * @returns {string} layertype - the type of the layer as string.
 */
export const getType = source => {
  if (source instanceof XYZ) {
    return "XYZ";
  }
  if (source instanceof TileWMS) {
    return "TileWMS";
  }
  if (source instanceof VectorSource) {
    return "Vector";
  }
  if (source instanceof OSM) {
    return "OSM";
  }
  if (source instanceof WMTS) {
    return "WMTS";
  }
  return "source not recognised. attention(!), layer will not get printed...";
};

/*
 * removes any search geometries and the infoBox from the map.
 * @param {function} setFeatureInfos - function to hide the infoBox.
 * @param {object} mapType - mapType state.
 */
export const removeSearchOverlays = (setFeatureInfos, mapType) => {
  setFeatureInfos([]);
  if (mapType.value === "3D") {
    removeCesiumGeojsonOverlays();
  } else {
    removeVectorLayers({
      map: appState.olMap,
      removeType: "featureInfo_search"
    });
  }
  removeParamFromUrl("search");
};

/*
 * remove every vector overlay (print/order rectangles) from the ol map
 * @param {object} params - parameter object
 * @param {object} params.map - ol map instance
 * @param {string} params.removeType - what kind of vector layers should be removed ("all" | "pdf" | "geoshop" | "print" | "measure")
 * @returns void
 */
export const removeVectorLayers = ({ map, removeType = "all" } = {}) => {
  //clojure function to remove layers
  function removeLayer(layer) {
    map.removeLayer(layer);
    //prevent memory leaks
    layer.getSource().clear(true);
    layer.setSource(undefined);
  }

  map.getLayers().forEach(layer => {
    if (layer && layer instanceof VectorLayer) {
      if (removeType === "all") {
        removeLayer(layer);
      }
      if (removeType === "pdf" && layer.usageType === "pdf") {
        removeLayer(layer);
      }
      if (removeType === "geoshop" && layer.usageType === "geoshop") {
        removeLayer(layer);
      }
      if (removeType === "print" && layer.usageType === "print") {
        removeLayer(layer);
      }
      if (removeType === "measure" && layer.usageType === "measure") {
        removeLayer(layer);
      }
      if (
        removeType === "featureInfo_search" &&
        (layer.usageType === "search" || layer.usageType === "featureInfo")
      ) {
        removeLayer(layer);
      }
    }
  });
};

export const addDrawingGeojson = ({ drawings, setLayer, layers } = {}) => {
  if (!drawings || !setLayer || !layers) {
    return;
  }
  const geojson = JSON.parse(drawings);
  const layer = createVectorLayer({
    geojson,
    name: "Zeichnung",
    usageType: "draw"
  });
  addLayer({ setLayer, layers, layer, updateUrl: false });
};

/*
 * create a layer for a vector data source like geojson or kml
 * @param {object} params - function parameter object.
 * @param {object} geojson - valid geojson Object.
 * @param {string} name - layername.
 * @param {string} usageType - The type the VectorLayer gets used e.g. "kml", "draw"...
 * @returns {object} layer - object with all the necessary properties to add as a parameter to the addLayer() function.
 */
export const createVectorLayer = ({ geojson, name, usageType }) => {
  const source = new VectorSource({ format: new GeoJSON() });
  addGeojsonToSource(source, geojson);
  const drawingLayer = new VectorLayer({
    source,
    zIndex: appState.olMap.getLayers().getLength()
  });
  drawingLayer.usageType = usageType;
  drawingLayer.name = name;
  const layer = {};
  layer.cesiumGeojson = projectGeojson({ geojson });
  layer.extern = true;
  layer.opacity = 1;
  layer.visible = true;
  layer.name = name;
  layer.serviceName = name;
  layer.mapLayer = drawingLayer;
  return layer;
};

/*
 * adds geojson features to a ol/source/Vector instance.
 * @param {object} source - ol/source/Vector instance.
 * @param {object} geojson - geojson.
 * @returns {object} source - ol/source/Vector instance from the function parameter.
 */
export const addGeojsonToSource = (source, geojson) => {
  if (geojson.type === "FeatureCollection") {
    geojson.features.forEach(element => {
      const feature = createOlFeatureFromGeojson({
        geojson: element
      });
      source.addFeature(feature);
    });
  }
  if (geojson.type === "Feature") {
    const feature = createOlFeatureFromGeojson({ geojson });
    source.addFeature(feature);
  }
  return source;
};

export const createGeourLayer = async ({
  abstract = false,
  metadata = false,
  visible = true,
  opacity = 1,
  onlineResource,
  serviceName,
  name,
  sec = false,
  attribution = false,
  extern = false,
  serverType = "geoserver",
  year = null,
  legendurl = false
}) => {
  const layer = {
    abstract,
    metadata: metadata ? metadata : abstract, // in case of external wms
    visible,
    opacity,
    onlineResource,
    serviceName,
    name,
    sec,
    attribution,
    extern,
    serverType,
    year,
    legendurl // in case of external wms
  };
  if (serviceName.indexOf("umwelt:messdaten") !== -1) {
    await addMessdatatenProps(layer);
  }
  return layer;
};

const createOlFeatureFromGeojson = ({ geojson }) => {
  const feature = new GeoJSON().readFeature(geojson);
  const { _olStyle, _cesiumStyle } = geojson.properties;
  if (_olStyle) {
    feature.setStyle(_olStyle);
    return feature;
  }
  if (_cesiumStyle) {
    feature.setStyle(styles.getOlStyleFromCesium(geojson));
    return feature;
  }
  // not able to get a style, take the default..
  return feature;
};

/*
 * converts a geojson from from the featureProjection to the dataProjection.
 * @param {object} geojson - valid geojson object.
 * @returns {string} stringified geojson with tranformed coordinates.
 */
export const projectGeojson = ({
  geojson,
  dataProjection = "EPSG:4326",
  featureProjection = "EPSG:3857"
} = {}) => {
  return new GeoJSON().writeFeatures(new GeoJSON().readFeatures(geojson), {
    dataProjection,
    featureProjection
  });
};

/*
 * creates a cesium entity from a geojson
 * @param {object} geojson - valid geojson object
 * @returns {object} entity - instanceof Cesium.Entity or null in case no geojson is provided.
 */
const createCesiumEntity = geojson => {
  if (!geojson || !geojson.geometry) {
    return null;
  }
  const { type, coordinates } = geojson.geometry;
  let style = geojson.properties._cesiumStyle;
  // if no style, we have to create one...
  if (!style) {
    console.warn("no style... we have to take the default one...!");
    style = styles.cesiumSearch[type];
  }
  // create Entity specific styles.
  switch (type) {
    case "Point":
    case "MultiPoint":
      const billboard = style.image;
      const pointEntity = {
        position: window.Cesium.Cartesian3.fromDegreesArray(coordinates)[0]
      };

      if (billboard) {
        pointEntity.billboard = {
          ...style,
          heightReference: window.Cesium.HeightReference.CLAMP_TO_GROUND,
          disableDepthTestDistance: Number.POSITIVE_INFINITY // draws the label in front of terrain
        };
      } else {
        const pointOutlineColor = window.Cesium.Color.fromCssColorString(
          style.stroke.color
        );
        const pointFillColor =
          style.fill.indexOf("#") !== -1
            ? window.Cesium.Color.fromCssColorString(style.fill)
            : pointOutlineColor;
        pointEntity.point = {
          color: style.fillOpacity
            ? pointFillColor.withAlpha(style.fillOpacity)
            : pointFillColor,
          pixelSize: style.text ? 2 : style.radius,
          outlineColor: pointOutlineColor,
          outlineWidth: style.text ? 0 : style.stroke.width,
          heightReference: window.Cesium.HeightReference.CLAMP_TO_GROUND,
          disableDepthTestDistance: Number.POSITIVE_INFINITY // draws the label in front of terrain
        };
      }
      if (style.text) {
        pointEntity.label = {
          style: window.Cesium.LabelStyle.FILL_AND_OUTLINE,
          text: style.text.text,
          font: style.Font,
          heightReference: window.Cesium.HeightReference.CLAMP_TO_GROUND,
          horizontalOrigin: window.Cesium.HorizontalOrigin.LEFT,
          verticalOrigin: window.Cesium.VerticalOrigin.BASELINE,
          fillColor: window.Cesium.Color.fromCssColorString(style.text.fill),
          outlineColor: window.Cesium.Color.fromCssColorString(
            style.text.stroke
          ),
          outlineWidth: 5,
          disableDepthTestDistance: Number.POSITIVE_INFINITY // draws the label in front of terrain
        };
      }
      return pointEntity;
    case "LineString":
    case "MultiLineString":
      const lineStrokeColor = window.Cesium.Color.fromCssColorString(
        style.stroke.color
      );
      if (style.dashed) {
      }
      const lineCoords = coordinates.flat(2);
      const lineEntity = {
        polyline: {
          positions: window.Cesium.Cartesian3.fromDegreesArray(lineCoords),
          material: style.stroke.dashed
            ? new window.Cesium.PolylineDashMaterialProperty({
                color: lineStrokeColor,
                dashLength: style.stroke.dashed[0]
              })
            : lineStrokeColor,
          width: style.stroke.width,
          clampToGround: true
        }
      };
      return lineEntity;
    case "Polygon":
    case "MultiPolygon":
      const polygonOutlineColor = window.Cesium.Color.fromCssColorString(
        style.stroke.color
      );
      const PolygonFillColor =
        style.fill.indexOf("#") === -1
          ? window.Cesium.Color.fromAlpha(polygonOutlineColor, 0.5)
          : window.Cesium.Color.fromCssColorString(style.fill);

      const polyCoords = coordinates.flat(3);
      const polygonEntity = {
        polygon: {
          hierarchy: window.Cesium.Cartesian3.fromDegreesArray(polyCoords),
          material: window.Cesium.Color.fromAlpha(PolygonFillColor, 0.3)
        }
      };
      return polygonEntity;
    default:
      console.warn("can not apply cesium geojson styles");
  }
};

/*
 * get a layer by name
 * @param {string} name - the name of the layer to search.
 * @returns {object} layer - geo.ur layer object or null.
 */
export const getLayerByName = name => {
  if (!name) {
    return null;
  }
  for (let layer of appState.layers) {
    if (layer.name && layer.name === name) {
      return layer;
    }
  }
  return null;
};

/*
 * get all the available time dimensions for a wms layer.
 * @param {object} layer - a geo.ur layer object to check for dimensions.
 * @returns {promise} - promise which resolves to a dimension object.
 */
const getDimensions = layer => {
  const url = `${layer.onlineResource}&SERVICE=WMS&VERSION=1.3.0&request=getCapabilities`;
  const parser = new WMSCapabilities();
  return fetch(url)
    .then(response => response.text())
    .then(text => {
      const result = parser.read(text);
      const layers = result.Capability.Layer.Layer;
      const wms = layers.filter(
        wmsLayer => layer.serviceName === wmsLayer.Name
      );
      if (wms.length > 0) {
        if (typeof wms[0].Dimension !== "undefined") {
          const dimensions = wms[0].Dimension[0];
          return dimensions;
        }
      }
      // return an empty object if no dimensions are available
      return {};
    })
    .catch(error => {
      console.error(error);
      return {};
    });
};

/*
 * updates the TIME WMS Parameter of a Geoserver WMS Layer.
 * @param {object} params - function parameter object.
 * @param {object} params.layer - a geo.ur layer object.
 * @param {array} params.val - the new start and end value to set.
 */
export const updateLayerTime = async ({ layer, val } = {}) => {
  if (!layer || !val) {
    return;
  }
  const dimensionName = layer.dimensions.name.toUpperCase();
  const dimensionValue = `${val[0]}/${val[1]}`;
  const params = { [dimensionName]: dimensionValue };
  layer.mapLayer.getSource().updateParams(params);

  if (layer.cesiumLayer) {
    // remove the "old" layer
    appState.cesiumViewer.imageryLayers.remove(layer.cesiumLayer, true);
    // and add the new one
    layer.cesiumLayer = await createCesiumLayer(layer);
  }
};

/* checks if the dimension value on the slider should be labeled or not.
 *  this is neccessary to not overload the slider with labels.
 * @param {number} index - the array index to check for a label.
 * @param {number} dimensionsLength - the length of the dimesions array.
 * @param {boolean} result - true if a label should be added, false otherwise
 */
export const checkDimensionMarkLabel = (index, dimensionsLength) => {
  const middle = parseInt(dimensionsLength / 2);
  if (
    index === 0 || // the first
    index === 1 || // the second
    index === middle || // a middle element
    index === dimensionsLength - 1 // the last
  ) {
    return true;
  } else {
    return false;
  }
};
