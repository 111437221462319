import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Collapse } from "@material-ui/core";
import { getSidebarVisibilityState } from "../../utils/sidebarUtil";
import {
  processSVData,
  hideCesiumStreetviewCoverage
} from "../../utils/streetviewUtil";
import "./Sidebar.css";
import appState from "../../utils/appState";

const StreetviewSidebar = ({ menuItems, setMenuItems, svCoords, mapType }) => {
  const [svAvailable, setSvAvailable] = useState(false);
  const menuItem = menuItems.filter(item => item.label === "Street View");

  useEffect(() => {
    if (svCoords.length > 1) {
      appState.sv.getPanorama(
        { location: { lat: svCoords[0], lng: svCoords[1] }, radius: 50 },
        (data, status) => {
          processSVData(data, status, setSvAvailable);
        }
      );
    }
  }, [svCoords]);
  return (
    <div
      className={menuItem[0].visible ? "sidebar" : "sidebar sidebar--collapsed"}
      style={{
        zIndex: menuItem[0].zIndex,
        top: mapType === "2D" ? "102px" : "66px"
      }}
    >
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => {
          appState.svCoverage.setVisible(false);
          hideCesiumStreetviewCoverage();
          // disable the drawing modus
          if (appState.measureTool) {
            appState.measureTool.removeInteraction();
          }
          setMenuItems(oldState =>
            getSidebarVisibilityState(oldState, menuItem[0].label)
          );
        }}
      >
        schliessen
      </Button>
      <section className="sidebar__content">
        <Collapse in={menuItem[0].visible}>
          <section>
            <div
              id="streetview"
              style={{ height: svAvailable ? "300px" : "0" }}
            />
            {svAvailable === false && (
              <div>
                <div style={{ padding: "0 0 8px 0" }}>STREET VIEW:</div>
                Bitte klicken Sie auf eine
                <strong style={{ color: "#5674c7" }}> blaue</strong> Linie um
                ein Bild zu sehen.
              </div>
            )}
          </section>
        </Collapse>
      </section>
    </div>
  );
};

export default StreetviewSidebar;

StreetviewSidebar.propTypes = {
  menuItems: PropTypes.array.isRequired,
  setMenuItems: PropTypes.func.isRequired,
  svCoords: PropTypes.array.isRequired
};
