import appState from "./appState";

/*
 * show blue streetview coverage layer in
 * ol and cesium.
 */
export const loadStreetViewCoverage = () => {
  appState.svCoverage.setVisible(true);
  showCesiumStreetviewCoverage();
};

/*
 * init streetview service and panorama.
 */
export const initStreetview = () => {
  if (!window.google) {
    requestAnimationFrame(() => {
      initStreetview();
    });
  } else {
    appState.sv = new window.google.maps.StreetViewService();
    appState.panorama = new window.google.maps.StreetViewPanorama(
      document.getElementById("streetview"),
      { controlSize: 24 }
    );
  }
};

/*
 * processes a street view service response.
 * @param {object} data - response object.
 * @param {string} status - response status.
 * @param {function} setSvAvailable - hook function.
 */
export const processSVData = (data, status, setSvAvailable) => {
  if (status === "OK") {
    setSvAvailable(true);
    appState.panorama.setPano(data.location.pano);
    appState.panorama.setPov({ heading: 0, pitch: 0 });
    appState.panorama.setVisible(true);
  } else {
    setSvAvailable(false);
  }
};

/*
 * display the cesium street view coverage layer
 */
const showCesiumStreetviewCoverage = () => {
  const svCoverage = appState.cesiumSvCoverageLayer;
  if (svCoverage) {
    svCoverage.show = true;
    appState.cesiumViewer.imageryLayers.raiseToTop(svCoverage);
  }
};

/*
 * display the cesium street view coverage layer
 */
export const hideCesiumStreetviewCoverage = () => {
  const svCoverage = appState.cesiumSvCoverageLayer;
  if (svCoverage) {
    svCoverage.show = false;
  }
};
