import proj4 from "proj4";
import { transformExtent } from "ol/proj";
/* projection definitions for an easier reference in functions */
proj4.defs(
  "EPSG:21781",
  "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs"
);
proj4.defs(
  "EPSG:2056",
  "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs"
);

export const registerProjections = register => {
  register(proj4);
};

/*
 * Converts point coordinates from one projection to another
 * @param {object} params
 * @param {string} params.sourceProj - the EPSG Code of the current coordinate system
 * @param {string} params.destProj - the EPSG Code of the projection the point should be converted
 * @param {array} params.coordinates - the coordinates to convert
 * @returns {array} converted coordinates
 */
export const convertPointCoordinates = ({
  sourceProj,
  destProj,
  coordinates
} = {}) => {
  // we have to switch the coordinates in case its wgs84
  const normalisedCoords =
    sourceProj === "EPSG:4326"
      ? [parseFloat(coordinates[1]), parseFloat(coordinates[0])]
      : [Math.round(coordinates[0]), Math.round(coordinates[1])];
  return proj4(sourceProj, destProj, normalisedCoords);
};

/*
 * Convert an extent to a different coordinate system
 * @param {object} params
 * @param {string} params.sourceProj - the EPSG Code of the current coordinate system
 * @param {string} params.destProj - the EPSG Code of the projection the point should be converted
 * @param {array} params.extent - the extent to convert
 */
export const convertExtent = ({ sourceProj, destProj, extent }) => {
  return transformExtent(extent, sourceProj, destProj);
};

/*
 * Creates a point geojson object
 * @param {object} params
 * @param {array} params.sourceCoords - the coordinates entered by the user
 * @param {array} params.destCoords - the converted coordinates ready to display in the viewer
 * @param {string} params.coordSys - the coordinate system for the coordinates as EPSG code
 * @param {ol/Style} params.olStyle - openlayers point style
 * @param {object} params.cesiumStyle - the coordinate system for the coordinates as EPSG code
 * @returns {object} geoJSON - Point GeoJSON with displayText property
 */
export const createPointGeojson = ({
  sourceCoords,
  destCoords,
  coordSys,
  olStyle,
  cesiumStyle
} = {}) => {
  //define the correct prefix according to the coordinate system
  let coordPrefix = [];
  switch (coordSys) {
    case "EPSG:4326":
      coordPrefix[0] = "lat";
      coordPrefix[1] = "lon";
      break;
    case "EPSG:2056":
      coordPrefix[0] = "E";
      coordPrefix[1] = "N";
      break;
    default:
      coordPrefix[0] = "X";
      coordPrefix[1] = "Y";
  }
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        id: "Koordinatensuche",
        geometry: {
          type: "Point",
          coordinates: destCoords
        },
        properties: {
          groupname: "coordinate",
          coordSys,
          Koordinate: `${coordPrefix[0]}: ${sourceCoords[0]} / ${coordPrefix[1]}: ${sourceCoords[1]}`,
          _olStyle: olStyle,
          _cesiumStyle: cesiumStyle
        }
      }
    ]
  };
};
