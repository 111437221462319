import React from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, IconButton } from "@mui/material";
import { API_URL } from "../../utils/ENV";
import MapSwitch from "./MapSwitch";
import Search from "../search/Search";
import Faq from "../dialog/Faq";
import Notifications from "../news/Notifications";
import NewsList from "../news/NewsList";
import MobileMenu from "./MobileMenu";
import { Link, HelpOutline, MoreVert } from "@mui/icons-material";
import LinksMenu from "./LinksMenu";
import logo from "./img/logo.png";
import title from "./img/title.png";
import "./appHeader.css";

function AppHeader(props) {
  const {
    layers,
    setLayer,
    mapType,
    switchMap,
    setModal,
    setFeatureInfos,
    setMenuItems
  } = props;
  const [linkAnchor, setLinkAnchor] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [news, setNews] = React.useState({ current: [], archive: [] });
  const isLinkMenuOpen = Boolean(linkAnchor);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  React.useEffect(() => {
    console.log("news effect called");
    const today = new Date(Date.now());
    const currentNews = [];
    const archiveNews = [];
    fetch(`${API_URL}news`)
      .then(response => response.json())
      .then(newsArr => {
        newsArr.forEach(item => {
          const difference_in_days =
            (today.getTime() - new Date(item.datum).getTime()) /
            (1000 * 3600 * 24); // difference in days
          if (difference_in_days > 14) {
            // difference > 14 days
            archiveNews.push(item);
          } else {
            currentNews.push(item);
          }
        });
        setNews({ current: currentNews, archive: archiveNews });
      })
      .catch(error => console.error(error));
  }, []);
  const openFaqModal = () => {
    fetch(`${API_URL}faq`)
      .then(response => response.json())
      .then(faqArr => {
        setModal({
          type: "faq",
          content: <Faq faqs={faqArr} />
        });
      })
      .catch(error => console.error(error));
  };
  const openNewsModal = () => {
    setModal({
      type: "news",
      content: <NewsList news={news} />
    });
  };

  function handleLinkMenuOpen(event) {
    setLinkAnchor(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setLinkAnchor(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  return (
    <div>
      <AppBar
        position="static"
        sx={{ backgroundColor: "primary.dark", boxShadow: "none" }}
      >
        <Toolbar disableGutters>
          <IconButton
            sx={{ padding: { xs: "0 16px", sm: "0 42px 0 24px" } }}
            color="inherit"
            aria-label="logo and app title"
          >
            <img src={logo} alt="lisag logo" />
            <img src={title} className="appHeader__companyname" alt="geo.ur" />
          </IconButton>
          <div className="appHeader__search">
            <Search
              mapType={mapType}
              layers={layers}
              setLayer={setLayer}
              setModal={setModal}
              setFeatureInfos={setFeatureInfos}
              setMenuItems={setMenuItems}
            />
          </div>
          <div className="appHeader__spacer" />

          <div className="appHeader__desktop">
            <MapSwitch mapType={mapType} switchMap={switchMap} />
            <IconButton aria-label="faq" color="inherit" onClick={openFaqModal}>
              <HelpOutline /> <span className="appHeader__text">FAQ</span>
            </IconButton>
            <IconButton
              aria-owns={isLinkMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              aria-label="link"
              title="Links"
              onClick={handleLinkMenuOpen}
              color="inherit"
            >
              <Link /> <span className="appHeader__text">Links</span>
            </IconButton>
            <IconButton
              aria-label="news"
              color="inherit"
              title="news"
              onClick={openNewsModal}
            >
              <Notifications latestNewsCount={news.current.length} />
            </IconButton>
          </div>
          <div className="appHeader__mobile">
            <IconButton
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              aria-label="more"
            >
              <MoreVert />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <LinksMenu
        linkAnchor={linkAnchor}
        isLinkMenuOpen={isLinkMenuOpen}
        handleMenuClose={handleMenuClose}
      />
      <MobileMenu
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        setMobileMoreAnchorEl={setMobileMoreAnchorEl}
        mapType={mapType}
        isMobileMenuOpen={isMobileMenuOpen}
        switchMap={switchMap}
        handleMenuClose={handleMenuClose}
        openFaqModal={openFaqModal}
        news={news}
        openNewsModal={openNewsModal}
        handleLinkMenuOpen={handleLinkMenuOpen}
      />
    </div>
  );
}
AppHeader.propTypes = {
  setLayer: PropTypes.func.isRequired,
  layers: PropTypes.array.isRequired,
  mapType: PropTypes.string.isRequired,
  switchMap: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  setFeatureInfos: PropTypes.func.isRequired,
  setMenuItems: PropTypes.func.isRequired
};
export default AppHeader;
