import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { filterCategories } from "../../utils/geodatenkatalogUtil";

const useStyles = makeStyles({
  root: {
    boxShadow: "0.1px 1px 1.5px 0 rgba(112,112,112,0.7);",
    margin: "4px 4px 0 0",
    maxWidth: "100%",
    overflow: "hidden",
    height: "initial",
    minHeight: "24px"
  },
  labelSmall: {
    whiteSpace: "initial"
  },
  Grundlagen: {
    color: "black",
    backgroundColor: "#8da0cb",
    "&:focus": { backgroundColor: "#8da0cb" },
    "&:hover": { backgroundColor: "#8da0cb" }
  },
  Natur: {
    backgroundColor: "#66c2a5",
    color: "black",
    "&:focus": { backgroundColor: "#66c2a5" },
    "&:hover": { backgroundColor: "#66c2a5" }
  },
  Bevölkerung: {
    backgroundColor: "#fc8d62",
    color: "black",
    "&:focus": { backgroundColor: "#fc8d62" },
    "&:hover": { backgroundColor: "#fc8d62" }
  },
  external_wms: {
    backgroundColor: "white",
    borderColor: "black",
    color: "red",
    "&:focus": { backgroundColor: "white" },
    "&:hover": { backgroundColor: "white" }
  }
});
const CategoryChip = ({
  category,
  categories,
  setCategories,
  layers,
  setCategorizedLayers
}) => {
  const [active, setActive] = useState(true);
  const classes = useStyles();
  const clickChip = () => {
    setActive(!active);
    filterCategories({
      category,
      categories,
      setCategories,
      layers,
      setLayers: setCategorizedLayers
    });
  };
  return (
    <Chip
      size="small"
      classes={{
        root: classes.root,
        labelSmall: classes.labelSmall,
        colorSecondary:
          classes[category.split(" ")[0]] || classes["external_wms"]
      }}
      label={category}
      clickable
      color={active === true ? "secondary" : "default"}
      title={`${category} ein/ausschalten`}
      onClick={clickChip}
    />
  );
};

export default CategoryChip;
CategoryChip.propTypes = {
  category: PropTypes.string.isRequired,
  categories: PropTypes.object.isRequired,
  setCategories: PropTypes.func.isRequired,
  layers: PropTypes.array.isRequired,
  setCategorizedLayers: PropTypes.func.isRequired
};
