import { updateUrl } from "../../utils/urlUtil";
import { normalizeCesiumRotation } from "../../utils/rotationUtil";
import { getAttributeObject } from "../../utils/requestUtil";
import { WISKI_HTML_HOST } from "../../utils/ENV";
import appState from "../../utils/appState";
import { loadCss, loadScript } from "../../utils/loadScript";
import { toLonLat } from "ol/proj";
import {
  addCesiumGeoJson,
  removeCesiumGeojsonOverlays
} from "../../utils/layerUtil";
import styles from "../../utils/stylesUtil";

const cesiumjs =
  "https://cesium.com/downloads/cesiumjs/releases/1.58/Build/Cesium/Cesium.js";
const cesiumcss =
  "https://cesium.com/downloads/cesiumjs/releases/1.58/Build/Cesium/Widgets/widgets.css";
export const loadCesiumLibrary = async () => {
  try {
    return Promise.all([loadCss(cesiumcss), loadScript(cesiumjs)]);
  } catch (error) {
    return alert(`Cesium konnte nicht geladen werden: ${error}`);
  }
};

export const loadCesiumViewer = ({ Cesium, setFeatureInfos } = {}) => {
  const webMercator = new Cesium.WebMercatorProjection();
  const cesiumContainer = document.getElementById("cesium-map");
  if (cesiumContainer === null) {
    window.requestAnimationFrame(() =>
      loadCesiumViewer({ Cesium, setFeatureInfos })
    );
  } else {
    // function to load the 3d objects over the basemap (houses, vegetation etc.)
    var getCesiumTileset = function (url) {
      return new Cesium.Cesium3DTileset({
        url: url,
        skipLevelOfDetail: true,
        baseScreenSpaceError: 1024,
        skipScreenSpaceErrorFactor: 16,
        skipLevels: 1,
        immediatelyLoadDesiredLevelOfDetail: false,
        loadSiblings: false,
        cullWithChildrenBounds: true
      });
    };

    // Aerial image
    // url: "//wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swissimage-product/default/current/4326/{z}/{x}/{y}.jpeg",
    // 3D Terrain
    // url:"//wmts10.geo.admin.ch/1.0.0/ch.swisstopo.swisstlm3d-karte-farbe.3d/default/current/4326/{z}/{x}/{y}.jpeg",
    const basemap = createZeitreiseProvider({ year: "current", Cesium });

    const createSwisstopoTerrain = () => {
      return new Cesium.CesiumTerrainProvider({
        url:
          "//3d.geo.admin.ch/1.0.0/ch.swisstopo.terrain.3d/default/20180601/4326/"
      });
    };

    var config = {
      requestRenderMode: true, // better performance, see: https://cesium.com/blog/2018/01/24/cesium-scene-rendering-performance/
      maximumRenderTimeChange: Infinity,
      baseLayerPicker: false,
      //the terrain
      terrainProvider: createSwisstopoTerrain(), //Cesium.createWorldTerrain(),
      // the basemap
      imageryProvider: basemap,
      fullscreenButton: false,
      homeButton: false,
      infoBox: false,
      sceneModePicker: false,
      selectionIndicator: false,
      timeline: false,
      animation: false,
      geocoder: false,
      navigationInstructionsInitiallyVisible: false,
      navigationHelpButton: true,
      scene3DOnly: true
    };
    //Cesium.Ion.defaultAccessToken =
    //  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4NThiZTQ5Ni0zOGFkLTRiOTAtODA1Yy01MmQwZDc1N2JiOTQiLCJpZCI6MTA3MDQsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NTcyMTMyMDF9.u6iKvPYb2wmwyvF4Now2vpbJdjNEqjPcjP961BHuyus";
    appState.cesiumViewer = new Cesium.Viewer("cesium-map", config);
    registerClickEvents(Cesium, appState.cesiumViewer, setFeatureInfos);
    // add the streetview coverage layer
    const svCoverageProvider = createSvCoverageProvider(Cesium);
    appState.cesiumSvCoverageLayer = appState.cesiumViewer.imageryLayers.addImageryProvider(
      svCoverageProvider
    );
    appState.cesiumSvCoverageLayer.show = appState.svCoverage.getVisible();
    // make sure, ground primitives (buildings) are not shown through mountains (important).
    appState.cesiumViewer.scene.globe.depthTestAgainstTerrain = true;
    // don't allow zoom < 250m
    appState.cesiumViewer.scene.screenSpaceCameraController.minimumZoomDistance = 15;
    // Add buildings
    const buildingTileset = getCesiumTileset(
      "https://vectortiles0.geo.admin.ch/3d-tiles/ch.swisstopo.swisstlm3d.3d/20180716/tileset.json"
    );
    buildingTileset.readyPromise.then(function (tileset) {
      appState.cesiumViewer.scene.primitives.add(tileset);
    });
    // @TODO Cesium Crashes on subsequent loads when the vegetation tileset is loaded
    // const vegetationTileset = getCesiumTileset(
    //   "https://vectortiles0.geo.admin.ch/3d-tiles/ch.swisstopo.vegetation.3d/20180716/tileset.json"
    // );
    // vegetationTileset.readyPromise.then(function(tileset) {
    //   appState.cesiumViewer.scene.primitives.add(tileset);
    // });

    //listen for move events and update the url
    appState.cesiumViewer.camera.moveEnd.addEventListener(() => {
      const xy = webMercator.project(
        appState.cesiumViewer.camera.positionCartographic
      );
      const height = xy.z;
      const zoom = getZoom(height);
      const rotation = appState.cesiumViewer.camera.heading * (180 / Math.PI); //rotation in degrees
      updateUrl({
        center: `${parseInt(xy.x)},${parseInt(xy.y)}`,
        zoom: zoom,
        rotation: normalizeCesiumRotation(rotation)
      });
    });
  }
};

/*
register the click event on the cesium map and
perform getFeatureInfo Requests on all layers.
filter the results and display th infobox.
@param {object} Cesium - main cesium library
@param {object} viewer - cesium viewer instance.
@returns void
*/
const registerClickEvents = (Cesium, viewer, setFeatureInfos) => {
  const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
  handler.setInputAction(e => {
    const pickRay = viewer.camera.getPickRay(e.position);
    // get the wgs coordinates for a potential street view image
    // when street view is active
    if (window.google && appState.svCoverage.getVisible()) {
      const coordinates = [];
      const cartesian3 = viewer.scene.pickPosition(e.position);
      const cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(
        cartesian3
      );
      const lat = parseFloat(Cesium.Math.toDegrees(cartographic.latitude));
      const lng = parseFloat(Cesium.Math.toDegrees(cartographic.longitude));
      coordinates.push(lat);
      coordinates.push(lng);
      appState.setSvCoords(coordinates);
    }
    removeCesiumGeojsonOverlays();
    // get datasources/kml info
    const pickedEntities = drillPickEntities(viewer, e.position);
    const entityResults = [];
    if (pickedEntities.length > 0) {
      pickedEntities.forEach(entity => {
        const usageType = entity.properties.usageType.getValue();
        const layername = entity.properties.name.getValue();
        switch (usageType) {
          case "foto":
            entityResults.push({
              type: "feature",
              layername,
              id: entity.properties.id.getValue(),
              properties: {
                thumbnail: entity.properties.thumbnail.getValue(),
                name: entity.properties.id.getValue()
              }
            });
            break;
          case "messdaten":
            entityResults.push({
              type: "feature",
              layername,
              id: entity.name,
              properties: {
                iframeurl: `${WISKI_HTML_HOST}/wasser?stationid=${entity.properties.station_id.getValue()}`
              }
            });
            break;
          case "kml":
            const description = entity.properties.description.getValue();
            entityResults.push({
              type: "feature",
              layername,
              id: entity.properties.id.getValue(),
              properties: { description }
            });
            break;
          default:
        }
      });
    }
    // get the attributes of the picked entites and convert to infobox format.
    const featuresPromise = viewer.imageryLayers.pickImageryLayerFeatures(
      pickRay,
      viewer.scene
    );
    if (!Cesium.defined(featuresPromise)) {
      if (entityResults.length > 0) {
        setFeatureInfos(entityResults);
      } else {
        console.log("No features picked.");
        return;
      }
    } else {
      Cesium.when(featuresPromise, results => {
        // This function is called asynchronously when the list of picked results is available.
        if (results.length > 0 || entityResults.length > 0) {
          const filteredResults = filterResults(results);
          // combine imagery layers and entities.
          if (entityResults.length > 0) {
            filteredResults.push(entityResults);
          }
          addCesiumGeoJson({
            type: "featureInfo",
            geojson: {
              type: "FeatureCollection",
              features: filteredResults.flat()
            },
            setFeatureInfos
          });
        } else {
          // no imagery or entity results
          setFeatureInfos([]);
        }
      });
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
};

/*
 * pick features from all cesium datasources.
 * @param {object} viewer - cesium viewer instance.
 * @param {object} windowPosition - {x,y} of the position in window coordinates.
 * @returns {array} result entity objects.
 */
const drillPickEntities = (viewer, windowPosition) => {
  var i;
  var entity;
  var picked;
  var pickedPrimitives = viewer.scene.drillPick(windowPosition);
  var length = pickedPrimitives.length;
  var result = [];
  var hash = {};
  for (i = 0; i < length; i++) {
    picked = pickedPrimitives[i];
    entity = window.Cesium.defaultValue(picked.id, picked.primitive.id);
    if (
      entity instanceof window.Cesium.Entity &&
      !window.Cesium.defined(hash[entity.id])
    ) {
      result.push(entity);
      hash[entity.id] = true;
    }
  }
  return result;
};

const filterResults = results => {
  return results
    .filter(result => result.imageryLayer.show === true)
    .map(result => {
      // check for external wms
      if (typeof result.data === "string") {
        return getAttributeObject({
          layername: result.imageryLayer.name,
          response: result.data
        });
      }
      // normal wms with geojson result
      const { properties, geometry } = result.data;
      properties._cesiumStyle = styles.cesiumSearch[geometry.type];

      return [
        {
          ...result.data, // the data key is a geojson feature.
          layername: result.imageryLayer.name || "Raster od. ext WMS"
        }
      ];
    });
};

/*
 * Sets the center and the height of the cesium viewer to the
 * coordinates/zoom from the searchParams.
 * @param {object} Cesium - main cesium library
 * @param {object} cesiumViewer - cesium viewer instance
 * @param {object} searchParams - parsed url search parameters
 * @returns {boolean} true when centering went well, false otherwise
 */
export const setCesiumWindow = (Cesium, cesiumViewer, searchParams) => {
  if (!Cesium || !cesiumViewer || !searchParams) {
    return false;
  }
  let height = searchParams.zoom
    ? getHeight(searchParams.zoom.toString())
    : 15000;
  let center = new Cesium.Cartesian3.fromDegrees(8.5, 46.87, height);

  if (searchParams.center) {
    const centerArr = searchParams.center.split(",");
    const lonLat = toLonLat(centerArr);
    center = new Cesium.Cartesian3.fromDegrees(lonLat[0], lonLat[1], height);
  }
  let heading = Cesium.Math.toRadians(0.0);
  if (searchParams.rotation) {
    //subtract from 360 because rotation is the other way around than ol
    heading = (360 - searchParams.rotation) / (180 / Math.PI);
  }
  cesiumViewer.camera.flyTo({
    destination: center,
    orientation: {
      heading: heading, //0.0 (north)
      pitch: Cesium.Math.toRadians(-90),
      roll: 0.0
    },
    duration: 2
  });
  return true;
};

/*
 * converts a ol zoom level to a cesium height (aproximativ)
 * @param {string} zoom - the ol zoom level.
 * @returns {number} height - the height for use in cesium.
 */
const getHeight = zoom => {
  const intZoom = parseInt(zoom);
  let height = 0;
  if (intZoom <= 12) {
    height = 25000;
  }
  if (intZoom === 13) {
    height = 15000;
  }
  if (intZoom === 14) {
    height = 10000;
  }
  if (intZoom === 15) {
    height = 6000;
  }
  if (intZoom === 16) {
    height = 4000;
  }
  if (intZoom > 16) {
    height = 2000;
  }
  return height;
};

/*
 * converts a cesium height to a ol zoom (aproximativ)
 * @param {number} height - the cesium height.
 * @returns {number} zoom - the zoom level for the ol map.
 */
const getZoom = height => {
  height = height ? height : 15000;
  let zoom = 12;
  if (height >= 25000) {
    return 11;
  }
  if (height >= 15000) {
    return 12;
  }
  if (height >= 10000) {
    zoom = 13;
  }
  if (height >= 6000) {
    return 14;
  }
  if (height >= 4000) {
    return 15;
  }
  if (height >= 2000) {
    return 16;
  }
  if (height >= 100) {
    return 18;
  }
  return zoom;
};

/*
 * @description: creates a wms imagery provider object
 * @param {string} layername - the namespace and layername of the wms layer
 * @param {object} Cesium - Cesium namespace like stored under window.Cesium
 * @returns {object} - Cesium wms imagery provider with the wms layer.
 */
export const createCesiumWmsImageryProvider = (layer, Cesium) => {
  const parameters = {
    format: "image/png",
    transparent: true,
    srs: "EPSG:4326",
    tiled: true
  };
  if (layer.dimensions) {
    const dimensionName = layer.dimensions.name.toUpperCase();
    const dimensionValue = layer.mapLayer.getSource().getParams()[
      dimensionName
    ];
    parameters[dimensionName] = dimensionValue;
  }
  const cesiumLayer = new Cesium.WebMapServiceImageryProvider({
    url: layer.onlineResource,
    layers: layer.serviceName,
    parameters,
    getFeatureInfoFormats: [
      new Cesium.GetFeatureInfoFormat("json"),
      new Cesium.GetFeatureInfoFormat("text")
    ],
    credit: new Cesium.Credit(layer.attribution)
  });
  return cesiumLayer;
};

/*
 * @description: creates a zeitreise imagery provider for the cesium viewer
 * @param {object} params - object with function parameters
 * @param {string} params.layername - the namespace and layername of the wms layer
 * @param {string} params.year - the year the othofoto was generated
 * @param {object} params.Cesium - Cesium namespace like stored under window.Cesium
 * @returns {object} - Cesium UrlTemplateImageryProvider layer object.
 */
export const createZeitreiseProvider = ({ year, Cesium }) =>
  new Cesium.UrlTemplateImageryProvider({
    url: `//wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swissimage-product/default/${year}/4326/{z}/{x}/{y}.jpeg`,
    minimumLevel: 8,
    maximumLevel: 17,
    tilingScheme: new Cesium.GeographicTilingScheme({
      numberOfLevelZeroTilesX: 2,
      numberOfLevelZeroTilesY: 1
    }),
    /* This is the extent of the whole viewer
     * In this case it's set to switzerland
     */
    rectangle: Cesium.Rectangle.fromDegrees(
      5.013926957923385,
      45.35600133779394,
      11.477436312994008,
      48.27502358353741
    )
  });

/*
 * @description: creates a streetview coverage imagery provider for the cesium viewer
 * @param {object} Cesium - Cesium namespace like stored under window.Cesium
 * @returns {object} - Cesium UrlTemplateImageryProvider layer object.
 */
export const createSvCoverageProvider = Cesium =>
  new Cesium.UrlTemplateImageryProvider({
    url:
      "https://mts1.googleapis.com/vt?hl=en-US&lyrs=svv|cb_client:apiv3&style=40,18&x={x}&y={y}&z={z}",
    maximumLevel: 21
  });
