import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { loadScript } from "./loadScript";
import appState from "../utils/appState";
import { removeVectorLayers } from "../utils/layerUtil";
import GeoJSON from "ol/format/GeoJSON.js";
import { Fill, Text } from "ol/style.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { Translate } from "ol/interaction";
import { getBottomLeft, getTopRight, getArea, getCenter } from "ol/extent";
import {
  convertPointCoordinates,
  convertExtent
} from "../utils/projectionUtil";
import styles from "../utils/stylesUtil";

export const loadGeoshop = () => {
  return new Promise((res, rej) => {
    // must be loaded first because it gets used by GSSoap.js
    loadScript("https://geoshop.lisag.ch/gsapi2018/GSMap.js")
      .then(response =>
        loadScript("https://geoshop.lisag.ch/gsapi2018/GSUser.js")
      )
      .then(response =>
        loadScript("https://geoshop.lisag.ch/gsapi2018/GSHashtable.js")
      )
      .then(response => {
        Promise.all([
          loadScript("https://geoshop.lisag.ch/gsapi2018/GSUtil.js"),
          loadScript("https://geoshop.lisag.ch/gsapi2018/GSOrder.js"),
          loadScript("https://geoshop.lisag.ch/gsapi2018/GSSoap.js"),
          loadScript("https://geoshop.lisag.ch/gsapi2018/GSString.js"),
          loadScript("https://geoshop.lisag.ch/gsapi2018/GSLang.js"),
          loadScript("https://geoshop.lisag.ch/gsapi2018/GSLangDE.js"),
          loadScript("https://geoshop.lisag.ch/gsapi2018/GSLangEN.js")
        ])
          .then(values => {
            // geoshop server url setzen
            window.GSUtil.setServer("https://geoshop.lisag.ch");
            res("All Geoshop Scripts successfully loaded");
          })
          .catch(error =>
            rej(
              "Der Geoshop konnte nicht geladen werden... Bitte kontaktiere Sie die Lisag. Tel 041 500 60 60"
            )
          );
      })
      .catch(error =>
        rej(
          "Der Geoshop konnte nicht geladen werden... Bitte kontaktiere Sie die Lisag. Tel 041 500 60 60"
        )
      );
  });
};

/*
 * creates a geoshop cart item with default product and options
 * @param {object} params - object with function parameters
 * @param {object} params.layer - layer object like it comes from the api call
 * @returns {promise} - a promise with the result object or an error message
 */
export const createGeoshopCartItem = async ({ layer }) => {
  return new Promise((resolve, reject) => {
    const { geoshop_user, geoshop_pw } = layer.metadata
      ? layer.metadata
      : layer;
    const user = new window.GSUser();
    const result = {};
    user.login(
      geoshop_user,
      geoshop_pw,
      async () => {
        const productHashtables = user.getProducts().values();
        const productNames = productHashtables.map(product =>
          product.get("display_name")
        );
        result.gsuser = user;
        result.products = productNames;
        result.currentProduct = productNames[0];
        result.options = await getOptions(user, productNames[0]);
        resolve(result);
      },
      error => {
        alert("login failed: " + error);
        reject(error);
      }
    );
  });
};

/*
 * manages the process of creating a cart item and add it to the shopping cart.
 * shows the progress of the process in a modal.
 * @param {object} params - function parameter object.
 * @param {function} setModal - hook to display a modal.
 * @param {object} layer - layer from the meta db.
 * @param {function} addToCart - hook to add an item to the shopping cart.
 * @param {boolean} checkGeoshop - if true, geoshop is loaded inside this function.
 * @return {Promise} - resolved or rejected Promise object.
 */
export const createAndAddItemToCart = async ({
  setModal,
  layer,
  addToCart,
  checkGeoshop = false
}) => {
  setModal({
    type: "info",
    content: (
      <div style={{ textAlign: "center" }}>
        Bitte einen Moment Geduld... <br />
        Der Layer wird dem Warenkorb hinzugefügt
        <br />
        <CircularProgress style={{ margin: "12px" }} />
      </div>
    )
  });
  if (checkGeoshop) {
    if (!window.GSUtil) {
      await loadGeoshop();
    }
  }
  const cartItem = await createGeoshopCartItem({ layer });
  setModal({
    type: "info",
    content: (
      <div style={{ textAlign: "center" }}>
        <CheckCircleIcon
          color="action"
          style={{ color: "limegreen" }}
          fontSize="large"
        />
        <br />
        Der Datensatz wurde in den Warenkorb gelegt.
      </div>
    )
  });
  addToCart(cartItem);
  window.setTimeout(() => {
    setModal({
      type: ""
    });
  }, 1000);
};

/*
 * Queries the selection options for a product
 * and assign them to the productOptions state.
 * @param {object} gsuser - geohsop user object.
 * @param {string} productName - productname to get the options for.
 * @returns {promise} - selection options object or error message
 */
export const getOptions = async (gsuser, productName) => {
  return new Promise((resolve, reject) => {
    gsuser.getProductByDisplayName(
      productName,
      product => {
        const params = product.get("params");
        const options = {};
        options.selection_type = params.get("selection_type");
        if (params.get("selection_options")) {
          options.selectionOptions = params
            .get("selection_options")
            .get("option");
        } else {
          options.selectionOptions = [];
        }
        if (params.get("selection_type") === "FORMATBOX") {
          options.selection_formats = params.get("selection_formats");
        }
        const selectionOptions = processOptions(options);
        resolve(selectionOptions);
      },
      error => {
        alert(error);
        reject(error);
      }
    );
  });
};

/*
 * Processes an object with product options
 * @param {object} options
 * @param {array} options.selectionOptions - array of geohsop product option hashtables
 * @paraam {string} options.selectionFormats - PDF selection format and scale values
 * @return {array} result - array of option objects
 */
export const processOptions = (options = {}) => {
  let { selectionOptions, selection_formats, selection_type } = options;
  if (!Array.isArray(selectionOptions)) {
    selectionOptions = [selectionOptions];
  }
  const result = [];
  selectionOptions.forEach(hashtable => {
    const name = hashtable.get("name");
    const values = hashtable.get("value").split(",");
    result.push({
      name,
      type: values[0],
      displayName: values[1],
      values: values.slice(2),
      selectedValue: values.slice(2)[0],
      selection_type
    });
  });
  if (selection_formats) {
    const formatsArr = selection_formats.split(";");
    const formats = formatsArr.map(format => {
      const formatArr = format.split(",");
      return `${formatArr[0]} ${formatArr[1]}`;
    });
    const scales = formatsArr[0]
      .split(",")
      .filter(item => item.indexOf("1:") === 0);
    result.push({
      name: "selection_formats",
      type: "select",
      displayName: "PDF Format/Massstab",
      formats,
      scales,
      selectedFormat: formats[0],
      selectedScale: scales[1], // 1:500 should be the default scale
      selection_type
    });
  }
  return result;
};

/*
 * Update the options array when the selectedValue of
 * an item changes
 * @param {array} options - array of options
 * @param {object} option - the option with the changed selected value
 * @returns {array} - options array with updated objects.
 */
export const updateOptions = (options, option) => {
  return options.map(item => {
    if (item.name === option.name) {
      return option;
    }
    return item;
  });
};

/*
 * Sends order(s) to the geoshop
 * @param {object} order
 * @param {string} order.benutzergruppe - type of the user who ordered the data,
 * @param {string} order.selection_polygon - coordinates of the selection perimeter
 * @param {string} order.orderMail - email adress to send the order to
 * @param {array} order.shoppingCart - order objects like configured by the user
 * @param {string} order.min - xmin/ymin of the selection polygon
 * @param {string} order.max - xmax/ymax of the selection polygon
 
 */
export const processOrder = order => {
  const {
    benutzergruppe,
    selection_polygon,
    orderMail,
    shoppingCart,
    min,
    max
  } = order;
  const orders = [];
  return new Promise((res, rej) => {
    shoppingCart.forEach(order => {
      order.gsuser.getProductByDisplayName(order.currentProduct, product => {
        orders.push(
          sendOrder({
            product,
            gsuser: order.gsuser,
            orderMail,
            selection_polygon,
            benutzergruppe,
            options: order.options,
            min,
            max
          })
        );
      });
    });
    Promise.all(orders)
      .then(values => {
        res("Bestellung wurde erfolgreich versendet");
      })
      .catch(error =>
        rej(
          `Bestellung konnte nicht ausgeführt werden. Fehlermeldung: ${error}`
        )
      );
  });
};

/*
 * sends an order to the geoshop
 * @param {object} params
 * @param {object} params.product - geoshop product
 * @param {array} params.options - product options
 * @param {object} params.gsuser - geoshop user
 * @param {string} params.orderMail - email adress to send the order to
 * @param {string} params.selection_polygon - coordinates of the selection perimeter
 * @param {string} params.min - xmin/ymin of the selection polygon
 * @param {string} params.max - xmax/ymax of the selection polygon
 * @param {string} params.benutzergruppe - type of the user who ordered the data,
 * @returns {object} promise - a promise object
 */
const sendOrder = ({
  product,
  options,
  gsuser,
  orderMail,
  selection_polygon,
  min,
  max,
  benutzergruppe
}) => {
  return new Promise((res, rej) => {
    const order = new window.GSOrder();
    order.setUser(gsuser);
    order.setProduct(product.get("name"));
    order.setParameterModelByProduct(product);
    // set the required product options
    // they can differ depending on the product
    options.forEach(option => {
      if (option.name === "selection_formats") {
        // some special parameters for pdf orders
        const [format, formatorientation] = option.selectedFormat.split(" ");
        order.setParameter("format", format);
        order.setParameter("scale", option.selectedScale);
        order.setParameter("formatorientation", formatorientation);
        order.setParameter("min", option.min);
        order.setParameter("max", option.max);
        order.setParameter("selection_polygon", option.selection_polygon);
        order.setParameter("angle", 0.0);
        order.setParameter(
          "origin",
          `${option.selection_origin[0]}/${option.selection_origin[1]}`
        );
      }
      if (option.selection_type === null || option.selection_type === "OFF") {
        order.setParameter("selection_polygon", "OFF");
      }
      // process regular options
      order.setParameter(option.name, option.selectedValue);
    });
    if (min && max && selection_polygon) {
      order.setParameter("min", min);
      order.setParameter("max", max);
      order.setParameter("selection_polygon", selection_polygon);
    }
    order.setParameter("name1", "geour_name1");
    order.setParameter("name2", "geour_name2");
    order.setParameter("adr1", "geour_adr1");
    order.setParameter("zip", "geour_zip");
    order.setParameter("city", "geour_city");
    order.setParameter("tel", "geour_tel");

    order.setParameter("email", orderMail);
    order.setParameter("benutzergruppe", benutzergruppe);
    console.log(order);
    order.sendOrder(
      success => {
        res(order.getInfoMessage());
      },
      errorString => rej(errorString)
    );
  });
};

/*
 * draw a grundbuchplan pdf order rectangle.
 * @param {object} params
 * @param {string} params.scale - the scale of the pdf  (e.g. 1:500)
 * @param {string} params.format - the paper format (e.g. A4 hoch)
 * @param {function} params.setSelectionOrigin - function to set the bottom left corner of the print rectangle
 * @returns void
 */
export const drawPDFRectangle = ({ scale, format, option } = {}) => {
  const map = appState.olMap;
  removeVectorLayers({ map, removeType: "pdf" });
  const view = map.getView();
  // use the center prop if available, otherwise center it in the view.
  const center = option.center ? option.center : view.getCenter();
  const geojson = getPrintGeoJson({ center, scale, format });
  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojson)
  });
  const printRectangle = new VectorLayer({
    zIndex: appState.olMap.getLayers().getLength() + 5, //one can add 5 layers before the print rectangle gets obscured,
    source: vectorSource,
    style: styles.getPrintRectangleStyle({
      text: `Druckausschnitt: ${format} ${scale}`,
      borderColor: "#CD2626",
      fillColor: "rgba(178, 34, 34, 0.2)"
    })["Polygon"] // we need the polygon key of the returned object
  });
  // add a custom property to be able to filter geohsop pdf extents
  printRectangle.usageType = "pdf";
  // center the map on the rectangle
  view.fit(vectorSource.getExtent(), { duration: 300, maxZoom: 15 });

  const translate = new Translate({ layers: [printRectangle] });
  const lv95Extent = convertExtent({
    sourceProj: "EPSG:3857",
    destProj: "EPSG:2056",
    extent: vectorSource.getExtent()
  });
  translate.on("translateend", e => {
    option.selection_origin = getSelectionOrigin(vectorSource);
    option.selection_polygon = getSelectionPolygon(lv95Extent);
    option.min = getMin(lv95Extent);
    option.max = getMax(lv95Extent);
    option.center = getCenter(vectorSource.getExtent());
  });
  map.addInteraction(translate);
  map.addLayer(printRectangle);
  // set the inital bottomLeft, min/max, and selection_polygon coords
  option.selection_origin = getSelectionOrigin(vectorSource);
  option.selection_polygon = getSelectionPolygon(lv95Extent);
  option.min = getMin(lv95Extent);
  option.max = getMax(lv95Extent);
};

/*
 * get the selection polygon for a geoshop order
 * @param {array} extent - ol.extent
 * returns {string} selection_polygon - geoshop order ready selection_polygon.
 */
export const getSelectionPolygon = extent => {
  const sw = getBottomLeft(extent);
  const ne = getTopRight(extent);
  const xmin = Math.ceil(sw[0]);
  const ymin = Math.ceil(sw[1]);
  const xmax = Math.ceil(ne[0]);
  const ymax = Math.ceil(ne[1]);
  return extent.length === 0
    ? null
    : `${xmin}/${ymin},${xmin}/${ymax},${xmax}/${ymax},${xmax}/${ymin},${xmin}/${ymin}`;
};

/*
 * get the min coordinate of a geoshop order extent
 * @param {array} extent - ol.extent
 * @returns {string} min - the min coords of the extent, directly useable in geoshop order
 */
export const getMin = extent => {
  const sw = getBottomLeft(extent);
  const xmin = Math.ceil(sw[0]);
  const ymin = Math.ceil(sw[1]);
  return extent.length === 0 ? null : `${xmin}/${ymin}`;
};

/*
 * get the max coordinate of a geoshop order extent
 * @param {array} extent - ol.extent
 * @returns {string} min - the min coords of the extent, directly useable in geoshop order
 */
export const getMax = extent => {
  const ne = getTopRight(extent);
  const xmax = Math.ceil(ne[0]);
  const ymax = Math.ceil(ne[1]);
  return extent.length === 0 ? null : `${xmax}/${ymax}`;
};

/*
 * update the selection origin of a geoshop pdf order
 * @param {object} vectorSource - ol.VectorSource
 * @returns {array} bottomLeft coordinates ([x,y]) in LV95 projection
 */
export const getSelectionOrigin = vectorSource => {
  const bottomLeft = getBottomLeft(vectorSource.getExtent());
  return convertPointCoordinates({
    sourceProj: "EPSG:3857",
    destProj: "EPSG:2056",
    coordinates: bottomLeft
  });
};

/*
 * construct a rectangle vector layer to show the print area
 * @param {object} params
 * @param {object} params.map - ol map
 * @param {array} center of the map in EPSG:3857 coordinates
 * @param {string} scale - scale of the pdf e.g. 1:500
 * @param {string} format - paper format of the pdf e.g. A4 hoch
 * @returns {geoJSON} - polygon Geojson object
 */
export const getPrintGeoJson = ({
  center,
  scale,
  format,
  type = "geoshop"
} = {}) => {
  return {
    type: "Feature",
    crs: {
      type: "name",
      properties: "EPSG:3857"
    },
    geometry: {
      type: "Polygon",
      coordinates: getPrintRectCoords({
        center,
        scale: scale.substr(2),
        format,
        type
      })
    },
    properties: {
      scale,
      format,
      rotation: 0
    }
  };
};

const getPrintRectCoords = ({ center, scale, format, type = "geoshop" }) => {
  const x = center[0];
  const y = center[1];
  let swX,
    swY,
    neX,
    neY = 0;
  // we have to use different values for print and geoshop pdf orders
  const geoshop = type === "geoshop" ? true : false;
  switch (format) {
    case "A4 hoch":
      // the non geoshop case is for the geoserver print
      swX = x - (geoshop ? 0.145 * scale : 0.133 * scale);
      swY = y - (geoshop ? 0.2 * scale : 0.182 * scale);
      neX = x + (geoshop ? 0.145 * scale : 0.133 * scale);
      neY = y + (geoshop ? 0.2 * scale : 0.182 * scale);
      return [
        [
          [swX, neY],
          [neX, neY],
          [neX, swY],
          [swX, swY],
          [swX, neY]
        ]
      ];
    case "A4 quer":
      swX = x - (geoshop ? 0.192 * scale : 0.195 * scale);
      swY = y - (geoshop ? 0.15 * scale : 0.115 * scale);
      neX = x + (geoshop ? 0.192 * scale : 0.195 * scale);
      neY = y + (geoshop ? 0.15 * scale : 0.115 * scale);
      return [
        [
          [swX, neY],
          [neX, neY],
          [neX, swY],
          [swX, swY],
          [swX, neY]
        ]
      ];
    case "A3 hoch":
      swX = x - (geoshop ? 0.22 * scale : 0.196 * scale);
      swY = y - (geoshop ? 0.305 * scale : 0.269 * scale);
      neX = x + (geoshop ? 0.22 * scale : 0.196 * scale);
      neY = y + (geoshop ? 0.305 * scale : 0.269 * scale);
      return [
        [
          [swX, neY],
          [neX, neY],
          [neX, swY],
          [swX, swY],
          [swX, neY]
        ]
      ];
    case "A3 quer":
      swX = x - (geoshop ? 0.31 * scale : 0.285 * scale);
      swY = y - (geoshop ? 0.215 * scale : 0.18 * scale);
      neX = x + (geoshop ? 0.31 * scale : 0.285 * scale);
      neY = y + (geoshop ? 0.215 * scale : 0.18 * scale);
      return [
        [
          [swX, neY],
          [neX, neY],
          [neX, swY],
          [swX, swY],
          [swX, neY]
        ]
      ];
    case "A2 hoch":
      swX = x - (geoshop ? 0.31 * scale : 0.288 * scale);
      swY = y - (geoshop ? 0.43 * scale : 0.393 * scale);
      neX = x + (geoshop ? 0.31 * scale : 0.288 * scale);
      neY = y + (geoshop ? 0.43 * scale : 0.393 * scale);
      return [
        [
          [swX, neY],
          [neX, neY],
          [neX, swY],
          [swX, swY],
          [swX, neY]
        ]
      ];
    case "A2 quer":
      swX = x - (geoshop ? 0.435 * scale : 0.412 * scale);
      swY = y - (geoshop ? 0.3 * scale : 0.275 * scale);
      neX = x + (geoshop ? 0.435 * scale : 0.412 * scale);
      neY = y + (geoshop ? 0.3 * scale : 0.275 * scale);
      return [
        [
          [swX, neY],
          [neX, neY],
          [neX, swY],
          [swX, swY],
          [swX, neY]
        ]
      ];

    default:
      return "not able to define print coordinates";
  }
};

/*
 * check if only a product is in the cart, that does not require a selection polygon (pdf/ganze Gemeinde...)
 * @param {array} cart - the shopping cart with product objects.
 * @returns {number} - 0 if no selection polygon is required, any other number otherwise
 */
export const checkSelectionPolygon = cart => {
  const selectionPolygonsRequired = [];
  cart.forEach(order => {
    const name = order.currentProduct.toLowerCase();
    if (name.indexOf("pdf") !== -1 || name.indexOf("ganze gmde") !== -1) {
      selectionPolygonsRequired.push(false); // no selection polygon required
    } else {
      selectionPolygonsRequired.push(true); // selection polygon required
    }
  });
  return selectionPolygonsRequired.filter(item => item === true).length === 0
    ? false
    : true;
};

const orderPerimeterValid = area => {
  return area / 1000000 > 2500 ? false : true;
};

const cancelMessage = area =>
  "🚫 Der Ausschnitt für die Bestellung von Geodaten " +
  "darf maximal 2500Km2 betragen. Dies entspricht einem Rechteck " +
  "um den gesamten Kanton Uri. Momentan ist er " +
  Math.ceil(area / 1000000) +
  " Km2 gross. 🚫";

const hohenMessage = area =>
  "🚫 Der Ausschnitt für die Bestellung von Höhenpunkten " +
  "darf maximal 10Km2 betragen. Momentan ist er " +
  Math.ceil(area / 1000000) +
  " Km2 gross. Bitte zeichnen Sie den Ausschnitt kleiner 🚫";

const cancelPerimeterDrawing = ({
  area,
  cancelDrawing,
  deletePolygon,
  message
} = {}) => {
  if (!area || !cancelDrawing || !deletePolygon) {
    return;
  }
  alert(message(area));
  window.requestAnimationFrame(() => {
    cancelDrawing();
    deletePolygon();
  });
};

export const checkOrderPerimeter = ({
  extent,
  layer,
  deletePolygon,
  cancelDrawing
} = {}) => {
  if (!extent || !layer || !deletePolygon || !cancelDrawing) {
    return false;
  }
  const area = parseInt(getArea(extent));
  const hoehen = filter(
    appState.shoppingCart.cart,
    "57 Höhen DTM GRID",
    "currentProduct"
  );
  if (orderPerimeterValid(area) === false) {
    cancelPerimeterDrawing({
      area,
      deletePolygon,
      cancelDrawing,
      message: cancelMessage
    });
    return false;
  }
  // check for too big order perimeter for the hohen layer
  if (hoehen.length > 0 && area / 1000000 > 10) {
    cancelPerimeterDrawing({
      area,
      deletePolygon,
      cancelDrawing,
      message: hohenMessage
    });
  }
  // dynamically set the rectangle label
  const style = layer.getStyle();
  style.setText(
    new Text({
      text: `Geoshop Bestellperimeter: ${area.toLocaleString("de-CH")}m2`,
      fill: new Fill({
        color: "#212121"
      }),
      backgroundFill: new Fill({ color: "#ffd044" }),
      font: "bold 11px arial",
      padding: [4, 4, 4, 4]
    })
  );
  layer.setStyle(style);
  return true;
};

/*
 * updates the global shopping cart object to get the values when
 * the sidebar gets closed and reopened.
 * @param {object} params
 * @param {string} params.key - the shopping cart object key to update
 * @param {array/string} params.value - the new value for the object key
 * @returns {object} - the updated shopping cart
 */
export const updateGlobalShoppingCart = ({ key, value } = {}) => {
  const newCart = { ...appState.shoppingCart, [key]: value };
  appState.shoppingCart = newCart;
  return newCart;
};

/*
 * Filters an array by a searchterm
 * @param {array} data - the data to filter
 * @param {string} term - the searchterm to look for
 * @param {string} filterAttribute - the the object key of the data item to look for the term
 * @returns {array} - the filtered array
 */
export const filter = (data, term, filterAttribute) => {
  if (term.trim() !== "") {
    return data.filter(
      layer =>
        layer[filterAttribute].toLowerCase().indexOf(term.toLowerCase()) !== -1
    );
  } else {
    return data;
  }
};

/*
 * adds a layer to the shoping cart
 * @param {object} params - function parameter object.
 * @param {function} setModal - hook to display a modal.
 * @param {object} layer - layer from the meta db.
 * @param {function} addToCart - hook to add an item to the shopping cart.
 * @returns {boolean} true in case of success, false otherwise.
 */
export const addLayerToCart = async ({ setModal, layer, addToCart } = {}) => {
  if (!setModal || !layer || !addToCart) return false;
  try {
    await createAndAddItemToCart({
      setModal,
      layer,
      addToCart
    });
    return true;
  } catch (e) {
    setModal({
      type: ""
    });
    alert(
      `Fehler: Layer konnte nicht dem Warenkorb hinzugefügt werden. Bitte wenden Sie sich an die Lisag (041 500 60 60).\n${e}`
    );
    return false;
  }
};
