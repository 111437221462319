export const mapState = {
  id: "map",
  initial: "2D",
  states: {
    "2D": {
      on: { TOGGLE: "3D" }
    },
    "3D": {
      on: { TOGGLE: "2D" }
    }
  }
};
