import { Feature, Map, View } from "ol";
import { toStringXY } from "ol/coordinate";
import { defaults as defaultControls, Control } from "ol/control";
import GpsPosition from "../../utils/positionUtil";
import { updateUrl } from "../../utils/urlUtil";
import { normalizeOlRotation } from "../../utils/rotationUtil";
import { WISKI_HTML_HOST } from "../../utils/ENV";
import {
  checkOrderPerimeter,
  updateGlobalShoppingCart
} from "../../utils/geoshopUtil";
import { convertPointCoordinates } from "../../utils/projectionUtil";
import { getOlFeatureInfo } from "../../utils/requestUtil";
import { removeVectorLayers } from "../../utils/layerUtil";
import { Draw, Modify, Select, Snap, Translate } from "ol/interaction";
import { createBox } from "ol/interaction/Draw";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Point } from "ol/geom";
import { GeoJSON } from "ol/format";
import { orthoBasemap } from "../basemaps/basemapDefinitions";
import { register } from "ol/proj/proj4";
import { registerProjections, convertExtent } from "../../utils/projectionUtil";
import styles from "../../utils/stylesUtil";
import {
  streetSmartLogin,
  updateStreetSmartLayer,
  updateStreetSmartPanorama
} from "../../utils/toolsUtil";
import appState from "../../utils/appState";

/*
 * This function contains the logic to render the OpenLayers map inside the ol-map div
 * @param {object} params - function parameter object.
 * @param {object} params.searchParams - url query parameters like zoom, center, basemap...,
 * @param {function} params.setFeatureInfos - open/close the infobox with attribute infos.
 * @param {array} params.layers - the layers in the toc.
 * @param {function} params.setLayer - useReducer hook function to add layers to the toc.
 * @returns {object} map - the OpenLayers map object
 */
export const renderOlMap = ({ searchParams, setFeatureInfos } = {}) => {
  const { center, zoom, rotation } = getViewParams(searchParams);
  const map = new Map({
    controls: defaultControls({ attributionOptions: { collapsible: false } }),
    target: "ol-map",
    layers: [orthoBasemap, appState.svCoverage],
    view: new View({
      center: center,
      zoom: zoom,
      minZoom: 10,
      maxZoom: 21,
      rotation: rotation
    })
  });
  window.onresize = e => {
    window.requestAnimationFrame(() => map.updateSize());
  };
  // add the positioning control
  appState.positioning = new GpsPosition(map);
  const positioning = new Control({
    element: appState.positioning.getPositionElement()
  });
  map.addControl(positioning);
  //add event listeners to update the url when center, zoom or rotation change
  map.on("moveend", async e => {
    const view = e.map.getView();
    const newMapParams = {
      zoom: parseInt(view.getZoom()),
      center: toStringXY(view.getCenter()).replace(/\s/g, "") //remove any whitespace
    };
    updateUrl(newMapParams);
    //load new street smart wfs points if street smart is active.
    if (appState.streetSmartActive === true && map.getView().getZoom() >= 17) {
      const xmlString = await streetSmartLogin(
        appState.streetSmartUser,
        appState.streetSmartPw
      );
      if (appState.wfsClient) {
        updateStreetSmartLayer(xmlString);
      }
    }
  });

  // add a vector layer to display positions on a profile graph.
  const profilePointFeature = new Feature({
    geometry: new Point([0, 0]),
    name: "profile point"
  });
  profilePointFeature.setStyle(styles.profileGraphCircle);
  profilePointFeature.setId(1);
  const profilePointSource = new VectorSource({
    features: [profilePointFeature]
  });
  appState.profilePointLayer = new VectorLayer({
    map,
    source: profilePointSource,
    visible: false
  });

  map.getView().on("change:rotation", e => {
    const view = e.target;
    const rotation = normalizeOlRotation(view.getRotation() * (180 / Math.PI)); // degrees
    const newMapParams = {
      rotation: rotation
    };
    updateUrl(newMapParams);
  });
  map.on("singleclick", async event => {
    // don't do anything if we are in drawing mode
    if (getDrawingMode(map)) {
      return false;
    }

    const lnglat = convertPointCoordinates({
      sourceProj: "EPSG:3857",
      destProj: "EPSG:4326",
      coordinates: event.coordinate
    });
    // set the coordinates for streetview usage, but only if street view is active.
    if (window.google && appState.svCoverage.getVisible()) {
      appState.setSvCoords([lnglat[1], lnglat[0]]);
    }

    //check if there are any non VectorLayers to query beside the basemap.
    const loadedLayers = map.getLayers().getArray();
    const nonVectorOverlays = [];
    loadedLayers.forEach(layer => {
      if (layer instanceof VectorLayer === false) {
        nonVectorOverlays.push(layer);
      }
    });
    const featureInfos = [];
    // only show feture infos if we have more than the basemap and the street view layer.
    if (nonVectorOverlays.length > 2) {
      try {
        const infos = await getOlFeatureInfo(event, map);
        infos.forEach(info => {
          if (info.length > 0) {
            info.forEach(featureInfo => {
              if (featureInfo.geometry) {
                const { type } = featureInfo.geometry;
                featureInfo.properties.usageType = "featureInfo";
                featureInfo.properties._olStyle = styles.olSearch[type];
              }
              featureInfos.push(featureInfo);
            });
          }
        });
      } catch (error) {
        const errorMessage = [
          {
            id: "Fehler (!)",
            layername: "Fehler (!)",
            properties: {
              Nachricht:
                "Attribute konnten nicht abgefragt werden. \n" +
                error.toString()
            }
          }
        ];
        featureInfos.push(errorMessage);
      }
    }
    // check for vector layers
    const vectorInfos = getVectorInfo({ pixel: event.pixel, map });
    if (vectorInfos.length > 0) {
      // add the style to highlight the clicked geometry
      vectorInfos.forEach(geojson => {
        geojson.properties._olStyle = styles.olSearch[geojson.geometry.type];
        featureInfos.push(geojson);
      });
    }
    if (featureInfos.length > 0) {
      setFeatureInfos(featureInfos);
    } else {
      setFeatureInfos([]);
    }
  });
  // make lv95 and lv03 projections availabe in OpenLayers
  registerProjections(register);
  return map;
};
/*
 * get valid center, zoom and rotation values to use in an ol view instance
 * @param {object} searchParams - parsed url search parameters
 * @returns {object} - {center:center, zoom:zoom, rotation:rotation}
 */
export const getViewParams = searchParams => {
  if (!searchParams) {
    return false;
  }
  let center = [962589, 5922132];
  if (searchParams.center) {
    const centerStringArr = searchParams.center.split(",");
    center[0] = parseInt(centerStringArr[0]);
    center[1] = parseInt(centerStringArr[1]);
  }
  let rotation = 0;
  if (searchParams.rotation) {
    rotation = searchParams.rotation / (180 / Math.PI);
  }
  let zoom = searchParams.zoom || 12;
  return { center, zoom, rotation };
};

/*
 * enable the map to draw a rectange for use with geoshop orders
 * @param {object} map - openLayers map Object
 * @returns {object}
 * {
 *  cancelDrawing: function to disable drawing mode,
 *  deletePolygon: funciton to delete the polygon from the map
 * }
 */
export const setDrawingMode = ({ map, setOrderExtent }) => {
  removeVectorLayers({ map, removeType: "geoshop" });
  // empty vector data to for saving the drawings
  const source = new VectorSource();
  const vectorLayer = new VectorLayer({
    zIndex: map.getLayers().getLength() + 5, //one can add 5 layers before the print rectangle gets obscured
    source,
    style: styles.geoshop
  });
  vectorLayer.usageType = "geoshop";
  map.addLayer(vectorLayer);
  /* to create a modify instance with the vector source
   * we want to modify the drawing and snap to the lines
   * if we are close enough
   */
  const modify = new Modify({ source });
  const snap = new Snap({ source });
  const translate = new Translate({ layers: [vectorLayer] });

  // draw instance to draw a polygon on the map
  const draw = new Draw({
    source,
    type: "Circle",
    geometryFunction: createBox()
  });

  draw.on("drawstart", e => {
    if (source.getFeatures().length > 0) {
      source.clear(true);
    }
  });

  draw.on("drawend", e => {
    const extent = convertExtent({
      sourceProj: "EPSG:3857",
      destProj: "EPSG:2056",
      extent: e.feature.getGeometry().getExtent()
    });
    if (
      checkOrderPerimeter({
        extent,
        layer: vectorLayer,
        deletePolygon,
        cancelDrawing
      })
    ) {
      setOrderExtent(extent);
      updateGlobalShoppingCart({ key: "extent", value: extent });
    }
  });

  source.on("changefeature", e => {
    const extent = convertExtent({
      sourceProj: "EPSG:3857",
      destProj: "EPSG:2056",
      extent: e.feature.getGeometry().getExtent()
    });
    if (
      checkOrderPerimeter({
        extent,
        layer: vectorLayer,
        deletePolygon,
        cancelDrawing
      })
    ) {
      setOrderExtent(extent);
      updateGlobalShoppingCart({ key: "extent", value: extent });
    }
  });

  // set the map in a drawing state
  map.addInteraction(translate);
  map.addInteraction(modify);
  map.addInteraction(snap);
  map.addInteraction(draw);

  const cancelDrawing = () => {
    map.removeInteraction(translate);
    map.removeInteraction(modify);
    map.removeInteraction(snap);
    map.removeInteraction(draw);
  };
  const deletePolygon = () => {
    source.clear(true);
    setOrderExtent([]);
    updateGlobalShoppingCart({ key: "extent", value: [] });
  };
  return {
    cancelDrawing,
    deletePolygon
  };
};

/* check if Draw interaction is active
 * @param {object} map - ol map object
 * @returns {boolean} drawingMode - true if drawing interaction is available and active, false otherwise
 */
export const getDrawingMode = map => {
  let drawingMode = false;
  map.getInteractions().forEach(interaction => {
    if (
      interaction instanceof Draw ||
      interaction instanceof Select ||
      interaction instanceof Modify
    ) {
      drawingMode = true;
    }
  });
  return drawingMode;
};

/*
 *hide a print rectangle but don't remove it.
 *@param {object} params - function parameter object.
 *@param {object} params.map - ol map instance.
 *@param {string} params.visibility - "show" or "hide".
 *@param {string} params.usageType - "print, geoshop, pdf, measure" or multiple comma separates values. (print, geoshop)
 *@returns {boolean} true in case of success, false otherwise
 */
export const changeVectorLayerVisibility = ({
  map = null,
  type = "hide",
  usageType = "print"
} = {}) => {
  if (!map) {
    return false;
  }
  map.getLayers().forEach(layer => {
    if (usageType.indexOf(layer.usageType) !== -1) {
      if (type === "hide") {
        layer.setVisible(false);
      }
      if (type === "show" && layer.getVisible() === false) {
        layer.setVisible(true);
      }
    }
  });
  return true;
};

const getVectorInfo = ({ pixel, map } = {}) => {
  if (!pixel || !map) {
    return;
  }
  const features = [];
  map.forEachFeatureAtPixel(
    pixel,
    feature => {
      // check if there are any other props beside the geometry
      const props = feature.getProperties();
      const keys = Object.keys(props);
      if (keys.length === 1 && keys[0] === "geometry") {
        // if there are no other props, don't add it to the features
        return;
      }
      // check for streetsmart points
      if (props.name && props.name.indexOf("streetsmart_") !== -1) {
        const destProj = "EPSG:2056";
        const coordinates = convertPointCoordinates({
          sourceProj: "EPSG:3857",
          destProj,
          coordinates: props.geometry.flatCoordinates
        });
        appState.streetSmartCoords = coordinates;
        updateStreetSmartPanorama({ coordinates, srs: destProj });
        return;
      }
      features.push(featureToGeojson(feature));
    },
    {
      layerFilter: candidate => {
        switch (candidate.usageType) {
          case "kml":
          case "messdaten":
          case "streetsmart":
          case "foto":
            return true;
          default:
            return false;
        }
      }
    }
  );
  return features;
};

const featureToGeojson = feature => {
  const geojsonFormat = new GeoJSON();
  const geojson = JSON.parse(geojsonFormat.writeFeature(feature));

  let messdaten = false;
  if (
    geojson.properties.usageType &&
    geojson.properties.usageType === "messdaten"
  ) {
    messdaten = true;
  }
  if (messdaten) {
    geojson.layername = geojson.properties.name;
    geojson.id = geojson.properties.station_name;
    geojson.properties.iframeurl = `${WISKI_HTML_HOST}/wasser?stationid=${geojson.properties.station_id}`;
  } else {
    geojson.layername = feature.getProperties().name;
    //unique id for infobox key
    geojson.id = geojson.properties.id
      ? geojson.properties.id
      : `${feature.getProperties().name}_${(Math.random() * 100).toFixed(4)}`;
  }
  // only add description/iframeurl if available
  if (geojson.properties.description) {
    geojson.properties = { description: geojson.properties["description"] };
  }
  if (geojson.properties.iframeurl) {
    geojson.properties = { iframeurl: geojson.properties["iframeurl"] };
  }
  if (geojson.properties.usageType) {
    /* we have to remove the usageType property
     * in order for the selection to work properly.
     * only usageTypes "search" or "featureInfo" are allowed.
     */
    delete geojson.properties.usageType;
  }
  return geojson;
};
