import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { drawPDFRectangle } from "../../utils/geoshopUtil";

const Option = ({ option }) => {
  useEffect(() => {
    // draw pdf print rectangle when pdf product is selected or format/scale changes
    if (option.type === "select") {
      drawPDFRectangle({
        scale: selectedScale,
        format: selectedFormat,
        option
      });
    }
  }, []);
  const [selectedOption, setSelectedOption] = useState(option.selectedValue);
  const [selectedFormat, setSelectedFormat] = useState("A4 hoch");
  const [selectedScale, setSelectedScale] = useState("1:500");
  if (option.type === "choice") {
    return (
      <FormControl component="fieldset" fullWidth={true}>
        <FormLabel component="legend" style={{ fontSize: "14px" }}>
          {option.displayName}
        </FormLabel>
        <RadioGroup
          aria-label={option.displayName}
          name={option.displayName}
          value={selectedOption}
          onChange={(e, value) => {
            // update the local option value
            option.selectedValue = value;
            // update the options array for a
            // potential later use in the shopping cart
            setSelectedOption(value);
          }}
        >
          {option.values.map(value => {
            return (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={value}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
  if (option.type === "select") {
    return (
      <div style={{ padding: "12px 0 24px 0" }}>
        <legend
          className="MuiFormLabel-root-257"
          style={{ fontSize: "14px", paddingBottom: "12px" }}
        >
          {option.displayName}
        </legend>
        <FormControl>
          <InputLabel htmlFor="paper-format">Format</InputLabel>
          <Select
            native
            inputProps={{ id: "paper-format", name: "paper-format" }}
            value={selectedFormat}
            onChange={e => {
              //important: use event value because state is not updatet fast enough
              option.selectedFormat = e.target.value;
              setSelectedFormat(e.target.value);
              drawPDFRectangle({
                scale: selectedScale,
                format: e.target.value,
                option
              });
            }}
          >
            {option.formats.map(format => (
              <option key={format} value={format}>
                {format}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ margin: "0 0 0 12px" }}>
          <InputLabel htmlFor="scale">Massstab</InputLabel>
          <Select
            native
            inputProps={{ id: "scale", name: "scale" }}
            value={selectedScale}
            onChange={e => {
              //important: use event value because state is not updatet fast enough
              option.selectedScale = e.target.value;
              setSelectedScale(e.target.value);
              drawPDFRectangle({
                scale: e.target.value,
                format: selectedFormat,
                option
              });
            }}
          >
            {option.scales.map(scale => (
              <option key={scale} value={scale}>
                {scale}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default Option;

Option.propTypes = {
  option: PropTypes.object.isRequired
};
