import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const LegendSelector = ({ selected, setSelected }) => {
  return (
    <section style={{ padding: "0 0 12px 0" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={selected}
            onChange={e => setSelected(e.target.checked)}
            value="legend"
            color="secondary"
          />
        }
        label="Mit Legende"
      />
      {selected && <div>Ein Druck mit Legende kann bis zu 60s dauern.</div>}
    </section>
  );
};

export default LegendSelector;
LegendSelector.propTypes = {
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired
};
